import { keyBy, merge } from 'lodash-es';

import { TOP_SEARCHES } from '../../common/constants';
import { FILTER_QUERY_KEYS } from './Constants';

const topCategories = TOP_SEARCHES.jobCategories.get() || [];
const topCities = (TOP_SEARCHES.locations.get() || []).map(l => l.city);
const topSkills = TOP_SEARCHES.skills.get() || [];
const topSchools = TOP_SEARCHES.schools.get() || [];
const topSearches = {
  city: keyBy(topCities, 'id'),
  jobCategory: keyBy(topCategories, 'id'),
  skill: keyBy(topSkills, 'id'),
  school: keyBy(topSchools, 'id'),
};

// Instead of sending ids over to GTM, this function translate filter value into name.
// eg: key: cities, value: 28904 -> Jakarta
export const translateFilterValue = (
  state: any,
  key: string,
  value: string
) => {
  const entities = merge({}, topSearches, state.entities);

  switch (key) {
    case FILTER_QUERY_KEYS.SKILLS:
      return entities?.skill?.[value]?.name;
    case FILTER_QUERY_KEYS.JOB_CATEGORIES:
      return entities?.jobCategory?.[value]?.name;
    case FILTER_QUERY_KEYS.JOB_TITLES:
      return entities?.jobTitle?.[value]?.name;
    case FILTER_QUERY_KEYS.CITIES:
      return entities?.city?.[value]?.name;
    case FILTER_QUERY_KEYS.COUNTRIES:
      return entities?.country?.[value]?.name;
    case FILTER_QUERY_KEYS.NATIONALITY:
      return entities?.country?.[value]?.demonym;
    case FILTER_QUERY_KEYS.SCHOOLS:
      return entities?.school?.[value]?.name;
    default:
      return value;
  }
};
