import { handleActions } from 'redux-actions';

import { Actions } from './Constants';

const commonState = {
  loading: false,
  error: false,
  total: 0,
  data: [],
};

const initialState = {
  allTalent: commonState,
  bookmarks: commonState,

  bookmarksCount: {},
  companies: commonState,
  companyJobs: {
    ...commonState,
    selected: null,
  },
  viewExternalProfileId: null,
};

const makeRequestReducerMap = key => state => ({
  ...state,
  [key]: {
    ...state[key],
    loading: true,
    error: false,
  },
});

const makeSuccessReducerMap = key => (state, action) => ({
  ...state,
  [key]: {
    ...state[key],
    loading: false,
    data: action.response.result,
    total: action.response.count,
  },
});

const makeFailureReducerMap = key => (state, action) => ({
  ...state,
  [key]: {
    ...state[key],
    loading: false,
    error: action.error.response,
  },
});

export default handleActions(
  {
    // allTalent
    [Actions.ALL_TALENT_REQUEST]: makeRequestReducerMap('allTalent'),
    [Actions.ALL_TALENT_SUCCESS]: makeSuccessReducerMap('allTalent'),
    [Actions.ALL_TALENT_FAILURE]: makeFailureReducerMap('allTalent'),
    // bookmarks
    [Actions.BOOKMARKS_REQUEST]: makeRequestReducerMap('bookmarks'),
    [Actions.BOOKMARKS_SUCCESS]: makeSuccessReducerMap('bookmarks'),
    [Actions.BOOKMARKS_FAILURE]: makeFailureReducerMap('bookmarks'),

    // bookmarksCount
    [Actions.BOOKMARKS_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      bookmarksCount: action.response,
    }),
    // companies
    [Actions.COMPANIES_REQUEST]: makeRequestReducerMap('companies'),
    [Actions.COMPANIES_SUCCESS]: makeSuccessReducerMap('companies'),
    [Actions.COMPANIES_FAILURE]: makeFailureReducerMap('companies'),
    // company jobs
    [Actions.COMPANY_JOBS_REQUEST]: state => ({
      ...state,
      bookmarks: initialState.bookmarks,
      companyJobs: {
        ...state.companyJobs,
        loading: true,
        error: false,
        selected: initialState.companyJobs.selected,
      },
    }),
    [Actions.COMPANY_JOBS_SUCCESS]: makeSuccessReducerMap('companyJobs'),
    [Actions.COMPANY_JOBS_FAILURE]: makeFailureReducerMap('companyJobs'),
    // others
    [Actions.SELECT_COMPANY_JOB]: (state, action) => ({
      ...state,
      companyJobs: {
        ...state.companyJobs,
        selected: action.payload,
      },
    }),
    [Actions.UNBOOKMARK_SUCCESS]: (state, action) => ({
      ...state,
      bookmarks: {
        ...state,
        data: state.bookmarks.data.filter(d => d !== action.payload),
      },
    }),
    [Actions.VIEW_EXTERNAL_PROFILE]: (state, action) => ({
      ...state,
      viewExternalProfileId: action.payload,
    }),
  },
  initialState
);
