import React from 'react';
import { PlainButton, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';
import { useMount } from 'react-use';
import styled from 'styled-components';

import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import { useVIPMembershipExpiredLocalStorage } from '../../../common/hooks/useVIPMembershipExpiredLocalStorage';
import { useGlintsVIPPurchaseModal } from '../../../modules/CreditSystem/PurchaseModals/GlintsVIPPurchaseModal/hooks/useGlintsVIPPurchaseModal';
import { StickyContainer, StyledBanner } from '../styles.sc';
import {
  trackVIPExpiredNavbarBannerCTAClicked,
  trackVIPExpiredNavbarBannerImpression,
} from './tracking';

const StyledButton = styled(PlainButton)`
  display: inline;
  padding: 0;
`;

type Props = {
  setFixedBannerHeight: (height: number) => void;
  closeBanner: () => void;
};

export const VIPMembershipExpiredBanner = ({
  setFixedBannerHeight,
  closeBanner,
}: Props) => {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });

  const { openGlintsVIPPurchaseModal, isModalChunkLoadPending } =
    useGlintsVIPPurchaseModal();

  const { setVIPMembershipExpiredFirstSeen } =
    useVIPMembershipExpiredLocalStorage();

  useMount(() => {
    setVIPMembershipExpiredFirstSeen();
    trackVIPExpiredNavbarBannerImpression();
  });

  return (
    <StickyContainer ref={ref}>
      <StyledBanner
        type="fixed"
        status="warning"
        showIcon={false}
        onDismiss={() => {
          closeBanner();
          setFixedBannerHeight(0);
        }}
      >
        <Typography variant="body2" color={Neutral.B18} as="div">
          <FormattedMessage
            id="interactive-vip-membership-expired"
            defaultMessage="Your VIP membership has expired. <link>Upgrade to VIP</link> to use VIP benefits."
            values={{
              link: (text: React.ReactNode) => (
                <StyledButton
                  onClick={() => {
                    trackVIPExpiredNavbarBannerCTAClicked();
                    return openGlintsVIPPurchaseModal();
                  }}
                  disabled={isModalChunkLoadPending}
                >
                  <Typography variant="body2">{text}</Typography>
                </StyledButton>
              ),
            }}
          />
        </Typography>
      </StyledBanner>
    </StickyContainer>
  );
};
