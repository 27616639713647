import { handleActions } from 'redux-actions';

import {
  Actions,
  RECOMMENDATIONS_FILTER,
  RECOMMENDATIONS_PER_PAGE_LIMIT,
  TABS,
} from './Constants';

const initialState = {
  activeTab: TABS.RECOMMENDED,
  selectedJobId: null,
  isFromEmail: false,
  selectedRecommendationFilter: RECOMMENDATIONS_FILTER.ALL,

  loading: false,
  error: null,
  success: false,
  value: [],
  count: 0,
  page: 1,
  hasMore: true,

  bookmarks: {
    loading: false,
    error: null,
    success: false,
    value: [],
  },

  createBookmark: {
    loading: false,
    error: null,
    success: false,
  },

  deleteBookmark: {
    id: null,
    loading: false,
    error: null,
    success: false,
  },

  jobs: {
    loading: false,
    error: null,
    success: false,
    value: [],
  },

  approve: {
    loading: false,
    error: null,
    success: false,
    recommendationId: null,
    talentName: null,
  },

  reject: {
    loading: false,
    error: null,
    success: false,
    recommendationId: null,
    talentName: null,
  },

  profileModal: {
    isVisible: false,
    recommendation: null,
    groupMember: {
      value: null,
      loading: false,
      success: false,
      error: null,
    },
  },

  rejectionModal: {
    isVisible: false,
    recommendationId: null,
    isTextAreaShown: false,
    otherReason: '',
    reasons: [],
    loading: false,
    success: false,
    error: null,
    displayNotification: false,
  },

  breakdown: {},
};

export default handleActions(
  {
    [Actions.RECOMMENDATIONS_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      success: false,
    }),
    [Actions.RECOMMENDATIONS_SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      error: null,
      success: true,
      value: [...new Set(state.value.concat(action.response.result))],
      count: action.response.count,
      hasMore: action.response.result.length === RECOMMENDATIONS_PER_PAGE_LIMIT,
    }),
    [Actions.RECOMMENDATIONS_FAILURE]: (state, action) => {
      const err = action.error.response;
      let message = null;
      let description = null;
      if (!err) {
        if (message === 'Network Error') {
          message = 'Network Error';
          description = "Please check if you're connected to the Internet.";
        } else {
          message = action.error.message;
          description =
            'Our engineers have been notified. Please try again by refreshing the page.';
        }
      } else if (err.status === 401 || err.status === 400) {
        message = 'Invalid email or password';
        description = 'Please enter the correct email or password.';
      } else {
        message = 'An error has occurred';
        description =
          'Our engineers have been notified. Please try again by refreshing the page.';
      }
      return {
        ...state,
        loading: false,
        error: { message, description },
        success: false,
        value: [],
        count: 0,
        page: 1,
        hasMore: true,
      };
    },
    [Actions.BOOKMARKS_REQUEST]: state => ({
      ...state,
      bookmarks: {
        ...state.bookmarks,
        loading: true,
        error: null,
        success: false,
      },
    }),
    [Actions.BOOKMARKS_SUCCESS]: (state, action) => ({
      ...state,
      bookmarks: {
        ...state.bookmarks,
        loading: false,
        error: null,
        value: [
          ...new Set(state.bookmarks.value.concat(action.response.result)),
        ],
        success: true,
      },
    }),
    [Actions.BOOKMARKS_FAILURE]: (state, action) => ({
      ...state,
      bookmarks: {
        ...state.bookmarks,
        loading: false,
        error: action.error.response,
        value: [],
        success: false,
      },
    }),
    [Actions.SET_ACTIVE_TAB]: (state, action) => ({
      ...state,
      activeTab: action.payload,
      value: [],
      page: 1,
      hasMore: true,
      bookmarks: initialState.bookmarks,
    }),
    [Actions.INCREMENT_PAGE_BY_ONE]: state => ({
      ...state,
      page: state.page + 1,
    }),
    [Actions.TALENTHUNT_JOBS_REQUEST]: state => ({
      ...state,
      jobs: {
        ...state.jobs,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.TALENTHUNT_JOBS_SUCCESS]: (state, action) => ({
      ...state,
      jobs: {
        ...state.jobs,
        loading: false,
        success: true,
        error: null,
        value: action.response.result,
      },
    }),
    [Actions.TALENTHUNT_JOBS_FAILURE]: (state, action) => ({
      ...state,
      jobs: {
        ...state.jobs,
        loading: false,
        success: false,
        error: action.payload.response,
        value: [],
      },
    }),
    [Actions.SELECT_JOB]: (state, action) => ({
      ...state,
      selectedJobId: action.payload,
      value: [],
      page: 1,
      hasMore: true,
      bookmarks: initialState.bookmarks,
      selectedRecommendationFilter: initialState.selectedRecommendationFilter,
    }),
    [Actions.SET_SELECTED_JOB_ID]: (state, action) => ({
      ...state,
      selectedJobId: action.payload,
    }),
    [Actions.APPROVE_RECOMMENDATION_REQUEST]: (state, action) => ({
      ...state,
      approve: {
        ...initialState.approve,
        loading: true,
        recommendationId: action.payload,
      },
    }),
    [Actions.APPROVE_RECOMMENDATION_SUCCESS]: (state, action) => ({
      ...state,
      approve: {
        ...state.approve,
        loading: false,
        success: true,
        error: null,
        talentName: action.payload,
      },
      value: state.value.filter(id => id !== state.approve.recommendationId),
      count: state.count - 1 < 0 ? 0 : state.count - 1,
      profileModal: initialState.profileModal,
    }),
    [Actions.APPROVE_RECOMMENDATION_FAILURE]: (state, action) => ({
      ...state,
      approve: {
        ...initialState.approve,
        error: action.payload.response,
      },
    }),
    [Actions.REJECT_RECOMMENDATION_REQUEST]: (state, action) => ({
      ...state,
      reject: {
        ...initialState.reject,
        loading: true,
        recommendationId: action.payload,
      },
    }),
    [Actions.REJECT_RECOMMENDATION_SUCCESS]: (state, action) => ({
      ...state,
      reject: {
        ...state.reject,
        loading: false,
        success: true,
        error: null,
        talentName: action.payload,
      },
      value: state.value.filter(id => id !== state.reject.recommendationId),
      count: state.count - 1 < 0 ? 0 : state.count - 1,
      profileModal: initialState.profileModal,
    }),
    [Actions.REJECT_RECOMMENDATION_FAILURE]: (state, action) => ({
      ...state,
      reject: {
        ...initialState.reject,
        error: action.payload.response,
      },
    }),
    [Actions.RESET_STATE]: () => initialState,
    [Actions.OPEN_PROFILE_MODAL]: (state, action) => ({
      ...state,
      profileModal: {
        ...state.profileModal,
        isVisible: true,
        recommendation: action.payload,
      },
    }),
    [Actions.CLOSE_PROFILE_MODAL]: state => ({
      ...state,
      profileModal: initialState.profileModal,
    }),
    [Actions.OPEN_REJECTION_MODAL]: (state, action) => ({
      ...state,
      rejectionModal: {
        ...state.rejectionModal,
        isVisible: true,
        recommendationId: action.payload,
      },
    }),
    [Actions.CLOSE_REJECTION_MODAL]: state => ({
      ...state,
      rejectionModal: initialState.rejectionModal,
    }),
    [Actions.TOGGLE_REJECTION_TEXT_AREA]: state => ({
      ...state,
      rejectionModal: {
        ...state.rejectionModal,
        isTextAreaShown: !state.rejectionModal.isTextAreaShown,
        otherReason: '',
      },
    }),
    [Actions.UPDATE_OTHER_REJECTION_REASON]: (state, action) => ({
      ...state,
      rejectionModal: {
        ...state.rejectionModal,
        otherReason: action.payload,
      },
    }),
    [Actions.UPDATE_REJECTION_REASONS]: (state, action) => ({
      ...state,
      rejectionModal: {
        ...state.rejectionModal,
        reasons: action.payload,
      },
    }),
    [Actions.SET_IS_FROM_EMAIL]: state => ({
      ...state,
      isFromEmail: true,
    }),
    [Actions.RESET_IS_FROM_EMAIL]: state => ({
      ...state,
      isFromEmail: initialState.isFromEmail,
    }),
    [Actions.SUBMIT_REJECTION_REASON_REQUEST]: state => ({
      ...state,
      rejectionModal: {
        ...state.rejectionModal,
        loading: true,
        success: false,
        error: null,
        displayNotification: false,
      },
    }),
    [Actions.SUBMIT_REJECTION_REASON_SUCCESS]: state => ({
      ...state,
      rejectionModal: {
        ...state.rejectionModal,
        loading: false,
        success: true,
        displayNotification: true,
      },
    }),
    [Actions.SUBMIT_REJECTION_REASON_FAILURE]: (state, action) => ({
      ...state,
      rejectionModal: {
        ...state.rejectionModal,
        loading: false,
        success: false,
        error: action.payload.response,
        displayNotification: false,
      },
    }),
    [Actions.SELECT_RECOMMENDATION_FILTER]: (state, action) => ({
      ...state,
      selectedRecommendationFilter: action.payload,
      value: [],
      page: 1,
      hasMore: true,
      bookmarks: initialState.bookmarks,
    }),
    [Actions.CREATE_BOOKMARK_REQUEST]: state => ({
      ...state,
      createBookmark: {
        ...state.createBookmark,
        loading: true,
        error: null,
        success: false,
      },
    }),
    [Actions.CREATE_BOOKMARK_SUCCESS]: (state, action) => ({
      ...state,
      createBookmark: {
        ...state.createBookmark,
        loading: false,
        error: null,
        success: true,
      },
      bookmarks: {
        ...state.bookmarks,
        value: [...state.bookmarks.value, action.response.result],
      },
    }),
    [Actions.CREATE_BOOKMARK_FAILURE]: (state, action) => ({
      ...state,
      createBookmark: {
        ...state.createBookmark,
        loading: false,
        error: action.error.response,
        success: false,
      },
    }),
    [Actions.DELETE_BOOKMARK_REQUEST]: state => ({
      ...state,
      deleteBookmark: {
        ...state.deleteBookmark,
        loading: true,
        error: null,
        success: false,
      },
    }),
    [Actions.DELETE_BOOKMARK_SUCCESS]: (state, action) => ({
      ...state,
      deleteBookmark: {
        ...state.deleteBookmark,
        loading: false,
        error: null,
        success: true,
      },
      bookmarks: {
        ...state.bookmarks,
        value: state.bookmarks.value.filter(id => id !== action.payload),
      },
    }),
    [Actions.DELETE_BOOKMARK_FAILURE]: (state, action) => ({
      ...state,
      deleteBookmark: {
        ...state.deleteBookmark,
        loading: false,
        error: action.error.response,
        success: false,
      },
    }),
    [Actions.TALENT_GROUP_MEMBER_REQUEST]: state => ({
      ...state,
      profileModal: {
        ...state.profileModal,
        groupMember: {
          ...initialState.profileModal.groupMember,
          loading: true,
        },
      },
    }),
    [Actions.TALENT_GROUP_MEMBER_SUCCESS]: (state, action) => ({
      ...state,
      profileModal: {
        ...state.profileModal,
        groupMember: {
          ...initialState.profileModal.groupMember,
          success: true,
          value: action.response.result.length
            ? action.response.result[0]
            : null,
        },
      },
    }),
    [Actions.TALENT_GROUP_MEMBER_FAILURE]: (state, action) => ({
      ...state,
      profileModal: {
        ...state.profileModal,
        groupMember: {
          ...initialState.profileModal.groupMember,
          error: action.error.response,
        },
      },
    }),
    [Actions.RECOMMENDATIONS_BREAKDOWN_SUCCESS]: (state, action) => ({
      ...state,
      breakdown: action.payload,
    }),
  },
  initialState
);
