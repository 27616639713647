import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  ALL: {
    id: 'modules.Talents.Constants.all.talent',
    defaultMessage: 'All Talent',
  },
  BOOKMARKED: {
    id: 'modules.Talents.Constants.bookmarked',
    defaultMessage: 'Bookmarked',
  },
  AI: {
    id: 'modules.Talents.Constants.talenthunt+',
    defaultMessage: 'TalentHunt+',
  },
});

export const TABS = {
  ALL: {
    label: 'All Talent',
    emptyText: 'talent',
    key: 'ALL',
    isJobRequired: false,
    message: messages.ALL,
  },
  BOOKMARKED: {
    label: 'Bookmarked',
    emptyText: 'bookmarked talent',
    key: 'BOOKMARKED',
    isJobRequired: true,
    message: messages.BOOKMARKED,
  },
};

export const TALENTS_LOCATION_QUERIES = {
  PAGE: 'page',
};

export const LIMIT_PER_PAGE = 10;

export const TalentHuntMembershipStates = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const Actions = {
  SEARCH_TALENTS_REQUEST: 'glints/talents/SEARCH_TALENTS_REQUEST',
  SEARCH_TALENTS_SUCCESS: 'glints/talents/SEARCH_TALENTS_SUCCESS',
  SEARCH_TALENTS_FAILURE: 'glints/talents/SEARCH_TALENTS_FAILURE',

  BOOKMARKS_REQUEST: 'glints/talents/BOOKMARKS_REQUEST',
  BOOKMARKS_SUCCESS: 'glints/talents/bookmarks/BOOKMARKS_SUCCESS',
  BOOKMARKS_FAILURE: 'glints/talents/bookmarks/BOOKMARKS_FAILURE',

  TALENTHUNT_COMPANIES_REQUEST: 'glints/talents/TALENTHUNT_COMPANIES_REQUEST',
  TALENTHUNT_COMPANIES_SUCCESS: 'glints/talents/TALENTHUNT_COMPANIES_SUCCESS',
  TALENTHUNT_COMPANIES_FAILURE: 'glints/talents/TALENTHUNT_COMPANIES_FAILURE',

  TALENTHUNT_JOBS_REQUEST: 'glints/talents/TALENTHUNT_JOBS_REQUEST',
  TALENTHUNT_JOBS_SUCCESS: 'glints/talents/TALENTHUNT_JOBS_SUCCESS',
  TALENTHUNT_JOBS_FAILURE: 'glints/talents/TALENTHUNT_JOBS_FAILURE',

  TALENTS_REQUEST: 'glints/talents/TALENTS_REQUEST',
  TALENTS_SUCCESS: 'glints/talents/TALENTS_SUCCESS',
  TALENTS_FAILURE: 'glints/talents/TALENTS_FAILURE',

  SET_ACTIVE_TAB: 'glints/talents/SET_ACTIVE_TAB',

  RESET_TALENT_SEARCH_STATE: 'glints/talents/RESET_TALENT_SEARCH_STATE',

  UPDATE_COMPANY_SEARCH_TERM: 'glints/talents/UPDATE_COMPANY_SEARCH_TERM',

  UPDATE_COMPANY_FOR_PAGE: 'glints/talents/UPDATE_COMPANY_FOR_PAGE',
  UPDATE_JOB_FOR_PAGE: 'glints/talents/UPDATE_JOB_FOR_PAGE',

  SET_CURRENT_PAGE: 'glints/talents/SET_CURRENT_PAGE',

  DELETE_BOOKMARK_REQUEST: 'glints/talents/DELETE_BOOKMARK_REQUEST',
  DELETE_BOOKMARK_SUCCESS: 'glints/talents/DELETE_BOOKMARK_SUCCESS',
  DELETE_BOOKMARK_FAILURE: 'glints/talents/DELETE_BOOKMARK_FAILURE',

  BOOKMARKS_COUNT_REQUEST: 'glints/talents/BOOKMARKS_COUNT_REQUEST',
  BOOKMARKS_COUNT_SUCCESS: 'glints/talents/BOOKMARKS_COUNT_SUCCESS',
  BOOKMARKS_COUNT_FAILURE: 'glints/talents/BOOKMARKS_COUNT_FAILURE',

  RECOMMENDATIONS_FOR_TALENTS_REQUEST:
    'glints/talents/RECOMMENDATIONS_FOR_TALENTS_REQUEST',
  RECOMMENDATIONS_FOR_TALENTS_SUCCESS:
    'glints/talents/RECOMMENDATIONS_FOR_TALENTS_SUCCESS',
  RECOMMENDATIONS_FOR_TALENTS_FAILURE:
    'glints/talents/RECOMMENDATIONS_FOR_TALENTS_FAILURE',

  INCREASE_BOOKMARK_COUNT: 'glints/talents/INCREASE_BOOKMARK_COUNT',

  ADD_BOOKMARK_COUNT_REQUEST: 'glints/talents/ADD_BOOKMARK_COUNT_REQUEST',
  ADD_BOOKMARK_COUNT_SUCCESS: 'glints/talents/ADD_BOOKMARK_COUNT_SUCCESS',
  ADD_BOOKMARK_COUNT_FAILURE: 'glints/talents/ADD_BOOKMARK_COUNT_FAILURE',
};
