export const TALENT_MODAL_VIEWS = {
  BOOKMARK: {
    view: 'bookmark',
    label: 'Bookmark Talent',
  },
  PROFILE: {
    view: 'profile',
    label: 'Talent Details',
  },
  RECOMMEND: {
    view: 'recommend',
    label: 'Recommend Talent',
  },
  RECOMMENDATION: {
    view: 'recommendation',
    label: 'Recommended Talent',
  },
};
