import { createAction } from 'redux-actions';

import { setTalentModalHasRecommendations } from './Profile/Actions';

export const Actions = {
  OPEN_TALENT_MODAL: 'glints/talentModal/modal/OPEN_TALENT_MODAL',

  SET_TALENT_MODAL_VIEW: 'glints/talentModal/modal/SET_TALENT_MODAL_VIEW',
  SET_TALENT_PROFILE_TAB: 'glints/talentModal/modal/SET_TALENT_PROFILE_TAB',

  RESET_TALENT_MODAL: 'glints/talentModal/modal/RESET_TALENT_MODAL',

  UPDATE_HIDE_GO_BACK_PROFILE:
    'glints/talentModal/modal/UPDATE_HIDE_GO_BACK_PROFILE',
};

export const openTalentModal = createAction(Actions.OPEN_TALENT_MODAL);

export const setTalentModalView = createAction(Actions.SET_TALENT_MODAL_VIEW);
export const setTalentProfileTab = createAction(Actions.SET_TALENT_PROFILE_TAB);

export const resetTalentModal = createAction(Actions.RESET_TALENT_MODAL);

export const updateHideGoBackProfile = createAction(
  Actions.UPDATE_HIDE_GO_BACK_PROFILE
);

export function showTalentModal({
  view,
  talentId,
  hasRecommendations = false,
  profileTab = null,
}) {
  return dispatch => {
    dispatch(setTalentModalView(view));
    dispatch(openTalentModal(talentId));
    dispatch(setTalentModalHasRecommendations(hasRecommendations));
    dispatch(setTalentProfileTab(profileTab));
  };
}
