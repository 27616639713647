import '@glints/poppins';
// Ordering is important here. We need to load the debug utils before any other imports
import './debugUtils';
import './modules/AppLanguage/initializeAppLanguage';
import './common/apm';
import './lazysizes';
import './polyfills';

import React from 'react';
import { ThumborProvider } from '@glints/fast-image';
import { CaptureConsole } from '@sentry/integrations';
import * as Sentry from '@sentry/react';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { ConnectedRouter } from 'connected-react-router';
import {
  AlertProvider,
  AlertWithProvider,
  ModalProvider,
  ModalWithProvider,
} from 'glints-aries/es/@next';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router';
import { QueryParamProvider } from 'use-query-params';

import { fetchFeatures } from './actions/features';
import { ApolloConfig } from './apollo-client';
import { App } from './app';
import { loadable } from './common/loadable';
import ErrorBoundaryWithCrashReporting from './components/Error/ErrorBoundaryWithCrashReporting';
import SessionInitializer from './components/SessionInitializer';
import config from './config';
import IntlProvider from './IntlProvider';
import { Error500Page } from './modules/ErrorPages';
import store, { browserHistory } from './store';
import { SWRConfig } from './swr';

const RecommendationActionLinkProcessorPageImport = import(
  /* webpackChunkName: "recommendation-action-link-processor-page" */ './modules/Recommendations/RecommendationActionLinkProcessorPage'
);
const RecommendationActionLinkProcessorPage = loadable(
  () => RecommendationActionLinkProcessorPageImport
);
Sentry.init({
  dsn: config.SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new CaptureConsole({
      levels: ['error'],
    }),
  ],

  // Release information, used for Sentry to decipher source maps.
  release: process.env.BUILD_ID,

  // Adjust this value in production, or use tracesSampler for greater control
  // https://docs.sentry.io/platforms/javascript/configuration/sampling/
  tracesSampleRate: 0.3,

  // Environment
  environment: config.ENVIRONMENT,

  // Disable on development and feature review app
  enabled: Boolean(config.SENTRY_DSN),

  // Adjust this value in the case of sending Redux state
  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
  normalizeDepth: 10,

  beforeBreadcrumb: excludeGraphQLFetch,
});

async function renderApp() {
  const container = document.getElementById('app');
  const root = createRoot(container as Element);
  store.dispatch(fetchFeatures());

  root.render(
    <Provider store={store}>
      <IntlProvider>
        <ErrorBoundaryWithCrashReporting fallback={<Error500Page />}>
          {/* @ts-ignore */}
          <ThumborProvider thumborServerURL={config.THUMBOR_BASE}>
            <ApolloConfig>
              <SWRConfig store={store}>
                {/* @ts-ignore */}
                <ConnectedRouter history={browserHistory}>
                  <QueryParamProvider ReactRouterRoute={Route}>
                    <Switch>
                      {/* This route lives here, outside of the session initializer */}
                      <Route
                        path="/process-recommendation-action-links"
                        component={RecommendationActionLinkProcessorPage}
                      />
                      <Route>
                        <ConfigProvider locale={enUS}>
                          <div className="app">
                            <SessionInitializer>
                              <AlertProvider>
                                <AlertWithProvider />
                                <ModalProvider>
                                  <ModalWithProvider />
                                  <App />
                                </ModalProvider>
                              </AlertProvider>
                            </SessionInitializer>
                          </div>
                        </ConfigProvider>
                      </Route>
                    </Switch>
                  </QueryParamProvider>
                </ConnectedRouter>
              </SWRConfig>
            </ApolloConfig>
          </ThumborProvider>
        </ErrorBoundaryWithCrashReporting>
      </IntlProvider>
    </Provider>
  );
}

renderApp();
