import React from 'react';
import { ButtonGroup, Icon } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import useIsWhatsAppIntegrationEnabled from '../../../../common/hooks/useIsWhatsAppIntegrationEnabled';
import {
  applicationChatContactsExchangeStatusMessages,
  applicationChatCVExchangeStatusMessages,
  ApplicationChatCVRequestActionType,
  ApplicationContactsExchangeStatus,
  ApplicationCVExchangeStatus,
} from '../../constants';
import {
  useChannelCVRequestMessage,
  useIsMobileVersion,
  useIsPopupMode,
} from '../../hooks';
import ApplicationStatusDropdown from '../ApplicationStatusDropdown';
import ChannelHeaderMenuButton from './ChannelHeaderMenuButton';
import { WhatsAppChannelHeaderMenuButton } from './WhatsAppMenuButton/WhatsAppChannelHeaderMenuButton';

type ExchangeActionProps<T> = {
  status: T;
  loading: boolean;
  onClick: () => void;
};

type ChannelHeaderDesktopMenuProps = {
  exchangeCV: ExchangeActionProps<ApplicationCVExchangeStatus>;
  exchangeContacts: ExchangeActionProps<ApplicationContactsExchangeStatus>;
};

const contactsExchangeStatusIcon = {
  [ApplicationContactsExchangeStatus.NONE]: <Icon name="ri-phone-line" />,
  [ApplicationContactsExchangeStatus.PENDING]: <Icon name="ri-timer3-line" />,
  [ApplicationContactsExchangeStatus.DONE]: <Icon name="ri-check" />,
};

const cvExchangeStatusIcon = {
  [ApplicationCVExchangeStatus.NONE]: <Icon name="ri-file-list-line" />,
  [ApplicationCVExchangeStatus.PENDING]: <Icon name="ri-timer3-line" />,
  [ApplicationCVExchangeStatus.DONE]: <Icon name="ri-check" />,
  [ApplicationCVExchangeStatus.AUTO_SHARED]: <Icon name="ri-check" />,
};

const Styled = {
  ButtonGroup: styled(ButtonGroup)<{
    isMobileVersion: boolean;
    isPopupVersion: boolean;
  }>`
    align-items: flex-start;
    justify-content: ${({ isPopupVersion }) =>
      isPopupVersion ? 'flex-start' : 'space-between'};

    ${({ isMobileVersion, isPopupVersion }) =>
      isMobileVersion
        ? {
            display: 'grid',
            gridTemplateColumns: isPopupVersion
              ? 'repeat(2, 1fr)'
              : 'repeat(3, 1fr)',
          }
        : undefined}
  `,
};

const ChannelHeaderMenus = ({
  exchangeContacts,
  exchangeCV,
}: ChannelHeaderDesktopMenuProps) => {
  const isPopupMode = useIsPopupMode();
  const isMobileVersion = useIsMobileVersion('ChannelHeaderMenus');
  const whatsAppEnabled = useIsWhatsAppIntegrationEnabled();
  const cvRequest = useChannelCVRequestMessage();
  const isCVRequestPendingEmpToAccept =
    exchangeCV.status === ApplicationCVExchangeStatus.PENDING &&
    cvRequest &&
    cvRequest.metadata.action_type !==
      ApplicationChatCVRequestActionType.CV_REQUEST_EMP;

  return (
    <Styled.ButtonGroup
      isMobileVersion={isMobileVersion}
      isPopupVersion={isPopupMode}
    >
      <Choose>
        <When condition={whatsAppEnabled}>
          <WhatsAppChannelHeaderMenuButton />
        </When>
        <Otherwise>
          <ChannelHeaderMenuButton
            icon={contactsExchangeStatusIcon[exchangeContacts.status]}
            iconPosition="left"
            disabled={exchangeContacts.loading}
            onClick={exchangeContacts.onClick}
            data-cy="exchange-phone-number-btn"
          >
            <FormattedMessage
              {...applicationChatContactsExchangeStatusMessages[
                exchangeContacts.status
              ]}
            />
          </ChannelHeaderMenuButton>
        </Otherwise>
      </Choose>

      <Choose>
        <When condition={isCVRequestPendingEmpToAccept}>
          <ChannelHeaderMenuButton
            icon={<Icon name="ri-file-list-line" />}
            iconPosition="left"
            disabled={exchangeCV.loading}
            onClick={exchangeCV.onClick}
            data-cy="exchange-cv-btn"
          >
            <FormattedMessage
              id="interactive-accept-resume"
              defaultMessage="Accept CV"
            />
          </ChannelHeaderMenuButton>
        </When>
        <Otherwise>
          <ChannelHeaderMenuButton
            icon={cvExchangeStatusIcon[exchangeCV.status]}
            iconPosition="left"
            disabled={exchangeCV.loading}
            onClick={exchangeCV.onClick}
            data-cy="exchange-cv-btn"
          >
            <FormattedMessage
              {...applicationChatCVExchangeStatusMessages[exchangeCV.status]}
            />
          </ChannelHeaderMenuButton>
        </Otherwise>
      </Choose>

      <If condition={!isPopupMode}>
        <ApplicationStatusDropdown />
      </If>
    </Styled.ButtonGroup>
  );
};

export default ChannelHeaderMenus;
