import React, { ReactNode } from 'react';
import { Flex } from 'glints-aries/es';
import {
  OutlineButton,
  PrimaryButton,
  Typography,
} from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  EmployerPurchaseProductType,
  JobBoostDetail,
} from '../../../../common/interfaces/entities';
import { ROUTES } from '../../../../common/routes';
import { CreditProductPurchaseOrder } from '../../types';
import { verticalSpacing } from '../spacing';
import { ProductIcon, StyledButtonGroup } from './styles.sc';

type AfterPurchaseActionLog = {
  afterPurchaseActionLog: {
    relatedJobId: string | null;
    job: {
      jobBoost: Pick<JobBoostDetail, 'boostEndsAt'>;
    } | null;
  } | null;
};

type Props = {
  creditProductPurchaseOrder: Pick<
    CreditProductPurchaseOrder,
    'companyProductInventory' | 'product'
  > &
    AfterPurchaseActionLog;
};

const messages = defineMessages({
  purchaseSuccessfully: {
    id: 'text-purchased-successfully',
    defaultMessage: 'Purchased successfully',
  },
  yourJobBoostsHaveBeenAdded: {
    id: 'text-your-job-boosts-have',
    defaultMessage: 'Your job boosts have been added',
  },
  nowYouHaveJobBoosts: {
    id: 'text-now-you-have-7-day',
    defaultMessage:
      'Now you have <b>{amount}</b>, {count}-days job boosts valid till <b>{dateTime}</b>',
  },
  jobBoostedSuccessfully: {
    id: 'text-job-successfully-boosted',
    defaultMessage: 'Job Successfully Boosted',
  },
  boostedTill: {
    id: 'text-this-job-boosted-till',
    defaultMessage: 'This job will be boosted till <b>{date}</b>.',
  },
  noJobBoosts: {
    id: 'text-now-you-have-no-job-boost',
    defaultMessage: 'Now you have 0 job boosts left.',
  },
});

const ModalTitle = ({ creditProductPurchaseOrder }: Props) => {
  const hasRelatedJob = Boolean(
    creditProductPurchaseOrder.afterPurchaseActionLog?.relatedJobId
  );
  return (
    <FormattedMessage
      {...(hasRelatedJob
        ? messages.jobBoostedSuccessfully
        : messages.purchaseSuccessfully)}
    />
  );
};

const ModalFooter = ({
  onClose,
  creditProductPurchaseOrder: {
    companyProductInventory,
    afterPurchaseActionLog,
  },
}: { onClose: () => void } & Props) => {
  const hasRelatedJob = Boolean(afterPurchaseActionLog?.relatedJobId);
  return (
    <StyledButtonGroup>
      <Choose>
        <When condition={hasRelatedJob}>
          <Choose>
            <When condition={companyProductInventory?.amount}>
              <Link
                to={`/${ROUTES.features}?tab=PURCHASES&purchasedTab=${EmployerPurchaseProductType.HOT_JOB}`}
              >
                <OutlineButton onClick={onClose}>
                  <Typography variant="button">
                    <FormattedMessage
                      id="interactive-view-job-boosts"
                      defaultMessage="View Job Boosts"
                    />
                  </Typography>
                </OutlineButton>
              </Link>
            </When>
            <Otherwise>
              <Link to={ROUTES.features}>
                <OutlineButton onClick={onClose}>
                  <Typography variant="button">
                    <FormattedMessage
                      id="interactive-buy-job-boosts"
                      defaultMessage="Buy More Job Boosts"
                    />
                  </Typography>
                </OutlineButton>
              </Link>
            </Otherwise>
          </Choose>

          <PrimaryButton onClick={onClose}>
            <Typography variant="button">
              <FormattedMessage id="interactive-done" defaultMessage="Done" />
            </Typography>
          </PrimaryButton>
        </When>
        <Otherwise>
          <OutlineButton onClick={onClose}>
            <Typography variant="button">
              <FormattedMessage id="interactive-close" defaultMessage="Close" />
            </Typography>
          </OutlineButton>
          <Link to={`/${ROUTES.dashboardRoute}`}>
            <PrimaryButton onClick={onClose}>
              <Typography variant="button">
                <FormattedMessage
                  id="interactive-boost-active-jobs"
                  defaultMessage="Boost Active Jobs"
                />
              </Typography>
            </PrimaryButton>
          </Link>
        </Otherwise>
      </Choose>
    </StyledButtonGroup>
  );
};

const Bold = (...chunks: ReactNode[]) => (
  <Typography variant="body2" as="span">
    {chunks}
  </Typography>
);

function JobBoostSuccessModalContentComponent({
  creditProductPurchaseOrder: {
    companyProductInventory,
    afterPurchaseActionLog,
    product: { variantDetail },
  },
}: Props) {
  const { formatDate, formatNumber } = useIntl();
  return (
    <Flex
      flexDirection="column"
      style={{
        gap: 20,
      }}
    >
      <ProductIcon
        src="/images/credit-system/job-boost-icon.svg"
        alt="Job Boost"
      />
      <div>
        <Typography variant="body2">
          <Choose>
            <When condition={afterPurchaseActionLog?.job}>
              {afterPurchaseActionLog?.job && (
                <FormattedMessage
                  {...messages.boostedTill}
                  values={{
                    b: (...chunks: ReactNode[]) => <span>{chunks}</span>,
                    date: formatDate(
                      afterPurchaseActionLog.job.jobBoost.boostEndsAt,
                      {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      }
                    ),
                  }}
                />
              )}
            </When>
            <Otherwise>
              <FormattedMessage {...messages.yourJobBoostsHaveBeenAdded} />
            </Otherwise>
          </Choose>
        </Typography>
        {verticalSpacing(4)}
        <Typography variant="body1" as="div" color={Neutral.B40}>
          <Choose>
            <When condition={companyProductInventory?.amount}>
              <FormattedMessage
                {...messages.nowYouHaveJobBoosts}
                values={{
                  amount: formatNumber(companyProductInventory?.amount || 0),
                  count: formatNumber(variantDetail.durationCount),
                  dateTime: companyProductInventory?.expiryDateTime
                    ? formatDate(companyProductInventory.expiryDateTime, {
                        year: 'numeric',
                        month: 'short',
                        day: '2-digit',
                      })
                    : '-',
                  b: Bold,
                }}
              />
            </When>
            <Otherwise>
              <FormattedMessage {...messages.noJobBoosts} />
            </Otherwise>
          </Choose>
        </Typography>
      </div>
    </Flex>
  );
}

export const JobBoostSuccessModalContent = Object.assign(
  JobBoostSuccessModalContentComponent,
  {
    ModalTitle,
    ModalFooter,
  }
);
