import React, { useLayoutEffect } from 'react';
import { Flex } from 'glints-aries/es';
import { Icon, PrimaryButton, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space16, space24 } from 'glints-aries/es/@next/utilities/spacing';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useMeasure } from 'react-use';
import styled from 'styled-components';

import { getConfig } from '../../config';
import useNavigationContext from '../Navigation/NavigationProvider/useNavigationContext';

const messages = defineMessages({
  title: {
    id: 'text-switch-app-for-better-experience',
    defaultMessage: 'Switch to the App for a better experience',
  },
  description: {
    id: 'text-switch-app-for-better-experience-desc',
    defaultMessage: 'Get the best experience by continuing in our mobile app',
  },
  action: {
    id: 'interactive-open-glints-app',
    defaultMessage: 'OPEN GLINTS APP',
  },
});

const languagesSupported = ['en', 'id', 'vi'];

export default function UseMobileAppPrompt() {
  const handleOpenApp = () => {
    const { EMPLOYER_APP_DASHBOARD_DEEP_LINK } = getConfig();
    window.open(EMPLOYER_APP_DASHBOARD_DEEP_LINK, '_blank');
  };
  const { LANG } = getConfig();
  const lang = languagesSupported.includes(LANG) ? LANG : 'en';
  const imgBasedOnLang = `/images/mobile-app-prompt/mobile-app-prompt-${String(
    lang
  ).toLowerCase()}.png`;
  const { setShowAlternativeHeader } = useNavigationContext();
  const [ref, { height }] = useMeasure<HTMLDivElement>();

  useLayoutEffect(() => {
    setShowAlternativeHeader({
      alternativeHeaderContent: null,
      showAlertNativeHeader: true,
    });
    return () => {
      setShowAlternativeHeader({
        alternativeHeaderContent: null,
        showAlertNativeHeader: false,
      });
    };
  }, [setShowAlternativeHeader]);

  return (
    <BackgroundContainer>
      <BackgroundImage
        src={imgBasedOnLang}
        style={{
          maxHeight: `calc(100vh - ${height}px)`,
        }}
      />
      <TextualContentContainer ref={ref}>
        <Flex flexDirection="column" gap={space16}>
          <GradientTitleText variant="headline3">
            <FormattedMessage {...messages.title} />
          </GradientTitleText>
          <Typography variant="subtitle1">
            <FormattedMessage {...messages.description} />
          </Typography>
        </Flex>

        <PrimaryButton
          fullWidth={true}
          icon={<Icon name="ri-external-link-line" />}
          size="large"
          onClick={handleOpenApp}
        >
          <Typography variant="button">
            <FormattedMessage {...messages.action} />
          </Typography>
        </PrimaryButton>
      </TextualContentContainer>
    </BackgroundContainer>
  );
}

const BackgroundImage = styled.img`
  width: 100%;
  object-fit: contain;
  object-position: center;
  margin-top: 20px;
`;
const BackgroundContainer = styled.div`
  min-height: inherit;
  position: relative;
  background: linear-gradient(0deg, #caeefb -0.36%, #f8fafc 99.64%);
`;

const TextualContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: ${space24} ${space16};
  gap: ${space24};
  text-align: center;
  background-color: ${Neutral.B100};
`;

const GradientTitleText = styled(Typography)`
  background: linear-gradient(62deg, #13d6f6 11.73%, #0187e4 64.07%);

  color: transparent;
  background-clip: text;
`;
