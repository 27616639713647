import React, {
  lazy,
  ReactNode,
  Suspense,
  useRef,
  useState,
  useTransition,
} from 'react';

import ErrorBoundaryWithCrashReporting from '../../../../../components/Error/ErrorBoundaryWithCrashReporting';
import VIPAfterPaymentStateHandlers from '../../../SharedComponents/VIPAfterPaymentStateHandlers';
import useShowCreditProductPurchaseSuccessModal from '../../CreditProductPurchaseSuccessModal/useShowCreditProductPurchaseSuccessModal';
import { CreditProductPurchaseOrderForAfterPayment } from '../../types';
import {
  GlintsVIPPurchaseModalContext,
  OpenModalOptions,
} from './GlintsVIPPurchaseModalContext';

const GlintsVIPPurchaseModal = lazy(
  () => import('./../GlintsVIPPurchaseModal')
);

type ProviderProps = {
  children: ReactNode;
};

export const GlintsVIPPurchaseModalProvider = ({ children }: ProviderProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [errorBannerProviderInitialValue, setErrorBannerProviderInitialValue] =
    useState<OpenModalOptions['errorBannerProviderInitialValue']>(undefined);
  const onCloseCallbackRef = useRef<OpenModalOptions['onClose']>(undefined);
  const onCloseCallback = onCloseCallbackRef.current;

  const [isModalChunkLoadPending, startTransition] = useTransition();

  const {
    creditProductPurchaseSuccessModal,
    openCreditProductPurchaseSuccessModal,
  } = useShowCreditProductPurchaseSuccessModal();

  const openGlintsVIPPurchaseModal = ({
    callback,
    errorBannerProviderInitialValue,
    onClose,
  }: OpenModalOptions = {}) => {
    if (isModalChunkLoadPending) {
      return;
    }
    setErrorBannerProviderInitialValue(errorBannerProviderInitialValue);
    onCloseCallbackRef.current = onClose;

    startTransition(() => {
      setIsModalOpen(true);
      return callback?.();
    });
  };

  const handleCloseModal = (
    creditProductPurchaseOrder?: CreditProductPurchaseOrderForAfterPayment
  ) => {
    onCloseCallback?.(creditProductPurchaseOrder);
    if (creditProductPurchaseOrder) {
      openCreditProductPurchaseSuccessModal(creditProductPurchaseOrder);
    }
    onCloseCallbackRef.current = undefined;
    setErrorBannerProviderInitialValue(undefined);
    setIsModalOpen(false);
  };

  return (
    <GlintsVIPPurchaseModalContext.Provider
      value={{
        openGlintsVIPPurchaseModal,
        isModalChunkLoadPending,
      }}
    >
      <ErrorBoundaryWithCrashReporting fallback={null}>
        <VIPAfterPaymentStateHandlers />
        <Suspense fallback={null}>
          {creditProductPurchaseSuccessModal}
          {isModalOpen && (
            <GlintsVIPPurchaseModal
              errorBannerProviderInitialValue={errorBannerProviderInitialValue}
              onClose={handleCloseModal}
            />
          )}
        </Suspense>
      </ErrorBoundaryWithCrashReporting>
      {children}
    </GlintsVIPPurchaseModalContext.Provider>
  );
};
