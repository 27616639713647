import React from 'react';
import { Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import { StickyContainer, StyledBanner } from '../styles.sc';

type Props = {
  setFixedBannerHeight: (height: number) => void;
  closeBanner: () => void;
};

export const SubscriptionPlanExceedingBanner = ({
  setFixedBannerHeight,
  closeBanner,
}: Props) => {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });

  return (
    <StickyContainer ref={ref}>
      <StyledBanner
        type="fixed"
        status="warning"
        showIcon={false}
        onDismiss={() => {
          closeBanner();
          setFixedBannerHeight(0);
        }}
      >
        <Typography variant="body2" color={Neutral.B18} as="div">
          <FormattedMessage
            id="interactive-exceeded-subscription-plan-limit"
            defaultMessage="You have exceeded your subscription plan’s limit. <link>See Job Slots Usage</link>"
            values={{
              link: (text: React.ReactNode) => (
                <Link to="/my-plan">{text}</Link>
              ),
            }}
          />
        </Typography>
      </StyledBanner>
    </StickyContainer>
  );
};
