import { handleActions } from 'redux-actions';

import { Actions as TalentModalBookmarkActions } from '../TalentModal/Bookmark/Actions';
import { Actions, TABS } from './Constants';

const initialState = {
  activeFilter: null,
  activeTab: TABS.ALL,
  page: 1,

  talents: {
    count: 0,
    error: null,
    loading: false,
    success: false,
    value: [],
  },

  bookmarks: {
    error: null,
    loading: false,
    success: false,
    value: [],
  },

  deleteBookmark: {
    id: null,
    loading: false,
    error: null,
    success: false,
  },

  talenthuntCompanies: {
    loading: false,
    error: null,
    searchTerm: '',
    value: [],
    company: null,
  },

  talenthuntJobs: {
    loading: false,
    error: null,
    value: [],
    job: null,
  },

  breakdown: {
    [TABS.BOOKMARKED.key]: 0,
  },

  recommendations: {
    loading: false,
    error: null,
    success: false,
    value: [],
  },

  bookmarkBreakdownCount: {
    value: {},
    loading: false,
  },
};

export default handleActions(
  {
    [Actions.SEARCH_TALENTS_REQUEST]: state => ({
      ...state,
      talents: {
        ...initialState.talents,
        count: state.talents.count,
        loading: true,
      },
    }),
    [Actions.SEARCH_TALENTS_SUCCESS]: (state, action) => ({
      ...state,
      talents: {
        ...state.talents,
        loading: false,
        error: null,
        value: action.response.result,
        count: action.response.count,
        success: true,
      },
    }),
    [Actions.SEARCH_TALENTS_FAILURE]: (state, action) => ({
      ...state,
      talents: {
        ...state.talents,
        loading: false,
        error: action.error,
        value: [],
        count: 0,
        success: false,
      },
      page: 1,
    }),
    [Actions.BOOKMARKS_REQUEST]: state => ({
      ...state,
      bookmarks: {
        ...initialState.bookmarks,
        loading: true,
      },
      talents: {
        ...initialState.talents,
        count: state.talents.count,
      },
    }),
    [Actions.BOOKMARKS_SUCCESS]: (state, action) => ({
      ...state,
      bookmarks: {
        ...state.bookmarks,
        error: null,
        loading: false,
        value: action.response.result,
      },
      talents: {
        ...state.talents,
        count: action.response.count,
        value: action.response.result.length
          ? Object.keys(action.response.entities.bookmark).map(
              x => action.response.entities.bookmark[x].CandidateId
            )
          : [],
      },
    }),
    [Actions.BOOKMARKS_FAILURE]: (state, action) => ({
      ...state,
      bookmarks: {
        ...initialState.bookmarks,
        error: action.error.response,
      },
      talents: initialState.talents,
    }),
    [Actions.SET_ACTIVE_TAB]: (state, action) => ({
      ...state,
      activeTab: action.payload,
      talents: initialState.talents,
      bookmarks: initialState.bookmarks,
      page: 1,
    }),
    [Actions.RESET_TALENT_SEARCH_STATE]: state => ({
      ...state,
      talents: initialState.talents,
    }),
    [Actions.TALENTHUNT_COMPANIES_REQUEST]: state => ({
      ...state,
      talenthuntCompanies: {
        ...state.talenthuntCompanies,
        value: [],
        loading: true,
        error: null,
      },
    }),
    [Actions.TALENTHUNT_COMPANIES_SUCCESS]: (state, action) => ({
      ...state,
      talenthuntCompanies: {
        ...state.talenthuntCompanies,
        value: action.response.result,
        loading: false,
        error: null,
      },
    }),
    [Actions.TALENTHUNT_COMPANIES_FAILURE]: (state, action) => ({
      ...state,
      talenthuntCompanies: {
        ...state.talenthuntCompanies,
        loading: false,
        error: action.response,
        value: [],
      },
    }),
    [Actions.UPDATE_COMPANY_SEARCH_TERM]: (state, action) => ({
      ...state,
      talenthuntCompanies: {
        ...state.talenthuntCompanies,
        searchTerm: action.payload,
      },
    }),
    [Actions.TALENTHUNT_JOBS_REQUEST]: state => ({
      ...state,
      talenthuntJobs: {
        ...state.talenthuntJobs,
        loading: true,
        error: null,
        value: [],
      },
    }),
    [Actions.TALENTHUNT_JOBS_SUCCESS]: (state, action) => ({
      ...state,
      talenthuntJobs: {
        ...state.talenthuntJobs,
        loading: false,
        error: null,
        value: action.response.result,
      },
    }),
    [Actions.TALENTHUNT_JOBS_FAILURE]: (state, action) => ({
      ...state,
      talenthuntJobs: {
        ...state.talenthuntJobs,
        loading: false,
        error: action.response,
        value: [],
      },
    }),
    [Actions.UPDATE_COMPANY_FOR_PAGE]: (state, action) => ({
      ...state,
      talenthuntCompanies: {
        ...state.talenthuntCompanies,
        company: action.payload,
      },
    }),
    [Actions.UPDATE_JOB_FOR_PAGE]: (state, action) => ({
      ...state,
      talenthuntJobs: {
        ...state.talenthuntJobs,
        job: action.payload,
      },
    }),
    [Actions.SET_CURRENT_PAGE]: (state, action) => ({
      ...state,
      page: action.payload,
    }),
    [Actions.SET_ACTIVE_FILTER]: (state, action) => ({
      ...state,
      activeFilter: action.payload,
    }),
    [Actions.TALENTS_REQUEST]: state => ({
      ...state,
      talents: {
        ...state.talents,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.TALENTS_SUCCESS]: state => ({
      ...state,
      talents: {
        ...state.talents,
        loading: false,
        success: true,
        error: null,
      },
    }),
    [Actions.TALENTS_FAILURE]: (state, action) => ({
      ...state,
      talents: {
        ...state.talents,
        loading: false,
        success: false,
        error: action.payload,
      },
    }),
    [Actions.DELETE_BOOKMARK_REQUEST]: (state, action) => ({
      ...state,
      deleteBookmark: {
        ...initialState.deleteBookmark,
        loading: true,
        id: action.payload,
      },
    }),
    [Actions.DELETE_BOOKMARK_SUCCESS]: (state, action) => ({
      ...state,
      deleteBookmark: {
        ...initialState.deleteBookmark,
        success: true,
      },
      bookmarks: {
        ...state.bookmarks,
        value: state.bookmarks.value.filter(id => id !== action.payload),
      },
      breakdown: {
        ...state.breakdown,
        [TABS.BOOKMARKED.key]:
          state.breakdown[TABS.BOOKMARKED.key] - 1 > 0
            ? state.breakdown[TABS.BOOKMARKED.key] - 1
            : 0,
      },
    }),
    [Actions.DELETE_BOOKMARK_FAILURE]: (state, action) => ({
      ...state,
      deleteBookmark: {
        ...initialState.deleteBookmark,
        error: action.payload,
      },
    }),
    [TalentModalBookmarkActions.CREATE_BOOKMARK_SUCCESS]: (state, action) => ({
      ...state,
      bookmarks: {
        ...state.bookmarks,
        value: [...state.bookmarks.value, action.response.result],
      },
    }),
    [Actions.BOOKMARKS_COUNT_SUCCESS]: (state, action) => ({
      ...state,
      breakdown: {
        ...state.breakdown,
        BOOKMARKED: action.payload,
      },
    }),
    [Actions.RECOMMENDATIONS_FOR_TALENTS_REQUEST]: state => ({
      ...state,
      recommendations: initialState.recommendations,
    }),
    [Actions.RECOMMENDATIONS_FOR_TALENTS_SUCCESS]: (state, action) => ({
      ...state,
      recommendations: {
        ...state.recommendations,
        value: action.response.result,
      },
    }),
    [Actions.RECOMMENDATIONS_FOR_TALENTS_FAILURE]: (state, action) => ({
      ...state,
      recommendations: {
        ...initialState.recommendations,
        error: action.error.response,
      },
    }),
    [Actions.INCREASE_BOOKMARK_COUNT]: state => ({
      ...state,
      breakdown: {
        ...state.breakdown,
        [TABS.BOOKMARKED.key]: state.breakdown[TABS.BOOKMARKED.key] + 1,
      },
    }),
  },
  initialState
);
