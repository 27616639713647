import React, { useCallback } from 'react';
import { Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { getIsLoggedIn } from '../../selectors/session';
import { canAccessMessaging } from '../Messaging/selectors';
import { toggleSidebar } from './Actions';
import CTAChat from './CTAChat';
import {
  LeftMenu,
  NavbarContainer,
  NavbarLogo,
  RightMenu,
} from './NavbarCommon.sc';
import { Hamburger } from './NavbarMobile.sc';

type NavbarMobileProps = {
  authenticated?: boolean;
};

const NavbarMobile: React.FC<NavbarMobileProps> = ({ authenticated }) => {
  const dispatch = useDispatch();

  const onMobileMenuClicked = useCallback(() => {
    dispatch(toggleSidebar());
  }, [dispatch]);

  const isLoggedIn = useSelector(getIsLoggedIn);
  const empCanAccessMessaging = useSelector(canAccessMessaging);

  return (
    <NavbarContainer>
      <LeftMenu mode="horizontal">
        <NavbarLogo to={isLoggedIn ? '/dashboard' : '/'} />
      </LeftMenu>

      <RightMenu mode="horizontal">
        <If condition={authenticated && empCanAccessMessaging}>
          <Menu.Item>
            <CTAChat />
          </Menu.Item>
        </If>
        <Menu.Item>
          <div aria-label="Open sidebar menu" onClick={onMobileMenuClicked}>
            <Hamburger />
          </div>
        </Menu.Item>
      </RightMenu>
    </NavbarContainer>
  );
};

export default NavbarMobile;
