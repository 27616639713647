import config from '../../config';

export const FIELDS = {
  name: 'name',
  logo: 'logo',
  website: 'website',
  size: 'size',
  tagline: 'tagline',
  address: 'address',
  legalRegistrationNumber: 'legalRegistrationNumber',
  legalDocument: 'legalDocument',
  vnTaxCode: 'vnTaxCode',
  vnBusinessRegistrationCertDate: 'vnBusinessRegistrationCertDate',
  vnBusinessRegistrationCertPlace: 'vnBusinessRegistrationCertPlace',
  contactMethod: 'contactMethod',
  industry: 'industry',
  countryCode: 'countryCode',
  cityId: 'cityId',
  locationProvinceId: 'locationProvinceId',
  locationCityId: 'locationCityId',
};

export const VALIDATION_ERRORS = {
  nameRequired: 'nameRequired',
  nameTooLong: 'nameTooLong',
  sizeRequired: 'sizeRequired',
  taglineRequired: 'taglineRequired',
  taglineTooLong: 'taglineTooLong',
  websiteInvalid: 'websiteInvalid',
  addressTooLong: 'addressTooLong',
  legalRegistrationNumberInvalid: 'legalRegistrationNumberInvalid',
  vnTaxCodeInvalid: 'vnTaxCodeInvalid',
  vnTaxCodeRequired: 'vnTaxCodeRequired',
  vnBusinessRegistrationCertDateRequired:
    'vnBusinessRegistrationCertDateRequired',
  vnBusinessRegistrationCertPlaceRequired:
    'vnBusinessRegistrationCertPlaceRequired',
  contactMethodRequired: 'contactMethodRequired',
  vnFieldTooLong: 'vnFieldTooLong',
  nameInvalid: 'nameInvalid',
  industryRequired: 'industryRequired',
  countryCodeRequired: 'countryCodeRequired',
  cityIdRequired: 'cityIdRequired',
  locationProvinceIdRequired: 'locationProvinceIdRequired',
  locationCityIdRequired: 'locationCityIdRequired',
};

export const initialValues = {
  [FIELDS.name]: '',
  [FIELDS.logo]: '',
  [FIELDS.size]: '',
  [FIELDS.website]: '',
  [FIELDS.tagline]: '',
  [FIELDS.address]: '',
  [FIELDS.legalRegistrationNumber]: '',
  [FIELDS.legalDocument]: '',
  [FIELDS.vnTaxCode]: '',
  [FIELDS.vnBusinessRegistrationCertDate]: '',
  [FIELDS.vnBusinessRegistrationCertPlace]: '',
  [FIELDS.contactMethod]: '',
  [FIELDS.industry]: '',
  [FIELDS.countryCode]: config.COUNTRY?.toUpperCase(),
  [FIELDS.cityId]: '',
  [FIELDS.locationProvinceId]: '',
  [FIELDS.locationCityId]: '',
};

export const MAX_FILE_SIZE_BYTES = 50;
