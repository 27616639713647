import React from 'react';
import { Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space12 } from 'glints-aries/es/@next/utilities/spacing';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';

import { VIPMembershipBenefit } from './types';

type Props = VIPMembershipBenefit & {
  hideDescription?: boolean;
};

export default function GlintsVIPBenefit({
  imageSrc,
  heading,
  description,
  hideDescription,
}: Props) {
  return (
    <BenefitContainer data-align-center={hideDescription}>
      <IconContainer src={imageSrc} />
      <div>
        <Typography variant="body2">
          <FormattedMessage {...heading} />
        </Typography>
        <If condition={!hideDescription}>
          <Typography variant="body1" color={Neutral.B40}>
            <FormattedMessage {...description} />
          </Typography>
        </If>
      </div>
    </BenefitContainer>
  );
}

export const BenefitContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${space12};

  &[data-align-center='true'] {
    align-items: center;
  }
`;

export const IconContainer = styled.img`
  width: 40px;
`;
