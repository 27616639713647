import React from 'react';
import * as dayjs from 'dayjs';
import { Flex, Greyscale } from 'glints-aries';
import { Icon, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';

import { CANDIDATE_STATUSES_LABELS } from '../../../common/constants';
import {
  getEducation,
  getMostRecentEducation,
  getMostRecentWork,
  getWorkExperience,
} from '../../../common/filters/experiences';
import { WorkExperience } from '../../../common/interfaces/entities';
import { ExperienceRange } from '../../../components/ExperienceRange';
import NodesJoin from '../../../components/NodesJoin';
import { ApplicationData, GetApplicationByIdData } from '../graphql';
import ApplicantLatestEducation from './ApplicantLatestEducation';
import MiddleDot from './MiddleDot';

type Props = {
  applicant: GetApplicationByIdData['getApplicationById']['applicant'];
};

const getCareerDurationInMonths = (date?: Date) => {
  if (!date || dayjs(date).isSame('0001-01-01')) return 0;
  return dayjs().diff(date, 'month');
};

const ApplicantInfo = ({ applicant }: Props) => {
  const locationString = [
    applicant.location?.formattedName ?? applicant.city?.name,
    applicant.country.name,
  ]
    .filter(Boolean)
    .join(', ');

  const mostRecentWorkExp: WorkExperience | undefined = getMostRecentWork(
    getWorkExperience(applicant.experiences)
  );

  const mostRecentEdu:
    | ApplicationData['applicant']['experiences'][number]
    | undefined = getMostRecentEducation(getEducation(applicant.experiences));

  const careerDurationInMonths = getCareerDurationInMonths(
    applicant.careerStartDate
  );

  return (
    <Flex
      data-cy="applicant-info-message"
      p={16}
      flexDirection="column"
      style={{
        gap: 8,
        border: `1px solid ${Neutral.B85}`,
        borderRadius: 8,
        backgroundColor: Greyscale.white,
      }}
    >
      <Typography
        variant="body2"
        as="b"
        style={{
          maxWidth: '100%',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
      >
        {applicant.firstName} {applicant.lastName}
      </Typography>
      <Typography variant="subtitle2" color={Greyscale.devilsgrey}>
        <NodesJoin separator={<MiddleDot />}>
          <span>{locationString}</span>
          {careerDurationInMonths >= 12 && (
            <FormattedMessage
              id="text_yoe-count"
              defaultMessage="{yearCount} {yearCount, plural, one {year} other {years}} of experience"
              values={{
                yearCount: Math.round(careerDurationInMonths / 12),
              }}
            />
          )}
          <FormattedMessage
            {...CANDIDATE_STATUSES_LABELS[applicant.candidateStatus]}
          />
        </NodesJoin>
      </Typography>
      {mostRecentWorkExp && (
        <Flex alignItems="center" style={{ gap: 8 }}>
          <Icon
            name="ri-briefcase-line"
            width={20}
            height={20}
            fill={Greyscale.devilsgrey}
          />
          <Typography variant="subtitle2" color={Neutral.B18}>
            <FormattedMessage
              id="text_work-experience-combined"
              defaultMessage="{positionTitle} at {organizationName}"
              values={{
                positionTitle: mostRecentWorkExp.title,
                organizationName: mostRecentWorkExp.organization,
              }}
            />
            &nbsp;
            <ExperienceRange experience={mostRecentWorkExp} isInline={true} />
          </Typography>
        </Flex>
      )}
      {mostRecentEdu && (
        <Flex alignItems="center" style={{ gap: 8 }}>
          <Icon
            name="ri-graduation-line"
            width={20}
            height={20}
            fill={Greyscale.devilsgrey}
          />
          <Typography variant="subtitle2" color={Neutral.B18}>
            <ApplicantLatestEducation education={mostRecentEdu} />
          </Typography>
        </Flex>
      )}
    </Flex>
  );
};

export default ApplicantInfo;
