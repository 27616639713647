import React, { ComponentProps } from 'react';
import { apm } from '@elastic/apm-rum';
import { ErrorBoundary } from 'react-error-boundary';

import EmptyErrorBoundaryFallbackComponent from '../EmptyErrorBoundaryFallbackComponent';
type ErrorBoundaryWithCrashReportingProps = {
  children: React.ReactNode;
  fallback: ComponentProps<typeof ErrorBoundary>['fallback'];
  /**
   Override the default onError behavior
   **/
  onError?: ComponentProps<typeof ErrorBoundary>['onError'];
};

export default function ErrorBoundaryWithCrashReporting({
  children,
  fallback,
  onError,
}: ErrorBoundaryWithCrashReportingProps) {
  return (
    <ErrorBoundary
      fallback={fallback || <EmptyErrorBoundaryFallbackComponent />}
      onError={(error, info) => {
        if (onError) {
          onError(error, info);
          return;
        }
        apm.captureError(error);
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
