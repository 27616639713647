import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

const initialState = {
  company: null,
  job: null,
  note: '',
  loading: false,
  error: null,
  success: false,
};

export default handleActions(
  {
    [Actions.UPDATE_COMPANY_FOR_BOOKMARK]: (state, action) => ({
      ...state,
      company: action.payload,
    }),
    [Actions.UPDATE_JOB_FOR_BOOKMARK]: (state, action) => ({
      ...state,
      job: action.payload,
    }),
    [Actions.RESET_BOOKMARK_STATE]: () => initialState,
    [Actions.UPDATE_NOTE]: (state, action) => ({
      ...state,
      note: action.payload,
    }),
    [Actions.CREATE_BOOKMARK_REQUEST]: state => ({
      ...state,
      loading: true,
      error: null,
      success: false,
    }),
    [Actions.CREATE_BOOKMARK_SUCCESS]: state => ({
      ...state,
      loading: false,
      error: null,
      success: true,
    }),
    [Actions.CREATE_BOOKMARK_FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.response,
      success: false,
    }),
  },
  initialState
);
