import { Actions as EntityActions } from './actions/entities';
import { Actions as ApplicationActions } from './modules/Application/Actions';
import { Actions as CandidatePageRecommendationActions } from './modules/CandidatePage/RecommendationPage/Actions';
import { Actions as FindTalentActions } from './modules/FindTalent/Constants';
import { translateFilterValue as FindTalentTranslateFilterValue } from './modules/FindTalent/transferFilterValue';
import { Actions as RecommendationActions } from './modules/Recommendations/Constants';
import { Actions as TalentModalActions } from './modules/TalentModal/Recommend/Actions';
import { Actions as TalentsActions } from './modules/Talents/Constants';

const GTM_JOB_APPLICATION_TYPES = {
  RECOMMENDATION: 'recommendation',
  APPLICATION: 'application',
};

const handleAction = {
  [TalentsActions.SEARCH_TALENTS_REQUEST]: state => ({
    findTalentSearchParams: {
      skills: state.talentSearch.skills.value.map(
        id => state.entities.skill[id].name
      ),
      jobTypes: state.talentSearch.jobTypes.value,
      candidateStatus: state.talentSearch.candidateStatus.value,
      curationStatus: state.talentSearch.curationStatus.value,
      matchCriterion: state.talentSearch.matchCriterion.value ? 'all' : 'any',
      nationalities: state.talentSearch.nationalities.value,
      legalToWork: state.talentSearch.nationalities.legalToWork,
      schools: state.talentSearch.schools.value.map(
        id => state.entities.school[id].name
      ),
      currentCountries: state.talentSearch.locations.currentCountries,
      currentCities: state.talentSearch.locations.currentCities.map(id => ({
        name: state.entities.city[id].name,
        countryCode: state.entities.city[id].CountryCode,
      })),
      preferredCountries: state.talentSearch.locations.preferredCountries,
      jobCategories: state.talentSearch.jobFunction.jobCategories.map(
        id => state.entities.jobCategory[id].name
      ),
      jobTitles: state.talentSearch.jobFunction.jobTitles.map(
        id => state.entities.jobTitle[id].name
      ),
      sort: state.talentSearch.sort.value,
      keywords: state.talentSearch.keywords.value,
      workTitles: state.talentSearch.workTitles,
      workDescriptions: state.talentSearch.workDescriptions,
      fieldsOfStudy: state.talentSearch.fieldsOfStudy,
      resumeSearchStrings: state.talentSearch.resumeSearchstrings,
      hasPhoneNumber: state.talentSearch.hasPhoneNumber,
      hasResume: state.talentSearch.hasResume,
      minimumExperience: state.talentSearch.minimumExperience,
      hideHasBeenReserved: state.talentSearch.hide.hasBeenReserved,
      hideHasBeenInterviewed: state.talentSearch.hide.hasBeenInterviewed,
      hideHasBeenOffered: state.talentSearch.hide.hasBeenOffered,
      hideHasBeenPlaced: state.talentSearch.hide.hasBeenPlaced,
    },
  }),
  [RecommendationActions.APPROVE_RECOMMENDATION_SUCCESS]: state => {
    const recommendation =
      state?.entities?.recommendation?.[
        state.recommendations.approve.recommendationId
      ] ?? {};

    return {
      isMadeByAI: recommendation.isMadeByAI,
      jobId: recommendation.JobId,
      candidateId: recommendation.CandidateId,
    };
  },
  [CandidatePageRecommendationActions.APPROVE_RECOMMENDATION_SUCCESS]: (
    state,
    action
  ) => {
    const recommendation =
      state.entities.recommendation[action.response.result] || {};

    return {
      isMadeByAI: recommendation.isMadeByAI,
      jobId: recommendation.JobId,
      candidateId: recommendation.CandidateId,
    };
  },
  [RecommendationActions.REJECT_RECOMMENDATION_SUCCESS]: state => {
    const recommendation =
      state?.entities?.recommendation?.[
        state.recommendations.reject.recommendationId
      ] ?? {};

    return {
      isMadeByAI: recommendation.isMadeByAI,
      jobId: recommendation.JobId,
      candidateId: recommendation.CandidateId,
    };
  },
  [CandidatePageRecommendationActions.REJECT_RECOMMENDATION_SUCCESS]: (
    state,
    action
  ) => ({
    isMadeByAI:
      state.entities.recommendation[action.response.result].isMadeByAI,
  }),
  [TalentModalActions.CREATE_RECOMMENDATION_SUCCESS]: (state, action) => {
    const recommendation = action?.payload?.data ?? {};

    return {
      jobId: recommendation.JobId,
      candidateId: recommendation.CandidateId,
    };
  },
  [ApplicationActions.UPDATE_APPLICATION_STATUS_SUCCESS]: (_state, action) => {
    const datalayerVars = {
      applicationStatus:
        action.response.entities.application[action.response.result.updates[0]]
          .status,
      fromTalentHuntJobApplication: false,
    };
    if (action.payload) {
      datalayerVars.changeCandidatePipelineStatusProps = action.payload.data;
    }
    return datalayerVars;
  },
  [EntityActions.UPDATE_TALENTHUNT_APPLICATION_ENTITY]: (_state, action) => {
    const talentHuntApplication = action.payload.data;
    if (talentHuntApplication.RecommendationId) {
      return {
        isMadeByAI: talentHuntApplication.isMadeByAI,
        fromTalentHuntJobApplication: true,
        talentHuntJobApplicationStatus: talentHuntApplication.status,
        gtmJobApplicationType: GTM_JOB_APPLICATION_TYPES.RECOMMENDATION,
        changeCandidatePipelineStatusProps: {
          topTenCandidateIds: talentHuntApplication.topTenCandidateIds,
          filter: talentHuntApplication.filter,
          sort: talentHuntApplication.sort,
          // These ids from EmployerATSPage is in camelCase while others are in PascalCase.
          jobId: talentHuntApplication.jobId || talentHuntApplication.JobId,
          candidateId:
            talentHuntApplication.candidateId ||
            talentHuntApplication.CandidateId,
        },
      };
    } else if (talentHuntApplication.ApplicationId) {
      return {
        fromTalentHuntJobApplication: true,
        talentHuntJobApplicationId: talentHuntApplication.ApplicationId,
        talentHuntJobApplicationJobId: talentHuntApplication.jobId,
        talentHuntJobApplicationStatus: talentHuntApplication.status,
        gtmJobApplicationType: GTM_JOB_APPLICATION_TYPES.APPLICATION,
      };
    } else {
      return {};
    }
  },
  [EntityActions.UPDATE_JOB_ENTITY]: (_state, action) => {
    const jobStatus = action.payload.data.status;
    return { jobStatus };
  },
  [FindTalentActions.GTM_TRACK_APPLY_FILTER]: (_state, action) => {
    const { key, value } = action.payload;
    const translatedValue = FindTalentTranslateFilterValue(_state, key, value);

    return {
      payload: {
        key,
        value: translatedValue,
      },
    };
  },
};

export default (state, action) => {
  const actionHandler = handleAction[action.type];
  if (actionHandler) {
    return actionHandler(state, action);
  } else {
    return {};
  }
};
