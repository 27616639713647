import React from 'react';
import type { IntlShape } from 'react-intl';

import { ACTIVITIES, authorizeCheck } from '../Platform';
import { MenuItem, MenuType } from '../Platform/Constants';

export const isAbsoluteURL = (url: string) => /^(?:[a-z]+:)?\/\//i.test(url);

export function disableLink(
  role: string,
  company: unknown,
  permissions: unknown
) {
  // we use undefined instead of !company is because the default value of
  // session.data.company is null
  // whereas if a Company account has no attached company, session.data.company will be undefined
  return (
    authorizeCheck(ACTIVITIES.ACCESS_COMPANY_MENU_ITEMS, role, permissions) &&
    company === undefined
  );
}

export const filteredMenuItems = (
  menuItems: MenuItem[] = [],
  authenticated: boolean,
  isBuilderEnabled: boolean
) => {
  if (isBuilderEnabled && authenticated) {
    return menuItems.filter(item =>
      [
        'dashboard',
        'features',
        'subscriptionPlans',
        'talentSearch',
        'cvFinder',
        'recommendedTalent',
        'myPlan',
        'eats',
      ].includes(item.value)
    );
  }
  return menuItems.filter(
    item =>
      item.type === MenuType.general &&
      Boolean(item.isPublicOnly) === !authenticated
  );
};

export const renderItemLabel = (intl: IntlShape, label: MenuItem['label']) =>
  React.isValidElement(label) || typeof label === 'string'
    ? label
    : intl.formatMessage(label as { id: string; defaultMessage: string });
