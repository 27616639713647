import { defineMessages, MessageDescriptor } from 'react-intl';

import { TimePeriodUnit } from './types';

export const GraphqlErrorCodes = {
  OPERATION_NOT_ALLOWED: 'OPERATION_NOT_ALLOWED',
  INSUFFICIENT_BALANCE: 'INSUFFICIENT_BALANCE',
  JOB_ALREADY_BOOSTED: 'JOB_ALREADY_BOOSTED',
  JOB_NO_LONGER_SUPPORT: 'JOB_NO_LONGER_SUPPORT',
  JOB_ALREADY_USING_THE_PRODUCT: 'JOB_ALREADY_USING_THE_PRODUCT',
  CREDIT_PURCHASE_ORDER_CANCELLED: 'CREDIT_PURCHASE_ORDER_CANCELLED',
  CREDIT_PURCHASE_ORDER_EXPIRED: 'CREDIT_PURCHASE_ORDER_EXPIRED',
} as const;

export const TrackingProductNames = {
  topUpCredits: 'top_up_credits',
  hotJob: 'hotjob',
  recommendedTalent: 'recommended_talent',
  vipMembership: 'vip_membership',
  talentSearch: 'talent_search',
} as const;

export type TrackingProductNames =
  (typeof TrackingProductNames)[keyof typeof TrackingProductNames];

export const timePeriodUnitToText: Record<TimePeriodUnit, MessageDescriptor> =
  defineMessages({
    [TimePeriodUnit.days]: {
      id: 'text-days-count',
      defaultMessage: '{count, plural,  =1 {day} other {days}}',
    },
    [TimePeriodUnit.months]: {
      id: 'text-months-count',
      defaultMessage: '{count, plural, =1 {month} other {months}}',
    },
    [TimePeriodUnit.years]: {
      id: 'text-years-count',
      defaultMessage: '{count, plural, =1 {year} other {years}}',
    },
  });
