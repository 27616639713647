import { createAction } from 'redux-actions';

import axios from '../../../common/axios-ts';
import { getTalentModalTalentId } from '../Selectors';
import { getRecommendJob, getRecommendNote } from './Selectors';

export const Actions = {
  UPDATE_COMPANY_FOR_RECOMMENDATION:
    'glints/talentModal/recommend/UPDATE_COMPANY_FOR_RECOMMENDATION',
  UPDATE_JOB_FOR_RECOMMENDATION:
    'glints/talentModal/recommend/UPDATE_JOB_FOR_RECOMMENDATION',

  UPDATE_NOTE: 'glints/talentModal/recommend/UPDATE_NOTE',

  RESET_RECOMMEND_STATE: 'glints/talentModal/recommend/RESET_RECOMMEND_STATE',

  CREATE_RECOMMENDATION_REQUEST:
    'glints/talentModal/recommend/CREATE_RECOMMENDATION_REQUEST',
  CREATE_RECOMMENDATION_SUCCESS:
    'glints/talentModal/recommend/CREATE_RECOMMENDATION_SUCCESS',
  CREATE_RECOMMENDATION_FAILURE:
    'glints/talentModal/recommend/CREATE_RECOMMENDATION_FAILURE',
};

export const updateCompanyForRecommendation = createAction(
  Actions.UPDATE_COMPANY_FOR_RECOMMENDATION
);
export const updateJobForRecommendation = createAction(
  Actions.UPDATE_JOB_FOR_RECOMMENDATION
);

export const updateNote = createAction(Actions.UPDATE_NOTE);

export const resetRecommendState = createAction(Actions.RESET_RECOMMEND_STATE);

export const createRecommendationRequest = createAction(
  Actions.CREATE_RECOMMENDATION_REQUEST
);
export const createRecommendationSuccess = createAction(
  Actions.CREATE_RECOMMENDATION_SUCCESS
);
export const createRecommendationFailure = createAction(
  Actions.CREATE_RECOMMENDATION_FAILURE
);

export function createRecommendation() {
  return async (dispatch, getState) => {
    try {
      const state = getState();

      dispatch(createRecommendationRequest());
      const response = await axios(getState().session.token).put(
        'recruiterats/jobApplications',
        {
          data: {
            status: 'PENDING',
            CandidateId: getTalentModalTalentId(state),
            JobId: getRecommendJob(state).id,
            note: getRecommendNote(state),
          },
        }
      );

      dispatch(createRecommendationSuccess(response.data));
    } catch (err) {
      dispatch(createRecommendationFailure(err));
    }
  };
}
