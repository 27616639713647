/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Flex, Greyscale, ScreenSize } from 'glints-aries';
import {
  Badge,
  Button,
  Icon,
  Tooltip,
  Typography,
} from 'glints-aries/es/@next';
import { Green, Neutral } from 'glints-aries/es/@next/utilities/colors';
import {
  space4,
  space8,
  space12,
} from 'glints-aries/es/@next/utilities/spacing';
import { get } from 'lodash-es';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Avatar,
  ChannelHeader,
  useChannelActionContext,
  useChannelStateContext,
} from 'stream-chat-react';
import styled from 'styled-components';
import { StringParam, useQueryParam } from 'use-query-params';

import {
  ApplicationSource,
  ApplicationStatus,
} from '../../../../common/constants';
import Card from '../../../../components/cards/Card';
import RejectApplicationButton from '../../../../components/RejectApplicationButton/RejectApplicationButton';
import UserLastSeenActivityStatus from '../../../../components/UserLastSeenActivityStatus/UserLastSeenActivityStatus';
import UserLastSeenBadgeAvatar from '../../../../components/UserLastSeenActivityStatus/UserLastSeenBadgeAvatar';
import { getUserPhoneNumber } from '../../../../selectors/user';
import {
  ApplicationContactsExchangeStatus,
  ApplicationCVExchangeStatus,
  ChannelQueryParam,
  MessagingUIMode,
  useMessagingChannelContext,
} from '../../constants';
import {
  useCreateShareContactsRequest,
  useCreateShareResumeRequest,
  useGetApplicationById,
} from '../../graphql';
import { getChannelApplicationMetadata } from '../../helper';
import {
  useIsGroupChatEnabled,
  useIsMobileVersion,
  useIsPopupMode,
  useRebuildComponentOnChannelEvent,
} from '../../hooks';
import useHandleApplicationStatusChangeForChat from '../../hooks/useHandleApplicationStatusChangeForChat';
import { trackChatPageViewedEvent } from '../../tracking';
import MessagingApplicationJobTitle from '../ApplicationJobTitle';
import ChannelMembersModal from '../ChannelMembersModal';
import { ClippedTypography } from '../Styled';
import ChannelHeaderMenus from './ChannelHeaderMenus';

const Styled = {
  ResponsiveContainer: styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media (max-width: ${ScreenSize.desktopM - 1}px) {
      flex-direction: column;
      gap: 10px;
    }
  `,
  IconButton: styled(Icon)`
    cursor: pointer;
    width: 24px;
    fill: ${Greyscale.devilsgrey};
  `,
  PopupHeader: styled.div`
    display: grid;
    gap: ${space8};
    grid-template-columns: repeat(3, auto);
    align-items: center;
  `,
  AvatarContainer: styled.div`
    margin-right: 8px;
    .str-chat__avatar--circle {
      margin-right: 0px;
    }
  `,
};

const LastSeenAt = (props: { applicationId: string }) => {
  const { data } = useGetApplicationById({
    fetchPolicy: 'cache-first',
    variables: {
      id: props.applicationId,
    },
  });
  const lastSeen = data?.getApplicationById?.applicant?.lastSeen;
  if (!lastSeen) return null;

  return <UserLastSeenActivityStatus lastSeen={lastSeen} />;
};

const ChatTabUserAvatar = (props: {
  applicationId: string;
  image?: string;
  name?: string;
}) => {
  const { data } = useGetApplicationById({
    fetchPolicy: 'cache-first',
    variables: {
      id: props.applicationId,
    },
  });
  const lastSeen = data?.getApplicationById?.applicant?.lastSeen;
  return (
    <Styled.AvatarContainer>
      <UserLastSeenBadgeAvatar lastSeenAt={lastSeen} indicatorSize={15}>
        <Avatar
          image={props.image}
          name={props.name}
          shape="circle"
          size={32}
        />
      </UserLastSeenBadgeAvatar>
    </Styled.AvatarContainer>
  );
};

const applicationSourceToText: Record<
  Extract<ApplicationSource, 'TALENT_SEARCH_WEB' | 'RECOMMENDED_TALENT'>,
  {
    popUpModeText: MessageDescriptor;
    fullChatModeText: MessageDescriptor;
  }
> = {
  [ApplicationSource.TALENT_SEARCH_WEB]: {
    fullChatModeText: {
      id: 'text-found-talent-search-for',
      defaultMessage: 'Found via Talent Search for',
    },
    popUpModeText: {
      id: 'text-found-talent-search',
      defaultMessage: 'Found via Talent Search',
    },
  },
  [ApplicationSource.RECOMMENDED_TALENT]: {
    fullChatModeText: {
      id: 'text-found-via-recommended-talents.100',
      defaultMessage: 'Found via Recommended Talent for',
    },
    popUpModeText: {
      id: 'text-found-recommended-talent',
      defaultMessage: 'Found via Recommended Talent',
    },
  },
};

const MessagingChannelHeader = () => {
  useRebuildComponentOnChannelEvent('channel.updated');
  const { jumpToMessage } = useChannelActionContext();
  const { formatMessage } = useIntl();
  const { channel } = useChannelStateContext();
  const { setShowInputPhoneNumber } = useMessagingChannelContext();
  const [createShareResumeRequest, createShareResumeRequestResult] =
    useCreateShareResumeRequest();
  const [createShareContactsRequest, createShareContactsRequestResult] =
    useCreateShareContactsRequest();
  const isGroupChatEnabled = useIsGroupChatEnabled();
  const isPopupMode = useIsPopupMode();
  const [isMobileVersion, isMobileVersionForMenus] = [
    useIsMobileVersion(),
    useIsMobileVersion('ChannelHeaderMenus'),
  ];
  const history = useHistory();
  const [, setPopupChannelId] = useQueryParam(
    ChannelQueryParam.POPUP_CHANNEL_ID,
    StringParam
  );
  const phoneNumber = useSelector(getUserPhoneNumber) as string | undefined;
  const [showManageMembers, setShowManageMembers] = useState(false);
  const metadata = getChannelApplicationMetadata(channel);

  const { handleApplicationStatusChangeForChat } =
    useHandleApplicationStatusChangeForChat();

  if (!metadata) {
    return <ChannelHeader />;
  }

  const { application, exchanged_contacts, exchanged_cv } = metadata;

  const jobTitle = application.Job?.title;
  const applicationSourceText = get(
    applicationSourceToText,
    application.source as ApplicationSource
  );

  const applicant = channel.state.members[application.ApplicantId]?.user;
  const numberOfTeamMembers = Object.keys(channel.state.members).length - 1;

  const handleContactsExchangeBtnOnClick = () => {
    if (exchanged_contacts !== ApplicationContactsExchangeStatus.NONE) {
      if (metadata.contacts_request_msg_id) {
        jumpToMessage(metadata.contacts_request_msg_id);
      }
    } else if (!phoneNumber) {
      setShowInputPhoneNumber(true);
    } else if (channel.id) {
      createShareContactsRequest({
        variables: {
          channelId: channel.id,
        },
      });
    }
  };

  const handleCVExchangeBtnOnClick = () => {
    if (exchanged_cv !== ApplicationCVExchangeStatus.NONE) {
      if (metadata.cv_request_msg_id) {
        jumpToMessage(metadata.cv_request_msg_id);
      }
    } else if (channel.id) {
      createShareResumeRequest({
        variables: {
          channelId: channel.id,
        },
      });
    }
  };

  const handleOpenChat = () => {
    const queryParams = new URLSearchParams({
      [ChannelQueryParam.CHANNEL_ID]: channel.id ?? '',
      [ChannelQueryParam.JOB_ID]: application.JobId ?? '',
      [ChannelQueryParam.APP_STATUS]: application.status,
    });

    trackChatPageViewedEvent({
      source: 'chat-widget',
      uiType: MessagingUIMode.FULL,
    });

    window.open(`/messages?${queryParams.toString()}`, '_blank');
  };

  const handleCloseChatPopup = () => {
    setPopupChannelId(undefined, 'replaceIn');
  };

  const menus = (
    <ChannelHeaderMenus
      exchangeCV={{
        status: exchanged_cv,
        loading: createShareResumeRequestResult.loading,
        onClick: handleCVExchangeBtnOnClick,
      }}
      exchangeContacts={{
        status: exchanged_contacts,
        loading: createShareContactsRequestResult.loading,
        onClick: handleContactsExchangeBtnOnClick,
      }}
    />
  );

  return (
    <Card
      style={{
        display: 'flex',
        borderBottom: `1px solid ${Greyscale.lightgrey}`,
        borderRadius: 0,
        gap: space8,
        zIndex: 1,
      }}
    >
      <Choose>
        <When condition={isPopupMode}>
          <Flex flexDirection="column" style={{ gap: space12, width: '100%' }}>
            <Flex justifyContent="space-between" style={{ gap: space4 }}>
              <Styled.PopupHeader>
                <ClippedTypography variant="body2">
                  {applicant?.name}
                </ClippedTypography>

                <If condition={applicationSourceText}>
                  {applicationSourceText && (
                    <Tooltip
                      content={formatMessage(
                        applicationSourceText.popUpModeText
                      )}
                      preferredPosition="bottom-center"
                      zIndex={101}
                    >
                      <Icon
                        name="ri-mail-send-fill"
                        width={18}
                        fill={Green.B52}
                      />
                    </Tooltip>
                  )}
                </If>

                <LastSeenAt applicationId={application.id} />
              </Styled.PopupHeader>
              <Flex alignItems="center" style={{ gap: space4 }}>
                <Tooltip
                  content={
                    <Typography
                      variant="subtitle2"
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <FormattedMessage
                        id="text-go-full-chat"
                        defaultMessage="Go to full chat"
                      />
                    </Typography>
                  }
                  zIndex={101}
                >
                  <Styled.IconButton
                    name="ri-external-link-line"
                    onClick={handleOpenChat}
                  />
                </Tooltip>
                <Tooltip
                  content={
                    <Typography
                      variant="subtitle2"
                      color={Neutral.B100}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      <FormattedMessage
                        id="text-press-esc-close"
                        defaultMessage="Press ESC to close"
                      />
                    </Typography>
                  }
                  style={{ whiteSpace: 'nowrap' }}
                  zIndex={999}
                >
                  <Styled.IconButton
                    name="ri-close"
                    onClick={handleCloseChatPopup}
                  />
                </Tooltip>
              </Flex>
            </Flex>
            {menus}
          </Flex>
        </When>
        <Otherwise>
          <If condition={isMobileVersion}>
            <Styled.IconButton
              name="ri-arrow-left-line"
              onClick={() => history.goBack()}
              style={{ paddingTop: '6px' }}
            />
          </If>
          <Styled.ResponsiveContainer>
            <Flex
              justifyContent="space-between"
              style={{ width: '100%', gap: space8 }}
            >
              <Flex data-cy="applicant-info-header-container">
                <ChatTabUserAvatar
                  image={applicant?.image}
                  name={applicant?.name}
                  applicationId={application.id}
                />

                <Flex flexDirection="column" style={{ gap: space8 }}>
                  <Flex style={{ gap: space8 }} alignItems="center">
                    <ClippedTypography variant="body2">
                      {applicant?.name}
                    </ClippedTypography>
                    <LastSeenAt applicationId={application.id} />
                  </Flex>
                  {jobTitle && (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      style={{ gap: space4 }}
                    >
                      <If condition={!isMobileVersion}>
                        <Choose>
                          <When condition={applicationSourceText}>
                            {applicationSourceText && (
                              <Typography variant="subtitle2">
                                <FormattedMessage
                                  {...applicationSourceText.fullChatModeText}
                                />
                              </Typography>
                            )}
                          </When>
                          <Otherwise>
                            <Typography variant="subtitle2">
                              <FormattedMessage
                                id="text_applied-for-label"
                                defaultMessage="Applied for"
                              />
                            </Typography>
                          </Otherwise>
                        </Choose>
                      </If>
                      <Link to={`/manage-candidates?jid=${application.JobId}`}>
                        <MessagingApplicationJobTitle
                          title={jobTitle}
                          style={{
                            cursor: 'pointer',
                            color: Neutral.B18,
                          }}
                        />
                      </Link>
                    </Flex>
                  )}
                  <If condition={!isMobileVersionForMenus}>{menus}</If>
                </Flex>
              </Flex>
              <Flex
                flexDirection="column"
                justifyContent={
                  isGroupChatEnabled ? 'space-between' : 'flex-end'
                }
                alignItems="flex-end"
                style={{ gap: space8 }}
              >
                <If condition={isGroupChatEnabled}>
                  <Button
                    icon={<Icon name="ri-group-line" />}
                    iconPosition="left"
                    onClick={() => setShowManageMembers(true)}
                  >
                    <If condition={!isMobileVersion}>
                      <Typography variant="button">
                        <FormattedMessage
                          id="interactive-member"
                          defaultMessage="Member"
                        />
                      </Typography>
                    </If>
                    <If condition={numberOfTeamMembers > 1}>
                      <Badge>{numberOfTeamMembers}</Badge>
                    </If>
                  </Button>
                </If>
                <If
                  condition={application.status !== ApplicationStatus.REJECTED}
                >
                  <RejectApplicationButton
                    jobId={application.JobId || ''}
                    selectedApplications={[application]}
                    icon={<Icon name="ri-close" />}
                    preferredAlignment="right"
                    preferredPosition="below"
                    eagerUpdateChannel={true}
                    onRejectSuccess={handleApplicationStatusChangeForChat}
                  >
                    <Typography variant="button" color={Neutral.B18}>
                      <FormattedMessage
                        id="interactive-reject"
                        defaultMessage="Reject"
                      />
                    </Typography>
                  </RejectApplicationButton>
                </If>
              </Flex>
            </Flex>
            <If condition={isMobileVersionForMenus}>{menus}</If>
          </Styled.ResponsiveContainer>
          <If condition={showManageMembers}>
            <ChannelMembersModal onClose={() => setShowManageMembers(false)} />
          </If>
        </Otherwise>
      </Choose>
    </Card>
  );
};

export default MessagingChannelHeader;
