import React from 'react';
import { FormattedMessage } from 'react-intl';

import { COUNTRY_DOMAINS } from '../../../../common/constants';
import config from '../../../../config';
import {
  CompanyLinks,
  LinksSection,
  LinksSectionWrapper,
  Title,
} from '../../FooterGlints.sc';
import { links } from './links';
import { renderLinks } from './utils';

const getLinkData = () => {
  const country = config.COUNTRY;
  switch (country) {
    case COUNTRY_DOMAINS.sg:
      return links.sg;
    default:
      return links.default;
  }
};

export default function DefaultLinksSection() {
  return (
    <LinksSectionWrapper>
      {getLinkData().map(({ title, links }) => (
        <LinksSection key={title.id}>
          <FormattedMessage tagName={Title} {...title} />
          <CompanyLinks>{renderLinks(links)}</CompanyLinks>
        </LinksSection>
      ))}
    </LinksSectionWrapper>
  );
}
