import { createAction } from 'redux-actions';

import { setScopedTokenActionCompleted } from '../../../actions/session';
import CALL_API from '../../../middleware/api_key';
import { Schemas } from '../../../middleware/schemas';
import {
  RECOMMENDATION_RESPONSE_MEDIUM,
  RECOMMENDATION_STATUSES,
} from './Constants';

export const Actions = {
  RECOMMENDATION_REQUEST:
    'glints/candidatePage/recommendation/RECOMMENDATION_REQUEST',
  RECOMMENDATION_SUCCESS:
    'glints/candidatePage/recommendation/RECOMMENDATION_SUCCESS',
  RECOMMENDATION_FAILURE:
    'glints/candidatePage/recommendation/RECOMMENDATION_FAILURE',

  APPROVE_RECOMMENDATION_REQUEST:
    'glints/candidatePage/recommendation/APPROVE_RECOMMENDATION_REQUEST',
  APPROVE_RECOMMENDATION_SUCCESS:
    'glints/candidatePage/recommendation/APPROVE_RECOMMENDATION_SUCCESS',
  APPROVE_RECOMMENDATION_FAILURE:
    'glints/candidatePage/recommendation/APPROVE_RECOMMENDATION_FAILURE',

  REJECT_RECOMMENDATION_REQUEST:
    'glints/candidatePage/recommendation/REJECT_RECOMMENDATION_REQUEST',
  REJECT_RECOMMENDATION_SUCCESS:
    'glints/candidatePage/recommendation/REJECT_RECOMMENDATION_SUCCESS',
  REJECT_RECOMMENDATION_FAILURE:
    'glints/candidatePage/recommendation/REJECT_RECOMMENDATION_FAILURE',

  RESET_STATE: 'glints/candidatePage/recommendation/RESET_STATE',
};

export const resetState = createAction(Actions.RESET_STATE);

export function fetchRecommendation(recommendationId) {
  return {
    [CALL_API]: {
      types: [
        Actions.RECOMMENDATION_REQUEST,
        Actions.RECOMMENDATION_SUCCESS,
        Actions.RECOMMENDATION_FAILURE,
      ],
      endpoint: `recommendations/${recommendationId}`,
      schema: Schemas.RECOMMENDATION,
    },
  };
}

export function approveRecommendation(recommendationId, responseMedium) {
  return async dispatch => {
    try {
      await dispatch({
        [CALL_API]: {
          types: [
            Actions.APPROVE_RECOMMENDATION_REQUEST,
            Actions.APPROVE_RECOMMENDATION_SUCCESS,
            Actions.APPROVE_RECOMMENDATION_FAILURE,
          ],
          endpoint: `recommendations/${recommendationId}`,
          schema: Schemas.RECOMMENDATION,
          method: 'put',
          options: {
            data: {
              status: RECOMMENDATION_STATUSES.APPROVED,
              responseMedium: responseMedium,
            },
          },
        },
      });
      if (responseMedium === RECOMMENDATION_RESPONSE_MEDIUM.EMAIL) {
        dispatch(setScopedTokenActionCompleted());
      }
    } catch (err) {
      if (responseMedium === RECOMMENDATION_RESPONSE_MEDIUM.EMAIL) {
        dispatch(setScopedTokenActionCompleted());
      }
    }
  };
}

export function rejectRecommendation(recommendationId, responseMedium) {
  return async dispatch => {
    try {
      await dispatch({
        [CALL_API]: {
          types: [
            Actions.REJECT_RECOMMENDATION_REQUEST,
            Actions.REJECT_RECOMMENDATION_SUCCESS,
            Actions.REJECT_RECOMMENDATION_FAILURE,
          ],
          endpoint: `recommendations/${recommendationId}`,
          schema: Schemas.RECOMMENDATION,
          method: 'put',
          options: {
            data: {
              status: RECOMMENDATION_STATUSES.REJECTED,
              responseMedium: responseMedium,
            },
          },
        },
      });
      if (responseMedium === RECOMMENDATION_RESPONSE_MEDIUM.EMAIL) {
        dispatch(setScopedTokenActionCompleted());
      }
    } catch (err) {
      if (responseMedium === RECOMMENDATION_RESPONSE_MEDIUM.EMAIL) {
        dispatch(setScopedTokenActionCompleted());
      }
    }
  };
}
