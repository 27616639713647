import { createAction } from 'redux-actions';

import CALL_API from '../../../middleware/api_key';
import { Schemas } from '../../../middleware/schemas';
import { getTalentModalTalentId } from '../Selectors';
import { getBookmarkJob, getBookmarkNote } from './Selectors';

export const Actions = {
  UPDATE_COMPANY_FOR_BOOKMARK:
    'glints/talentModal/bookmark/UPDATE_COMPANY_FOR_BOOKMARK',
  UPDATE_JOB_FOR_BOOKMARK:
    'glints/talentModal/bookmark/UPDATE_JOB_FOR_BOOKMARK',

  RESET_BOOKMARK_STATE: 'glints/talentModal/bookmark/RESET_BOOKMARK_STATE',

  UPDATE_NOTE: 'glints/talentModal/bookmark/UPDATE_NOTE',

  CREATE_BOOKMARK_REQUEST:
    'glints/talentModal/bookmark/CREATE_BOOKMARK_REQUEST',
  CREATE_BOOKMARK_SUCCESS:
    'glints/talentModal/bookmark/CREATE_BOOKMARK_SUCCESS',
  CREATE_BOOKMARK_FAILURE:
    'glints/talentModal/bookmark/CREATE_BOOKMARK_FAILURE',
};

export const updateCompanyForBookmark = createAction(
  Actions.UPDATE_COMPANY_FOR_BOOKMARK
);

export const updateJobForBookmark = createAction(
  Actions.UPDATE_JOB_FOR_BOOKMARK
);

export const resetBookmarkState = createAction(Actions.RESET_BOOKMARK_STATE);

export const updateNote = createAction(Actions.UPDATE_NOTE);

export function createBookmark() {
  return async (dispatch, getState) => {
    await dispatch({
      [CALL_API]: {
        types: [
          Actions.CREATE_BOOKMARK_REQUEST,
          Actions.CREATE_BOOKMARK_SUCCESS,
          Actions.CREATE_BOOKMARK_FAILURE,
        ],
        endpoint: 'me/bookmarks',
        method: 'post',
        schema: Schemas.BOOKMARK,
        options: {
          data: {
            CandidateId: getTalentModalTalentId(getState()),
            ForJobId: getBookmarkJob(getState()).id,
            note:
              getBookmarkNote(getState()) === ''
                ? null
                : getBookmarkNote(getState()),
          },
        },
      },
    });
  };
}
