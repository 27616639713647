import * as Yup from 'yup';

import {
  hierarchicalLocationSupportedCountryCodes,
  USER_ROLES,
} from '../../common/constants';
import { countryCodeIsIndonesia } from '../../common/helpers/countryCodeHelper';
import { getFirstForbiddenKeywordByCountry } from '../../common/validationHelper';
import { FIELDS, VALIDATION_ERRORS } from './consts';

export const isHierarchicalLocationSupportedForCountryCode = (
  countryCode: string
) => hierarchicalLocationSupportedCountryCodes.includes(countryCode);

export const mapFromDataToRequestData = (
  {
    name,
    tagline,
    logo,
    size,
    website,
    address,
    legalRegistrationNumber,
    legalDocument,
    vnTaxCode,
    vnBusinessRegistrationCertDate,
    vnBusinessRegistrationCertPlace,
    contactMethod,
    industry,
    countryCode,
    cityId,
    locationCityId,
  }: typeof FIELDS,
  userId: string,
  userRole: (typeof USER_ROLES)[keyof typeof USER_ROLES]
) => ({
  name,
  logo,
  website,
  size,
  tagline,
  address: address.trim() === '' ? null : address,
  legalRegistrationNumber:
    legalRegistrationNumber === '' ? null : legalRegistrationNumber,
  legalDocument: legalDocument === '' ? null : legalDocument,
  CountryCode: countryCode,
  CityId: isHierarchicalLocationSupportedForCountryCode(countryCode)
    ? null
    : cityId,
  LocationId: isHierarchicalLocationSupportedForCountryCode(countryCode)
    ? locationCityId
    : null,
  customAttributes: {
    companyUser: [
      {
        UserId: userId,
        role: userRole,
      },
    ],
  },
  vnTaxCode: vnTaxCode === '' ? undefined : vnTaxCode,
  vnBusinessRegistrationCertDate:
    vnBusinessRegistrationCertDate === ''
      ? undefined
      : vnBusinessRegistrationCertDate,
  vnBusinessRegistrationCertPlace:
    vnBusinessRegistrationCertPlace === ''
      ? undefined
      : vnBusinessRegistrationCertPlace,
  contactMethod: contactMethod === '' ? undefined : contactMethod,
  IndustryId: parseInt(industry),
});

export const validationSchema = Yup.object()
  .shape({
    [FIELDS.name]: Yup.string().when(
      FIELDS.countryCode,
      (countryCode, schema) =>
        schema
          .max(60, VALIDATION_ERRORS.nameTooLong)
          .test({
            name: 'forbiddenKeywords',
            test: function (desc: any) {
              const firstForbiddenWord = getFirstForbiddenKeywordByCountry(
                desc,
                countryCode || '',
                false
              );
              return (
                (firstForbiddenWord &&
                  this.createError({
                    message: VALIDATION_ERRORS.nameInvalid,
                    path: FIELDS.name,
                  })) ||
                true
              );
            },
          })
          .required(VALIDATION_ERRORS.nameRequired)
    ),
    [FIELDS.logo]: Yup.string(),
    [FIELDS.website]: Yup.string()
      .optional()
      .matches(
        /^(((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?|)$/,
        VALIDATION_ERRORS.websiteInvalid
      ),
    [FIELDS.size]: Yup.string().required(VALIDATION_ERRORS.sizeRequired),
    [FIELDS.tagline]: Yup.string()
      .max(255, VALIDATION_ERRORS.taglineTooLong)
      .required(VALIDATION_ERRORS.taglineRequired),
    [FIELDS.address]: Yup.string().max(2048, VALIDATION_ERRORS.addressTooLong),

    [FIELDS.legalRegistrationNumber]: Yup.string()
      .matches(/^[0-9]+$/, VALIDATION_ERRORS.legalRegistrationNumberInvalid)
      .min(13, VALIDATION_ERRORS.legalRegistrationNumberInvalid)
      .max(13, VALIDATION_ERRORS.legalRegistrationNumberInvalid),
    [FIELDS.legalDocument]: Yup.string(),
    [FIELDS.industry]: Yup.string().required(
      VALIDATION_ERRORS.industryRequired
    ),
    [FIELDS.countryCode]: Yup.string().required(
      VALIDATION_ERRORS.countryCodeRequired
    ),
    [FIELDS.cityId]: Yup.string().when(FIELDS.countryCode, {
      is: isHierarchicalLocationSupportedForCountryCode,
      then: Yup.string().optional(),
      otherwise: Yup.string().required(VALIDATION_ERRORS.cityIdRequired),
    }),
    [FIELDS.locationProvinceId]: Yup.string().when(FIELDS.countryCode, {
      is: countryCodeIsIndonesia,
      then: Yup.string().required(VALIDATION_ERRORS.locationProvinceIdRequired),
      otherwise: Yup.string().optional(),
    }),
    [FIELDS.locationCityId]: Yup.string().when(FIELDS.countryCode, {
      is: isHierarchicalLocationSupportedForCountryCode,
      then: Yup.string().required(VALIDATION_ERRORS.locationCityIdRequired),
      otherwise: Yup.string().optional(),
    }),
  })
  .defined();
