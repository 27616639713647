export const supportedLabel = [
  'resume',
  'job-banner-pic',
  'company-logo',
  'company-banner-pic',
  'profile-picture',
  'company-photos',
  'group-logo',
  'group-banner-pic',
  'portfolio-image',
  'portfolio-application',
];
