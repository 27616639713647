import React, { Component } from 'react';
import { FastImage } from '@glints/fast-image';
import { omit } from 'lodash-es';
import PropTypes from 'prop-types';

import { getImageAssetURL } from './URLUtils';

const AssetTypePropType = PropTypes.oneOf([
  'career-banner-pic',
  'company-banner-pic',
  'company-logo',
  'company-photos',
  'content',
  'default-banner-pic-large',
  'default-banner-pic-small',
  'group-logo',
  'job-banner-pic',
  'job-category-banner-pic',
  'job-default-banner-pic',
  'perk-pic',
  'profession-banner-pic',
  'profile-picture',
  'profile-picture-default',
  'resource-banner-pic',
  'talent-radar/linkedin/profile-picture',
]);

class GlintsPicture extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    assetType: AssetTypePropType,
    fallback: PropTypes.string,
    fallbackAssetType: AssetTypePropType,
    sizes: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    lazy: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.state = {
      isError: false,
      fallbackIsError: false,
      measured: Boolean(props.sizes),
      measuredWidth: null,
    };
    this.measurerRef = React.createRef();
  }

  componentDidMount() {
    if (this.measurerRef.current) {
      const width = this.measurerRef.current?.parentCode?.clientWidth;
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        measured: true,
        measuredWidth: `${width}px`,
      });
    }
  }

  componentDidCatch(error, errorInfo) {
    // Provide error boundary here to continue to allow page to render.
    console.warn(
      'Error occurred while rendering ThumborImage: ',
      error,
      errorInfo
    );
    return null;
  }

  handleOnError() {
    if (!this.state.isError) {
      this.setState({ isError: true });
    } else if (!this.state.fallbackIsError) {
      // Fallback can also encounter errors.

      this.setState({ fallbackIsError: true });
    }
  }

  render() {
    if (!this.state.measured) {
      return <div ref={this.measurerRef} />;
    }

    const thumborProps = omit(this.props, Object.keys(GlintsPicture.propTypes));

    // Handle error conditions, like 404 on image load.
    let assetName = null;
    let assetType = null;
    if (this.state.isError) {
      if (this.state.fallbackIsError) {
        // Last ditch attempt to load also failed, don't render this image.
        console.warn(
          'Failed to load image asset and fallback',
          this.props.assetType,
          this.props.name,
          this.props.fallback
        );
        return null;
      } else if (this.props.fallback) {
        // Fallback specified, try the fallback.
        assetName = this.props.fallback;
        assetType = this.props.fallbackAssetType
          ? this.props.fallbackAssetType
          : this.props.assetType;
      } else {
        // No fallback specified, bail.
        console.warn(
          'Failed to load image asset and no fallback specified',
          this.props.assetType,
          this.props.name
        );
        return null;
      }
    } else {
      assetName = this.props.name;
      assetType = this.props.assetType;
    }

    return (
      <FastImage
        onError={this.handleOnError.bind(this)}
        src={getImageAssetURL(assetName, assetType)}
        thumborOptions={thumborProps}
        lazy={this.props.lazy}
        sizes={this.props.sizes ? this.props.sizes : this.state.measuredWidth}
        className={this.props.className}
        style={this.props.style}
      />
    );
  }
}

export default GlintsPicture;
