import { useCallback, useEffect, useState } from 'react';
import { apm } from '@elastic/apm-rum';
import { isNil } from 'lodash-es';
import { nanoid } from 'nanoid';
import {
  Channel,
  ChannelFilters,
  ChannelSort,
  Logger,
  MemberSort,
  StreamChat as StreamChat_,
  UserFilters,
} from 'stream-chat';

import config from '../config';
import { GlintsCustomSystemMessage } from '../modules/Messaging/types';

const enum StreamGlintsChannelTypes {
  EMPLOYER_CANDIDATE = 'employer-candidate',
}

let StreamChat: any = {};

export const PipelineStatuses = {
  PENDING: 'PENDING',
  SHORTLISTED: 'SHORTLISTED',
  ASSESSMENT: 'ASSESSMENT',
  INTERVIEWING: 'INTERVIEWING',
  OFFERED: 'OFFERED',
  HIRED: 'HIRED',
  REJECTED: 'REJECTED',
};

export type CandidateDetails = {
  candidateFirstName: string;
  candidateLastName: string;
  candidateEmail: string;
  candidateName: string;
  candidateId: string;
  applicationStatus: keyof typeof PipelineStatuses;
  candidateProfilePic?: string;
};

export type JobDetails = {
  role: string;
  companyName: string;
  companyId: string;
  jobId: string;
};

const loadStreamChat = () =>
  import(
    /* webpackExports: "StreamChat" */
    /* webpackChunkName: "stream-chat-class" */ 'stream-chat'
  ).then(mod => mod.StreamChat);

export const makeCreateStreamChannelPayload = ({
  candidateDetails,
  jobDetails,
}: {
  candidateDetails: CandidateDetails;
  jobDetails: JobDetails;
}): CreateStreamChannelPayload => {
  const { candidateEmail, candidateFirstName, candidateLastName } =
    candidateDetails;
  const {
    companyName: glintsCompanyName,
    companyId: glintsCompanyId,
    jobId: glintsJobId,
  } = jobDetails;

  return {
    name: getStreamUserName({
      email: candidateEmail,
      firstName: candidateFirstName,
      lastName: candidateLastName,
    }),
    glintsChannelType: StreamGlintsChannelTypes.EMPLOYER_CANDIDATE,
    glintsCompanyName,
    glintsCompanyId,
    glintsJobId,
  };
};

export interface CreateStreamChannelPayload {
  name: string;
  glintsChannelType: StreamGlintsChannelTypes.EMPLOYER_CANDIDATE;
  glintsCompanyName: string;
  glintsCompanyId: string;
  glintsJobId: string;
}
interface StreamUser {
  id: string;
  name: string;
  glintsRole: string;
  image: string;
}

const FILTER_GET_ALL = {};

const DESCENDING_ON_CREATED_AT: MemberSort = [{ created_at: -1 }];

export const enum DefaultStreamChannelTypes {
  MESSAGING = 'messaging',
  LIVESTREAM = 'livestream',
  GAMING = 'gaming',
  TEAM = 'team',
  COMMERCE = 'commerce',
}

const enum StreamChannelUserRoles {
  MEMBER = 'channel_member',
  MODERATOR = 'channel_moderator',
}

const setStreamChat = async () => {
  const streamChatClass = await loadStreamChat();
  StreamChat = streamChatClass;
};

class StreamChatLoggingErrors extends Error {
  override name: string = this.constructor.name;

  constructor(level: string, message: string) {
    super();
    this.message = `StreamChatClientError: Level ${level} - ${message}`;
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

export const getStreamInstance = async () => {
  if (Object.keys(StreamChat).length === 0) {
    try {
      await setStreamChat();
    } catch (error) {
      apm.captureError(
        'StreamChatClientError: Error Importing Stream Chat Library'
      );
      throw error;
    }
  }

  const instance = StreamChat.getInstance(config.MESSAGING_SERVICE_API_KEY, {
    timeout: 12000,
  });

  const chatLogger: Logger = (level, message) => {
    if (level === 'error' || level === 'warn') {
      apm.captureError(new StreamChatLoggingErrors(level, message));
    }
  };
  instance.logger = chatLogger;
  return instance;
};

export const connectStreamUser = async (
  streamUserData: StreamUser,
  token: string
) => {
  const client = await getStreamInstance();
  await client.connectUser({ ...streamUserData }, token);
};

export const disconnectStreamUser = async () => {
  const client = await getStreamInstance();
  await client.disconnectUser();
};

export const createStreamChannel = async ({
  createStreamChannelPayload,
  candidateId,
  employerId,
  text,
}: {
  createStreamChannelPayload: Partial<CreateStreamChannelPayload>;
  candidateId: string;
  employerId: string;
  text: string;
}) => {
  const client = await getStreamInstance();
  const channel = client.channel(
    DefaultStreamChannelTypes.MESSAGING,
    nanoid(),
    createStreamChannelPayload
  );

  await channel.create();
  await channel.addMembers([
    { user_id: candidateId, channel_role: StreamChannelUserRoles.MEMBER },
    { user_id: employerId, channel_role: StreamChannelUserRoles.MODERATOR },
  ]);
  await channel.sendMessage({ text });

  return;
};

export const getStreamUserName = ({
  email,
  firstName,
  lastName,
}: {
  email: string;
  firstName?: null | string;
  lastName?: null | string;
}) => {
  if (!firstName && !lastName) {
    const [emailName] = email.split('@');
    if (emailName) return emailName.trim();
  }
  if (!firstName && lastName) return lastName.trim();
  if (!lastName && firstName) return firstName.trim();
  return `${firstName} ${lastName}`.trim();
};

export const queryChannels = async (
  filter: ChannelFilters,
  sort?: ChannelSort
) => {
  const client = await getStreamInstance();

  return client.queryChannels(filter, sort);
};

export const queryChannelByCompanyJobAndCandidate = async (
  companyId: string,
  jobId: string,
  candidateId: string
) => {
  const filter = {
    type: DefaultStreamChannelTypes.MESSAGING,
    glintsChannelType: StreamGlintsChannelTypes.EMPLOYER_CANDIDATE,
    glintsCompanyId: companyId,
    glintsJobId: jobId,
    members: { $in: [candidateId] },
  };

  const channels = await queryChannels(filter);

  const channel = channels[0];
  if (!channel) return [channel, false];

  const hasSentFirstMessage = Boolean(channel.state.messages[0]);
  return [channel, hasSentFirstMessage];
};

export const queryMembers = async (
  channel: Channel,
  filter: UserFilters = FILTER_GET_ALL,
  sort: MemberSort = DESCENDING_ON_CREATED_AT
) => await channel.queryMembers(filter, sort);

export const addMember = async (
  memberId: string,
  activeChannel: Channel,
  text: string,
  options?: GlintsCustomSystemMessage
) => {
  await activeChannel.addMembers([memberId], {
    text,
    ...options,
  });
};

export const removeMember = async (
  memberId: string,
  activeChannel: Channel,
  text: string,
  options?: GlintsCustomSystemMessage
) => {
  await activeChannel.removeMembers([memberId], {
    text,
    ...options,
  });
};

export const useStreamClient = () => {
  const [client, setClient] = useState<StreamChat_ | undefined>();

  const initClient = useCallback(async () => {
    const instance = await getStreamInstance();
    setClient(instance);
  }, []);

  useEffect(() => {
    initClient();
  }, [initClient]);

  return client;
};

export const useStreamCurrentUserTotalUnreadCount = () => {
  const client = useStreamClient();
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const initialTotalUnreadCount = client?.user?.total_unread_count;

  useEffect(() => {
    if (typeof initialTotalUnreadCount === 'number') {
      setTotalUnreadCount(initialTotalUnreadCount);
    }
  }, [initialTotalUnreadCount]);

  useEffect(
    () =>
      client?.on(event => {
        if (!isNil(event.total_unread_count)) {
          setTotalUnreadCount(event.total_unread_count);
        }
      }).unsubscribe,
    [client]
  );

  return totalUnreadCount;
};
