import React from 'react';
import { PlainButton, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';
import { useMount } from 'react-use';
import styled from 'styled-components';

import { useGetDivElementHeight } from '../../../common/hooks/useGetDivElementHeight';
import { useGlintsVIPPurchaseModal } from '../../../modules/CreditSystem/PurchaseModals/GlintsVIPPurchaseModal/hooks/useGlintsVIPPurchaseModal';
import { StickyContainer, StyledBanner } from '../styles.sc';
import {
  trackVIPExpiringNavbarBannerCTAClicked,
  trackVIPExpiringNavbarBannerImpression,
} from './tracking';

const StyledButton = styled(PlainButton)`
  display: inline;
  padding: 0;
`;

type Props = {
  setFixedBannerHeight: (height: number) => void;
  closeBanner: () => void;
  expiryInDays: number;
};

export const VIPMembershipExpiringBanner = ({
  setFixedBannerHeight,
  closeBanner,
  expiryInDays,
}: Props) => {
  const { ref } = useGetDivElementHeight({
    setElementHeight: setFixedBannerHeight,
  });

  const { openGlintsVIPPurchaseModal, isModalChunkLoadPending } =
    useGlintsVIPPurchaseModal();

  useMount(() => {
    trackVIPExpiringNavbarBannerImpression();
  });

  return (
    <StickyContainer ref={ref}>
      <StyledBanner
        type="fixed"
        status="warning"
        showIcon={false}
        onDismiss={() => {
          closeBanner();
          setFixedBannerHeight(0);
        }}
      >
        <Typography variant="body2" color={Neutral.B18} as="div">
          <FormattedMessage
            id="interactive-vip-membership-expire-in-days"
            defaultMessage="{count, plural, =0 {Your VIP Membership will expire today. <link>Extend your membership</link> to continue enjoying VIP benefits.} =1 {Your VIP Membership will expire tomorrow. <link>Extend your membership</link> to continue enjoying VIP benefits.} other {Your VIP Membership will expire in {count} days. <link>Extend your membership</link> to continue enjoying VIP benefits.}}"
            values={{
              link: (text: React.ReactNode) => (
                <StyledButton
                  onClick={() => {
                    trackVIPExpiringNavbarBannerCTAClicked();
                    return openGlintsVIPPurchaseModal();
                  }}
                  disabled={isModalChunkLoadPending}
                >
                  <Typography variant="body2">{text}</Typography>
                </StyledButton>
              ),
              count: expiryInDays,
            }}
          />
        </Typography>
      </StyledBanner>
    </StickyContainer>
  );
};
