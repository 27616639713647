import React, { useMemo } from 'react';
import { uniqueId } from 'lodash-es';
import { MessageList, useChannelStateContext } from 'stream-chat-react';

import {
  ApplicationChatEphemeralActionType,
  useMessagingChannelContext,
} from '../constants';
import { customGroupStyles } from '../helper';
import { useTrackMessageSentEvent } from '../hooks';
import MessagingChannelHeader from './ChannelHeader';
import MessagingInput from './MessageInput';
import MessageVariantController from './MessageVariantController';

const MessagingChannel = () => {
  useTrackMessageSentEvent();
  const { messages = [] } = useChannelStateContext();
  const { showInputPhoneNumber, ephemeralMessages } =
    useMessagingChannelContext();

  const filteredMessages = useMemo(
    () => messages.filter(message => !message?.glints_hide_for_employer),
    [messages]
  );

  const _messages = showInputPhoneNumber
    ? [
        ...filteredMessages,
        {
          id: uniqueId(ApplicationChatEphemeralActionType.INPUT_PHONE),
          glints_application_chat: {
            action_type: ApplicationChatEphemeralActionType.INPUT_PHONE,
          },
        },
      ]
    : filteredMessages;

  return (
    <>
      <MessagingChannelHeader />
      <MessageList
        groupStyles={customGroupStyles}
        messageActions={['edit', 'delete']}
        messages={_messages.concat(ephemeralMessages)}
        Message={MessageVariantController}
        returnAllReadData={true}
      />
      <MessagingInput />
    </>
  );
};

export default MessagingChannel;
