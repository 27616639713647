import { getSessionCompanyCountryCode } from './session';

export const getFeatures = state => state.entities.features;
export const getFeature = (state, featureName) =>
  state?.entities?.features?.[featureName];
export const getIsFeatureEnabled = (state, featureName) =>
  Boolean(state?.entities?.features?.[featureName]);
export const getFeatureVariant = (state, featureName) =>
  state?.entities?.features?.[featureName]?.variant?.value;
export const getIsFeatureEnabledInCountry = (state, featureName) =>
  getFeatureVariant(state, featureName)
    ?.split(',')
    ?.includes(getSessionCompanyCountryCode(state));
export const areFeaturesFetched = state => state?.features?.isFetched;
export const getAreFeaturesFetched = areFeaturesFetched;
