import { merge } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { Actions } from './Constants';

const initialState = {
  activityLog: {
    loading: false,
    success: false,
    error: null,
    value: [],
    selectedSort: 'createdAt DESC',
  },

  candidateStatus: {
    selected: null,
    loading: false,
    success: false,
    error: null,
  },

  activityLogNotes: {},

  ats: {
    companyId: null,
    jobId: null,
    note: null,
    loading: false,
    otherReason: '',
    rejectionReasons: [],
    success: false,
    error: null,
    talentHuntApplicationId: null,
  },

  talentHuntCompanies: {
    loading: false,
    success: false,
    error: null,
    value: [],
    searchTerm: '',
  },

  talentHuntJobs: {
    loading: false,
    success: false,
    error: null,
    value: [],
  },
};

export default handleActions(
  {
    [Actions.ACTIVITY_LOG_REQUEST]: state => ({
      ...state,
      activityLog: {
        ...state.activityLog,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.ACTIVITY_LOG_SUCCESS]: (state, action) => ({
      ...state,
      activityLog: {
        ...state.activityLog,
        loading: false,
        success: true,
        error: null,
        value: [
          ...new Set(state.activityLog.value.concat(action.response.result)),
        ],
      },
      activityLogNotes: merge(
        state.activityLogNotes,
        action.response.result.reduce((result, item) => {
          result[item] = {
            editMode: false,
            isExpanded: true,
            save: {
              loading: false,
              success: false,
              error: null,
            },
            delete: {
              loading: false,
              success: false,
              error: null,
            },
          };
          return result;
        }, {})
      ),
    }),
    [Actions.ACTIVITY_LOG_FAILURE]: (state, action) => ({
      ...state,
      activityLog: {
        ...state.activityLog,
        loading: false,
        success: false,
        error: action.error,
        value: [],
      },
    }),
    [Actions.RESET_ACTIVITY_LOG_STATE]: state => ({
      ...state,
      activityLog: initialState.activityLog,
    }),
    [Actions.SELECT_ACTIVITY_LOG_SORT_OPTION]: (state, action) => ({
      ...state,
      activityLog: {
        ...state.activityLog,
        selectedSort: action.payload,
      },
    }),
    [Actions.SELECT_CANDIDATE_STATUS]: (state, action) => ({
      ...state,
      candidateStatus: {
        ...state.candidateStatus,
        selected: action.payload,
      },
    }),
    [Actions.UPDATE_CANDIDATE_STATUS_REQUEST]: state => ({
      ...state,
      candidateStatus: {
        ...state.candidateStatus,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.UPDATE_CANDIDATE_STATUS_SUCCESS]: state => ({
      ...state,
      candidateStatus: {
        ...state.candidateStatus,
        loading: false,
        success: true,
        error: null,
        selected: null,
      },
      activityLog: {
        ...state.activityLog,
        value: [],
      },
    }),
    [Actions.UPDATE_CANDIDATE_STATUS_FAILURE]: (state, action) => ({
      ...state,
      candidateStatus: {
        ...state.candidateStatus,
        loading: false,
        success: false,
        error: action.payload.response,
      },
    }),
    [Actions.TOGGLE_ACTIVITY_LOG_NOTE]: (state, action) => ({
      ...state,
      activityLogNotes: {
        ...state.activityLogNotes,
        [action.payload]: {
          ...state.activityLogNotes[action.payload],
          isExpanded: !state.activityLogNotes[action.payload].isExpanded,
        },
      },
    }),
    [Actions.SET_NOTE_EDIT_MODE]: (state, action) => ({
      ...state,
      activityLogNotes: {
        ...state.activityLogNotes,
        [action.payload.id]: {
          ...state.activityLogNotes[action.payload.id],
          editMode: action.payload.editMode,
        },
      },
    }),
    [Actions.SAVE_NOTE_REQUEST]: (state, action) => ({
      ...state,
      activityLogNotes: {
        ...state.activityLogNotes,
        [action.payload]: {
          ...state.activityLogNotes[action.payload],
          save: {
            loading: true,
            success: false,
            error: null,
          },
        },
      },
    }),
    [Actions.SAVE_NOTE_SUCCESS]: (state, action) => ({
      ...state,
      activityLogNotes: {
        ...state.activityLogNotes,
        [action.payload]: {
          ...state.activityLogNotes[action.payload],
          editMode: false,
          save: {
            loading: false,
            success: true,
            error: null,
          },
        },
      },
    }),
    [Actions.SAVE_NOTE_FAILURE]: (state, action) => ({
      ...state,
      activityLogNotes: {
        ...state.activityLogNotes,
        [action.payload]: {
          ...state.activityLogNotes[action.payload],
          save: {
            loading: false,
            success: false,
            error: action.payload.response,
          },
        },
      },
    }),
    [Actions.DELETE_NOTE_REQUEST]: (state, action) => ({
      ...state,
      activityLogNotes: {
        ...state.activityLogNotes,
        [action.payload]: {
          ...state.activityLogNotes[action.payload],
          delete: {
            loading: true,
            success: false,
            error: null,
          },
        },
      },
    }),
    [Actions.DELETE_NOTE_SUCCESS]: (state, action) => ({
      ...state,
      activityLogNotes: {
        ...state.activityLogNotes,
        [action.payload]: {
          ...state.activityLogNotes[action.payload],
          editMode: false,
          isExpanded: true,
          delete: {
            loading: false,
            success: true,
            error: null,
          },
        },
      },
    }),
    [Actions.REMOVE_ACTIVITY_LOG_ID]: (state, action) => ({
      ...state,
      activityLog: {
        ...state.activityLog,
        value: state.activityLog.value.filter(x => x !== action.payload),
      },
    }),
    [Actions.DELETE_NOTE_FAILURE]: (state, action) => ({
      ...state,
      activityLogNotes: {
        ...state.activityLogNotes,
        [action.payload]: {
          ...state.activityLogNotes[action.payload],
          delete: {
            loading: false,
            success: false,
            error: action.payload.response,
          },
        },
      },
    }),
    [Actions.TALENTHUNT_COMPANIES_REQUEST]: state => ({
      ...state,
      talentHuntCompanies: {
        ...initialState.talentHuntCompanies,
        loading: true,
        searchTerm: state.talentHuntCompanies.searchTerm,
      },
    }),
    [Actions.TALENTHUNT_COMPANIES_SUCCESS]: (state, action) => ({
      ...state,
      talentHuntCompanies: {
        ...initialState.talentHuntCompanies,
        success: true,
        value: action.response.result,
        searchTerm: state.talentHuntCompanies.searchTerm,
      },
    }),
    [Actions.TALENTHUNT_COMPANIES_FAILURE]: (state, action) => ({
      ...state,
      talentHuntCompanies: {
        ...initialState.talentHuntCompanies,
        error: action.error.response,
      },
    }),
    [Actions.TALENTHUNT_JOBS_REQUEST]: state => ({
      ...state,
      talentHuntJobs: {
        ...initialState.talentHuntJobs,
        loading: true,
      },
    }),
    [Actions.TALENTHUNT_JOBS_SUCCESS]: (state, action) => ({
      ...state,
      talentHuntJobs: {
        ...initialState.talentHuntJobs,
        success: true,
        value: action.response.result,
      },
    }),
    [Actions.TALENTHUNT_JOBS_FAILURE]: (state, action) => ({
      ...state,
      talentHuntJobs: {
        ...initialState.talentHuntJobs,
        error: action.error.response,
      },
    }),
    [Actions.UPDATE_ATS_COMPANY_ID]: (state, action) => ({
      ...state,
      ats: {
        ...state.ats,
        companyId: action.payload,
        jobId: null,
      },
      talentHuntJobs: initialState.talentHuntJobs,
    }),
    [Actions.UPDATE_ATS_JOB_ID]: (state, action) => ({
      ...state,
      ats: {
        ...state.ats,
        jobId: action.payload,
      },
    }),
    [Actions.UPDATE_ATS_RECRUITER_ID]: (state, action) => ({
      ...state,
      ats: {
        ...state.ats,
        recruiterId: action.payload,
      },
    }),
    [Actions.UPDATE_ATS_NOTE]: (state, action) => ({
      ...state,
      ats: {
        ...state.ats,
        note: action.payload,
      },
    }),
    [Actions.UPDATE_ATS_REJECTION_REASONS]: (state, action) => ({
      ...state,
      ats: {
        ...state.ats,
        rejectionReasons: action.payload,
      },
    }),
    [Actions.UPDATE_ATS_OTHER_REASON]: (state, action) => ({
      ...state,
      ats: {
        ...state.ats,
        otherReason: action.payload,
      },
    }),
    [Actions.UPDATE_COMPANY_SEARCH_TERM]: (state, action) => ({
      ...state,
      talentHuntCompanies: {
        ...state.talentHuntCompanies,
        searchTerm: action.payload,
      },
    }),
    [Actions.PERSIST_ATS_ACTION_REQUEST]: state => ({
      ...state,
      ats: {
        ...state.ats,
        loading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.PERSIST_ATS_ACTION_SUCCESS]: state => ({
      ...state,
      ats: merge({}, initialState.ats, { success: true }),
    }),
    [Actions.PERSIST_ATS_ACTION_FAILURE]: (state, action) => ({
      ...state,
      ats: {
        ...state.ats,
        loading: false,
        success: false,
        error: action.payload.response,
      },
    }),
    [Actions.RESET_STATE]: () => initialState,
    [Actions.RESET_ATS_STATE]: state => ({
      ...state,
      ats: initialState.ats,
    }),
    [Actions.TALENTHUNT_APPLICATION_SUCCESS]: (state, action) => ({
      ...state,
      ats: {
        ...state.ats,
        talentHuntApplicationId: action.response.result[0] || null,
      },
    }),
  },
  initialState
);
