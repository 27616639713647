export const ACTIONS_FROM_EMAIL = {
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};

export const RECOMMENDATION_RESPONSE_MEDIUM = {
  PLATFORM: 'PLATFORM',
  EMAIL: 'EMAIL',
};

export const RECOMMENDATION_STATUSES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const ERRORS = {
  status409: {
    ExistingRejectionReasons:
      'The reasons for this rejection are already provided!',
    ErrorUpdatingStatus: `This recommendation has been updated previously and cannot be updated again.
Please reach out to our help desk for further assistance.`,
  },
};
