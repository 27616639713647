import CALL_API from '../middleware/api_key';
import { Schemas } from '../middleware/schemas';

export const Actions = {
  FETCH_FEATURES_REQUEST: 'glints/features/FETCH_FEATURES_REQUEST',
  FETCH_FEATURES_SUCCESS: 'glints/features/FETCH_FEATURES_SUCCESS',
  FETCH_FEATURES_FAILURE: 'glints/features/FETCH_FEATURES_FAILURE',
};

export function fetchFeatures() {
  return {
    type: 'fetchFeatures',
    [CALL_API]: {
      types: [
        Actions.FETCH_FEATURES_REQUEST,
        Actions.FETCH_FEATURES_SUCCESS,
        Actions.FETCH_FEATURES_FAILURE,
      ],
      endpoint: 'enabled-feature-flags',
      schema: Schemas.FEATURE_ARRAY,
    },
  };
}
