import axios from 'axios';

import { getConfig } from '../config';
import { handleFraudCompanyError } from './handleFraudCompany';
import { createAcceptLanguageHeader } from './helpers/createAcceptLanguageHeader';

export default function (token: string | null, baseURL?: string) {
  const config = getConfig();
  const headers = {
    'accept-language': createAcceptLanguageHeader(config.LANG),
  };
  const axiosConfig = {
    baseURL: baseURL || config.API_BASE,
    headers: {
      ...headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };

  const instance = axios.create(axiosConfig);

  // Axios doesn't wrap stuff as an Error object. In order for redux-actions
  // to recognize something as an error (and set action.error), the rejected
  // promise must have a payload which is an instance of Error.
  instance.interceptors.response.use(
    response => response,
    error => {
      handleFraudCompanyError(
        error.response?.status,
        error.response?.data?.error?.companyStatus
      );

      if (!(error instanceof Error)) {
        error = Object.assign(new Error('A HTTP request has failed'), error);
      }

      return Promise.reject(error);
    }
  );

  return instance;
}
