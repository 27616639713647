import { defineMessages } from 'react-intl';

export const TABS = {
  RECOMMENDED: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const TABS_LABEL = defineMessages({
  RECOMMENDED: {
    id: 'modules.Recommendations.Constants.recommended',
    defaultMessage: 'RECOMMENDED ({count})',
  },
  APPROVED: {
    id: 'modules.Recommendations.Constants.approved',
    defaultMessage: 'APPROVED ({count})',
  },
  REJECTED: {
    id: 'modules.Recommendations.Constants.rejected',
    defaultMessage: 'REJECTED ({count})',
  },
});

export const RECOMMENDATION_STATUSES = {
  PENDING: 'PENDING',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
};

export const RECOMMENDATIONS_PER_PAGE_LIMIT = 5;

export const ACTIONS_FROM_EMAIL = {
  OPEN_MODAL: 'OPEN_MODAL',
  APPROVE: 'APPROVE',
  REJECT: 'REJECT',
};

export const REJECTION_REASONS = [
  "Candidate's lack of experience",
  "Candidate's skills set",
  "Candidate's skill level",
  "Candidate's expected salary",
  'Found the same candidate from another source',
];

export const RECOMMENDATION_RESPONSE_MEDIUM = {
  PLATFORM: 'PLATFORM',
  EMAIL: 'EMAIL',
};

export const AI_RECOMMENDER = {
  logo: '/images/talenthunt-plus.svg',
};

export const RECOMMENDATIONS_FILTER = {
  ALL: {
    label: 'All Recommendations',
    params: {},
  },
  AI: {
    label: 'TalentHunt+ Recommendations',
    params: {
      where: {
        isMadeByAI: true,
      },
    },
  },
  RECRUITER: {
    label: "Recruiter's Recommendations",
    params: {
      where: {
        isMadeByAI: false,
      },
    },
  },
};

export const ERRORS = {
  status409: {
    ExistingRejectionReasons:
      'The reasons for this rejection are already provided!',
  },
};

export const Actions = {
  RECOMMENDATIONS_REQUEST: 'glints/recommendations/RECOMMENDATIONS_REQUEST',
  RECOMMENDATIONS_SUCCESS: 'glints/recommendations/RECOMMENDATIONS_SUCCESS',
  RECOMMENDATIONS_FAILURE: 'glints/recommendations/RECOMMENDATIONS_FAILURE',

  INCREMENT_PAGE_BY_ONE: 'glints/recommendations/INCREMENT_PAGE_BY_ONE',

  SET_ACTIVE_TAB: 'glints/recommendations/SET_ACTIVE_TAB',

  TALENTHUNT_JOBS_REQUEST: 'glints/recommendations/TALENTHUNT_JOBS_REQUEST',
  TALENTHUNT_JOBS_SUCCESS: 'glints/recommendations/TALENTHUNT_JOBS_SUCCESS',
  TALENTHUNT_JOBS_FAILURE: 'glints/recommendations/TALENTHUNT_JOBS_FAILURE',

  SELECT_JOB: 'glints/recommendations/SELECT_JOB',
  SET_SELECTED_JOB_ID: 'glints/recommendations/SET_SELECTED_JOB_ID',

  APPROVE_RECOMMENDATION_REQUEST:
    'glints/recommendations/APPROVE_RECOMMENDATION_REQUEST',
  APPROVE_RECOMMENDATION_SUCCESS:
    'glints/recommendations/APPROVE_RECOMMENDATION_SUCCESS',
  APPROVE_RECOMMENDATION_FAILURE:
    'glints/recommendations/APPROVE_RECOMMENDATION_FAILURE',

  REJECT_RECOMMENDATION_REQUEST:
    'glints/recommendations/REJECT_RECOMMENDATION_REQUEST',
  REJECT_RECOMMENDATION_SUCCESS:
    'glints/recommendations/REJECT_RECOMMENDATION_SUCCESS',
  REJECT_RECOMMENDATION_FAILURE:
    'glints/recommendations/REJECT_RECOMMENDATION_FAILURE',

  RESET_STATE: 'glints/recommendations/RESET_STATE',

  OPEN_PROFILE_MODAL: 'glints/recommendations/OPEN_PROFILE_MODAL',
  CLOSE_PROFILE_MODAL: 'glints/recommendations/CLOSE_PROFILE_MODAL',

  OPEN_REJECTION_MODAL: 'glints/recommendations/OPEN_REJECTION_MODAL',
  CLOSE_REJECTION_MODAL: 'glints/recommendations/CLOSE_REJECTION_MODAL',

  TOGGLE_REJECTION_TEXT_AREA:
    'glints/recommendations/TOGGLE_REJECTION_TEXT_AREA',
  UPDATE_OTHER_REJECTION_REASON:
    'glints/recommendations/UPDATE_OTHER_REJECTION_REASON',
  UPDATE_REJECTION_REASONS: 'glints/recommendations/UPDATE_REJECTION_REASONS',

  SUBMIT_REJECTION_REASON_REQUEST:
    'glints/recommendations/SUBMIT_REJECTION_REASON_REQUEST',
  SUBMIT_REJECTION_REASON_SUCCESS:
    'glints/recommendations/SUBMIT_REJECTION_REASON_SUCCESS',
  SUBMIT_REJECTION_REASON_FAILURE:
    'glints/recommendations/SUBMIT_REJECTION_REASON_FAILURE',

  SELECT_RECOMMENDATION_FILTER:
    'glints/recommendations/SELECT_RECOMMENDATION_FILTER',

  BOOKMARKS_REQUEST: 'glints/recommendations/BOOKMARKS_REQUEST',
  BOOKMARKS_SUCCESS: 'glints/recommendations/BOOKMARKS_SUCCESS',
  BOOKMARKS_FAILURE: 'glints/recommendations/BOOKMARKS_FAILURE',

  CREATE_BOOKMARK_REQUEST: 'glints/recommendations/CREATE_BOOKMARK_REQUEST',
  CREATE_BOOKMARK_SUCCESS: 'glints/recommendations/CREATE_BOOKMARK_SUCCESS',
  CREATE_BOOKMARK_FAILURE: 'glints/recommendations/CREATE_BOOKMARK_FAILURE',

  DELETE_BOOKMARK_REQUEST: 'glints/recommendations/DELETE_BOOKMARK_REQUEST',
  DELETE_BOOKMARK_SUCCESS: 'glints/recommendations/DELETE_BOOKMARK_SUCCESS',
  DELETE_BOOKMARK_FAILURE: 'glints/recommendations/DELETE_BOOKMARK_FAILURE',

  TALENT_GROUP_MEMBER_REQUEST:
    'glints/recommendations/TALENT_GROUP_MEMBER_REQUEST',
  TALENT_GROUP_MEMBER_SUCCESS:
    'glints/recommendations/TALENT_GROUP_MEMBER_SUCCESS',
  TALENT_GROUP_MEMBER_FAILURE:
    'glints/recommendations/TALENT_GROUP_MEMBER_FAILURE',

  RECOMMENDATIONS_BREAKDOWN_REQUEST:
    'glints/recommendations/RECOMMENDATIONS_BREAKDOWN_REQUEST',
  RECOMMENDATIONS_BREAKDOWN_SUCCESS:
    'glints/recommendations/RECOMMENDATIONS_BREAKDOWN_SUCCESS',
  RECOMMENDATIONS_BREAKDOWN_FAILURE:
    'glints/recommendations/RECOMMENDATIONS_BREAKDOWN_FAILURE',

  SET_IS_FROM_EMAIL: 'glints/recommendations/SET_IS_FROM_EMAIL',
  RESET_IS_FROM_EMAIL: 'glints/recommendations/RESET_IS_FROM_EMAIL',

  FETCH_AND_SHOW_PROFILE_FAILURE:
    'glints/recommendations/FETCH_AND_SHOW_PROFILE_FAILURE',
};
