import React, { ComponentType } from 'react';
import { ScreenSize } from 'glints-aries/es';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';

import { COUNTRY_DOMAINS } from '../../common/constants';
import { getConfig } from '../../config';
import { getIsFeatureEnabled } from '../../selectors/features';
import { getIsLoggedIn } from '../../selectors/session';
import { UnleashFeatureNames } from '../Unleash/featureNames';
import UseMobileAppPrompt from './UseMobileAppPrompt';

export function withUseMobileAppPrompt<P = Record<string, unknown>>(
  WrappedComponent: ComponentType<P>
) {
  const Wrapper = (props: P & JSX.IntrinsicAttributes) => {
    const isMobileView = useMediaQuery({ maxWidth: ScreenSize.tablet - 1 });
    const useMobileAppPromptEnabled = useSelector(state =>
      getIsFeatureEnabled(state, UnleashFeatureNames.empUseMobileAppPrompt)
    );
    const { COUNTRY } = getConfig();

    const isEnabledForCountry = [
      COUNTRY_DOMAINS.id,
      COUNTRY_DOMAINS.vn,
    ].includes(COUNTRY);

    const isLoggedIn = useSelector(getIsLoggedIn);

    if (
      isLoggedIn &&
      isEnabledForCountry &&
      useMobileAppPromptEnabled &&
      isMobileView
    ) {
      return <UseMobileAppPrompt />;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
}
