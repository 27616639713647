import { createAction } from 'redux-actions';

import axios from '../../common/axios-ts';
import config from '../../config';
import { supportedLabel } from './Constants';

export const Actions = {
  FILE_UPLOAD_REQUEST: Symbol('FILE_UPLOAD_REQUEST'),
  FILE_UPLOAD_SUCCESS: Symbol('FILE_UPLOAD_SUCCESS'),
  FILE_UPLOAD_FAIL: Symbol('FILE_UPLOAD_FAIL'),

  SAVE_FILE_TO_LOCAL: Symbol('SAVE_FILE_TO_LOCAL'),
  DELETE_FILE_IN_LOCAL: Symbol('DELETE_FILE_IN_LOCAL'),

  RESET_STATE: Symbol('RESET_STATE'),
};

export const fileUploadingRequest = createAction(Actions.FILE_UPLOAD_REQUEST);
export const fileUploadingSuccess = createAction(Actions.FILE_UPLOAD_SUCCESS);
export const fileUploadingError = createAction(Actions.FILE_UPLOAD_FAIL);

export const saveFileToLocal = createAction(Actions.SAVE_FILE_TO_LOCAL);
export const deleteFileInLocal = createAction(Actions.DELETE_FILE_IN_LOCAL);

export const resetState = createAction(Actions.RESET_STATE);

export function uploadFile(files) {
  return async dispatch => {
    dispatch(fileUploadingRequest());
    const attachments = [];
    if (files && files.length !== 0) {
      try {
        for (const file of files) {
          if (!file.label) {
            dispatch(fileUploadingError('Need to provide label in files'));
            console.warn('Need to provide label in files');
            return;
          }

          if (!supportedLabel.includes(file.label)) {
            dispatch(
              fileUploadingError(
                `File label is not in these list ${supportedLabel}`
              )
            );
            console.warn(`File label is not in these list ${supportedLabel}`);
            return;
          }

          const response = await axios(null, config.AWS_BASE).get(
            '/s3PrepareUpload',
            {
              params: {
                name: file.name,
                type: file.type,
                label: file.label,
              },
            }
          );

          await new Promise((resolve, reject) => {
            const xhr = response.request;
            xhr.open('put', response.data.request);
            xhr.setRequestHeader('Content-Type', file.type);

            xhr.onload = () => {
              attachments.push({
                fileName: file.name,
                filePathname: `${file.label}/${response.data.name}`,
              });
              resolve();
            };

            xhr.onerror = () => {
              reject();
            };

            const _send = xhr.send;
            xhr.send = () => _send.call(xhr, file);
            xhr.send();
          });
          await dispatch(fileUploadingSuccess(attachments));
        }
      } catch (e) {
        console.warn('e', e);
        console.warn('** An error occurred during the XMLHttpRequest');
        dispatch(
          fileUploadingError('** An error occurred during the XMLHttpRequest')
        );
      }
    } else {
      dispatch(fileUploadingError('Files is empty'));
    }
    return attachments;
  };
}
