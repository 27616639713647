import { useAlert } from 'glints-aries/es/@next';
import { isArray, omit } from 'lodash-es';
import { defineMessages, useIntl } from 'react-intl';
import { Cache, useSWRConfig } from 'swr';

import { RejectionReason } from '../../../components/Modal/ApplicationStatusTrackingModal/types';
import { ApplicationRejectionMessageType } from '../../../components/Modal/RejectionReasonPreferenceModal/types';
import { ApplicationStatus, applicationStatusMessages } from '../../constants';
import { ApplicantWhatsAppDetails } from '../../interfaces/entities';
import { ApplicationDetailsResponse } from '../../interfaces/responses';
import { SwrCacheKeys } from '../../swr-cache-keys';
import { useAxiosWithAPI } from '../useAxiosWithAPI';

const bulkChangeApplicationStatusUrl = (jobId: string) =>
  `jobs/${jobId}/applicationsBulk`;

export interface ChangeApplicationStatusPayload {
  status: ApplicationStatus;
  applicationId: string;
  // TODO: Remove RejectionReason type after RejectApplicant component is removed
  rejectionReasons?: (RejectionReason | ApplicationRejectionMessageType)[];
  otherReason?: string;
}

export interface ChangeApplicationStatusPayloadWithName
  extends ChangeApplicationStatusPayload {
  name: string;
}

export interface BulkChangeApplicationStatusResponse {
  failed: ChangeApplicationStatusPayload[];
  updates: Array<{
    ApplicantId: string;
    JobId: string;
    id: string;
    status: ApplicationStatus;
    whatsAppDetails: ApplicantWhatsAppDetails;
  }>;
}

interface UseBulkChangeApplicationStatusParams {
  jobId: string;
  onSuccess?: (res: BulkChangeApplicationStatusResponse) => void;
  onError?: (error: Error) => void;
  disableAlert?: boolean;
}

const alertMessages = defineMessages({
  success: {
    id: 'text-success-move-candidate',
    defaultMessage: 'Success',
  },
  error: {
    id: 'hooks.requests.useBulkChangeApplicationStatus.error',
    defaultMessage: 'Error',
  },
  successSingle: {
    id: 'text-name-moved-application-status',
    defaultMessage: '{fullName} has been moved to {applicationStatus}.',
  },
  successMultiple: {
    id: 'text-bulk-moved-application-status',
    defaultMessage:
      '{count} applicants have been moved to {applicationStatus}.',
  },
  generalError: {
    id: 'hooks.requests.useBulkChangeApplicationStatus.generalError',
    defaultMessage: 'Failed to update application status',
  },
});

const useBulkChangeApplicationStatus = ({
  jobId,
  onSuccess,
  onError,
  disableAlert,
}: UseBulkChangeApplicationStatusParams) => {
  const axios = useAxiosWithAPI();
  const intl = useIntl();

  const { open: showAlert } = useAlert();
  const { cache, mutate } = useSWRConfig();

  const updateApplicationsCache = (
    applications: BulkChangeApplicationStatusResponse
  ) => {
    if (!applications.updates.length) return;

    function refetchApplicationsListQuery() {
      mutate(
        key => isArray(key) && key[0] === SwrCacheKeys.jobApplications(jobId)
      );
    }

    refetchApplicationsListQuery();

    applications.updates.forEach(updatedApplication => {
      const key = SwrCacheKeys.applicationDetails(jobId, updatedApplication.id);
      const cachedResponse = cache.get(key) as ReturnType<
        Cache<ApplicationDetailsResponse>['get']
      >;

      if (!cachedResponse?.data) {
        mutate(key);
        return;
      }

      mutate<ApplicationDetailsResponse>(key, {
        ...cachedResponse.data,
        data: {
          ...cachedResponse.data.data,
          status: updatedApplication.status,
          whatsAppDetails: updatedApplication.whatsAppDetails,
        },
      });
    });
  };

  const bulkChangeApplicationStatus = async (
    data: Array<ChangeApplicationStatusPayloadWithName>,
    options?: Partial<{
      eagerUpdateChannel: boolean;
    }>
  ): Promise<null | BulkChangeApplicationStatusResponse> => {
    const dataWithoutName = data.map(application =>
      omit(application, ['name'])
    );

    const successAlert = (content: string) => {
      showAlert({
        title: intl.formatMessage(alertMessages.success),
        content,
        status: 'success',
        zIndex: 1000,
      });
    };

    const errorAlert = () => {
      showAlert({
        title: intl.formatMessage(alertMessages.error),
        content: intl.formatMessage(alertMessages.generalError),
        status: 'error',
        zIndex: 1000,
      });
    };

    try {
      const res = await axios.put<BulkChangeApplicationStatusResponse>(
        bulkChangeApplicationStatusUrl(jobId),
        {
          data: {
            updates: dataWithoutName,
            ...options,
          },
        }
      );

      if (!disableAlert && res.data.failed.length === 0) {
        if (data.length === 1) {
          successAlert(
            intl.formatMessage(alertMessages.successSingle, {
              fullName: data[0].name,
              applicationStatus: intl.formatMessage(
                applicationStatusMessages[data[0].status]
              ),
            })
          );
        } else {
          successAlert(
            intl.formatMessage(alertMessages.successMultiple, {
              count: data.length,
              applicationStatus: intl.formatMessage(
                applicationStatusMessages[data[0].status]
              ),
            })
          );
        }
      } else if (!disableAlert) {
        errorAlert();
      }

      onSuccess?.(res.data);
      updateApplicationsCache(res.data);
      return res.data;
    } catch (error) {
      if (!disableAlert) {
        errorAlert();
      }

      onError?.(error as Error);
      return null;
    }
  };

  return bulkChangeApplicationStatus;
};

export default useBulkChangeApplicationStatus;
