import { createContext } from 'react';
import { defineMessages } from 'react-intl';

import { CANDIDATE_STATUS, JOB_TYPE } from './../../common/constants';

export const FindTalentContext = createContext({
  canFilter: false,
  expandCard: false,
  setExpandCard: () => {},
});

export const FILTER_QUERY_KEYS = {
  SHOW: 'show',
  SORT: 'sort',
  PAGE: 'page',
  SKILLS: 'skills',
  JOB_CATEGORIES: 'jobCategories',
  JOB_TITLES: 'jobTitles',
  CITIES: 'cities',
  COUNTRIES: 'countries',
  CURRENT_LOCATION_ONLY: 'currentLocationOnly',
  PREFERRED_LOCATION_ONLY: 'preferredLocationOnly',
  NATIONALITY: 'nationality',
  LEGAL: 'legalToWork',
  SCHOOLS: 'schools',
  MIN_EXPERIENCE: 'minExperience',
  MAX_EXPERIENCE: 'maxExperience',
  AVAILABILITY: 'availability',
  PROFILE: 'profile',
  JOB_TYPES: 'jobTypes',
  MIN_SALARY: 'minSalary',
  MAX_SALARY: 'maxSalary',
  SALARY_CURRENCY_CODE: 'currencyCode',
  KEYWORDS: 'keywords',
  WORK_TITLES: 'workTitles',
  WORK_DESCRIPTIONS: 'workDescriptions',
  FIELD_OF_STUDY: 'fieldOfStudy',
  RESUME: 'resumeContains',
  LAST_SEEN: 'lastSeen',
  PLATFORM: 'platform',
};

export const SHOW_OPTIONS = {
  ALL_TALENT: 'allTalent',
  BOOKMARKED: 'bookmarked',
};

export const SORT_OPTIONS = {
  MOST_RELEVANT: 'MOST_RELEVANT',
  LAST_SEEN: 'LAST_SEEN',
};

export const AVAILABILITY_FILTERS = {
  LOOKING_FOR_JOB: CANDIDATE_STATUS.I_AM_LOOKING_FOR_JOB,
  OPEN_FOR_OPPORTUNITIES: CANDIDATE_STATUS.OPEN_FOR_OPPORTUNITIES,
  NOT_LOOKING: CANDIDATE_STATUS.I_AM_NOT_LOOKING_FOR_A_JOB,
};

export const PLATFORM_FILTERS = {
  GLINTS: 'GLINTS',
  LINKEDIN: 'LINKEDIN',
  CAKE_RESUME: 'CAKE_RESUME',
};

export const PLATFORM_FILTERS_LABEL = {
  [PLATFORM_FILTERS.GLINTS]: 'Glints',
  [PLATFORM_FILTERS.LINKEDIN]: 'LinkedIn',
  [PLATFORM_FILTERS.CAKE_RESUME]: 'Cake Resume',
};

export const PROFILE_FILTERS = {
  PHONE: 'phone',
  RESUME: 'resume',
};

export const JOB_TYPES_FILTERS = {
  FULL_TIME: JOB_TYPE.FULL_TIME,
  PART_TIME: JOB_TYPE.PART_TIME,
  PROJECT: JOB_TYPE.PROJECT,
  INTERNSHIP: JOB_TYPE.INTERNSHIP,
};

export const messages = defineMessages({
  all: {
    id: 'modules.FindTalent.Constants.all',
    defaultMessage: 'All',
  },
  topSearches: {
    id: 'modules.FindTalent.Constants.top.searches',
    defaultMessage: 'Top searches',
  },
  noVisaSponsorRequired: {
    id: 'modules.FindTalent.Constants.no.visa.sponsorship',
    defaultMessage: 'No visa sponsorship required',
  },
  countYear: {
    id: 'modules.FindTalent.Constants.count.year',
    defaultMessage:
      '{count} {count, plural, =0 {year} one {year} other {years}}',
  },
  noTalentFound: {
    id: 'modules.FindTalent.Constants.no.talent.found',
    defaultMessage: 'No talent found!',
  },
  pleaseSelectAJob: {
    id: 'modules.FindTalent.Constants.please.select.a.job',
    defaultMessage: 'Please select a job',
  },
  [SHOW_OPTIONS.ALL_TALENT]: {
    id: 'modules.FindTalent.Constants.findTalent.control.show.allTalent',
    defaultMessage: 'All talent',
  },
  [SHOW_OPTIONS.BOOKMARKED]: {
    id: 'modules.FindTalent.Constants.findTalent.control.show.bookmarked',
    defaultMessage: 'Bookmarked',
  },
  [SORT_OPTIONS.MOST_RELEVANT]: {
    id: 'modules.FindTalent.Constants.findTalent.control.sort.mostRelevant',
    defaultMessage: 'Most relevant',
  },
  [SORT_OPTIONS.LAST_SEEN]: {
    id: 'modules.FindTalent.Constants.findTalent.control.sort.lastSeen',
    defaultMessage: 'Last seen',
  },
  [AVAILABILITY_FILTERS.LOOKING_FOR_JOB]: {
    id: 'modules.FindTalent.Constants.looking.for.job',
    defaultMessage: 'Looking for job',
  },
  [AVAILABILITY_FILTERS.OPEN_FOR_OPPORTUNITIES]: {
    id: 'modules.FindTalent.Constants.open.to.opportunities',
    defaultMessage: 'Open to opportunities',
  },
  [AVAILABILITY_FILTERS.NOT_LOOKING]: {
    id: 'modules.FindTalent.Constants.not.looking.for.opportunities',
    defaultMessage: 'Not looking for opportunities',
  },
  [JOB_TYPES_FILTERS.FULL_TIME]: {
    id: 'modules.FindTalent.Constants.full.time',
    defaultMessage: 'Full time',
  },
  [JOB_TYPES_FILTERS.PART_TIME]: {
    id: 'modules.FindTalent.Constants.part.time',
    defaultMessage: 'Part time',
  },
  [JOB_TYPES_FILTERS.PROJECT]: {
    id: 'modules.FindTalent.Constants.project.based',
    defaultMessage: 'Project based',
  },
  [JOB_TYPES_FILTERS.INTERNSHIP]: {
    id: 'modules.FindTalent.Constants.internship',
    defaultMessage: 'Internship',
  },
  [PROFILE_FILTERS.PHONE]: {
    id: 'modules.FindTalent.Constants.has.phoneNumber',
    defaultMessage: 'Has phone number',
  },
  [PROFILE_FILTERS.RESUME]: {
    id: 'modules.FindTalent.Constants.has.resume',
    defaultMessage: 'Has resume',
  },
});

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_SHOW_OPTION = SHOW_OPTIONS.ALL_TALENT;
export const DEFAULT_SORT_OPTION = SORT_OPTIONS.MOST_RELEVANT;
export const MIN_EXPERIENCE = 0;
export const MAX_EXPERIENCE = 10;
export const DEFAULT_MIN_EXPERIENCE_VALUE = 0;
export const DEFAULT_MAX_EXPERIENCE_VALUE = 10;
export const MAX_SKILLS_DISPLAY = 10;
export const MAX_WORK_EXP_DISPLAY = 3;
// elasticsearch default index.max_result_window
export const MAX_RESULT_WINDOW = 10000;
export const LEARNING_TO_RANK_EXPERIMENT =
  'learning_to_rank_linear_model_experiment';
export const FIND_TALENT_EXPERIMENTS = [LEARNING_TO_RANK_EXPERIMENT];
// Default the platform filter to Glints only upon redirection from /talents.
export const FIND_TALENT_REDIRECT_QUERY_STRING = `?${FILTER_QUERY_KEYS.PLATFORM}=${PLATFORM_FILTERS.GLINTS}`;
export const Actions = {
  ALL_TALENT_REQUEST: 'glints/FindTalent/ALL_TALENT_REQUEST',
  ALL_TALENT_SUCCESS: 'glints/FindTalent/ALL_TALENT_SUCCESS',
  ALL_TALENT_FAILURE: 'glints/FindTalent/ALL_TALENT_FAILURE',

  TALENT_REQUEST: 'glints/FindTalent/TALENT_REQUEST',
  TALENT_SUCCESS: 'glints/FindTalent/TALENT_SUCCESS',
  TALENT_FAILURE: 'glints/FindTalent/TALENT_FAILURE',

  TALENT_RECOMMENDATIONS_REQUEST:
    'glints/FindTalent/TALENT_RECOMMENDATIONS_REQUEST',
  TALENT_RECOMMENDATIONS_SUCCESS:
    'glints/FindTalent/TALENT_RECOMMENDATIONS_SUCCESS',
  TALENT_RECOMMENDATIONS_FAILURE:
    'glints/FindTalent/TALENT_RECOMMENDATIONS_FAILURE',

  BOOKMARKS_REQUEST: 'glints/FindTalent/BOOKMARKS_REQUEST',
  BOOKMARKS_SUCCESS: 'glints/FindTalent/BOOKMARKS_SUCCESS',
  BOOKMARKS_FAILURE: 'glints/FindTalent/BOOKMARKS_FAILURE',

  BOOKMARKS_COUNT_REQUEST: 'glints/FindTalent/BOOKMARKS_COUNT_REQUEST',
  BOOKMARKS_COUNT_SUCCESS: 'glints/FindTalent/BOOKMARKS_COUNT_SUCCESS',
  BOOKMARKS_COUNT_FAILURE: 'glints/FindTalent/BOOKMARKS_COUNT_FAILURE',

  UNBOOKMARK_REQUEST: 'glints/FindTalent/UNBOOKMARK_REQUEST',
  UNBOOKMARK_SUCCESS: 'glints/FindTalent/UNBOOKMARK_SUCCESS',
  UNBOOKMARK_FAILURE: 'glints/FindTalent/UNBOOKMARK_FAILURE',

  COMPANIES_REQUEST: 'glints/FindTalent/COMPANIES_REQUEST',
  COMPANIES_SUCCESS: 'glints/FindTalent/COMPANIES_SUCCESS',
  COMPANIES_FAILURE: 'glints/FindTalent/COMPANIES_FAILURE',

  COMPANY_JOBS_REQUEST: 'glints/FindTalent/COMPANY_JOBS_REQUEST',
  COMPANY_JOBS_SUCCESS: 'glints/FindTalent/COMPANY_JOBS_SUCCESS',
  COMPANY_JOBS_FAILURE: 'glints/FindTalent/COMPANY_JOBS_FAILURE',

  SELECT_COMPANY_JOB: 'glints/FindTalent/SELECT_COMPANY_JOB',
  VIEW_EXTERNAL_PROFILE: 'glints/FindTalent/VIEW_EXTERNAL_PROFILE',
};

export const GTMActions = {
  GTM_TRACK_APPLY_FILTER: 'glints/FindTalent/GTM_TRACK_APPLY_FILTER',
  GTM_TRACK_RESULTS_TTI: 'glints/FindTalent/GTM_TRACK_RESULTS_TTI',
};
