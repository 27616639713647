import { defineMessage } from 'react-intl';

import config from '../../../../../config';
import { LinkDefinition } from '../types';

const { S3_BUCKET_NAME } = config;

const vnDoc = (fileName: string) =>
  `http://s3-ap-southeast-1.amazonaws.com/${S3_BUCKET_NAME}/docs/${fileName}`;

const defaultTermsAndConditions = `https://s3-ap-southeast-1.amazonaws.com/glints-dashboard/docs/GlintsTermsAndConditions.pdf`;

const companySectionLinks: LinkDefinition[] = [
  {
    defaultURL: `${config.CANDIDATE_URL}/about`,
    perCountryURL: {
      id: `${config.CANDIDATE_URL}/id/about`,
      my: `${config.CANDIDATE_URL}/my/about`,
      tw: `${config.CANDIDATE_URL}/tw/about`,
      vn: `${config.CANDIDATE_URL}/vn/about`,
    },
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'About Us',
      id: 'components.Footer.FooterGlints.about.us',
    }),
    className: 'about',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/inside/`,
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Inside Glints',
      id: 'components.Footer.FooterGlints.inside.glints',
    }),
    className: 'inside',
  },
  {
    defaultURL: 'https://tech.glints.com/',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Tech Blog',
      id: 'components.Footer.FooterGlints.tech.glints',
    }),
    className: 'tech',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/careers/`,
    perCountryURL: {
      id: `${config.CANDIDATE_URL}/id/careers`,
      my: `${config.CANDIDATE_URL}/my/careers`,
      tw: `${config.CANDIDATE_URL}/tw/careers`,
      vn: `${config.CANDIDATE_URL}/vn/careers`,
    },
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Careers',
      id: 'components.Footer.FooterGlints.careers',
    }),
    className: 'careers',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/about/terms`,
    perCountryURL: {
      id: `${config.CANDIDATE_URL}/id/about/terms`,
      my: defaultTermsAndConditions,
      vn: vnDoc('terms-and-conditions-vn.pdf'),
      tw: 'terms',
    },
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Terms Of Use',
      id: 'components.Footer.FooterGlints.terms.of.use',
    }),
    openInNewTab: true,
    className: 'terms',
  },
  {
    perCountryURL: {
      tw: 'privacy-policy',
    },
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Privacy Policy',
      id: 'components.Footer.FooterGlints.privacy.policy',
    }),
    className: 'privacy',
  },
  {
    perCountryURL: {
      vn: vnDoc('Information-security-policy-vn.pdf'),
    },
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Information Security Policy',
      id: 'components.Footer.FooterGlints.informationSecurityPolicy',
    }),
    className: 'security',
  },
  {
    perCountryURL: {
      vn: vnDoc('operation-regulation-vn.pdf'),
    },
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Operation Regulation',
      id: 'components.Footer.FooterGlints.operationRegulation',
    }),
    className: 'operation',
  },
  {
    perCountryURL: {
      vn: vnDoc('dispute-settlement-mechanism-vn.pdf'),
    },
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Dispute Settlement Mechanism',
      id: 'components.Footer.FooterGlints.disputeSettlementMechanism',
    }),
    className: 'dispute',
  },
  {
    defaultURL: `${config.CANDIDATE_URL}/sg/hired/`,
    perCountryURL: {
      id: `${config.CANDIDATE_URL}/id/lowongan/`,
      tw: `${config.CANDIDATE_URL}/tw/blog/`,
    },
    excludedCountries: ['vn'],
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Hired Blog',
      id: 'components.Footer.FooterGlints.hired.blog',
    }),
    className: 'blog',
  },
  {
    defaultURL: 'https://security.glints.com',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Report Vulnerability',
      id: 'components.Footer.FooterGlints.report.vulnerability',
    }),
    className: 'report',
  },
];

const solutionsLinks: LinkDefinition[] = [
  {
    defaultURL: 'recruitment',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Recruitment',
      id: 'components.Footer.FooterGlints.recruitment',
    }),
    className: 'recruitment',
  },
  {
    defaultURL: 'managed-talent',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Managed Talent',
      id: 'components.Footer.FooterGlints.managed.talent',
    }),
    className: 'talent',
  },
  {
    defaultURL: 'job-search',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Job Search',
      id: 'components.Footer.FooterGlints.job.search',
    }),
    className: 'job',
  },
];

const resourcesLinks: LinkDefinition[] = [
  {
    defaultURL: 'https://employers.glints.com/sg/blog/',
    perCountryURL: {
      id: `https://employers.glints.com/id-id/blog/`,
      tw: `${config.CANDIDATE_URL}/tw/blog/`,
      vn: `${config.CANDIDATE_URL}/vn/blog/`,
    },
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Blog',
      id: 'interactive-blog',
    }),
    className: 'blog',
  },
  {
    defaultURL: 'fee-structure',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Fee Structure',
      id: 'interactive-fee-structure',
    }),
    className: 'fee',
  },
  {
    defaultURL: 'startup-offer',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'For Startups',
      id: 'components.Footer.FooterGlints.for.startups',
    }),
    className: 'startup',
  },
  {
    defaultURL: 'remote-hiring',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Remote Hiring',
      id: 'interactive-remote-hiring',
    }),
    className: 'hiring',
  },
  {
    defaultURL: 'talent-pool',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Talent Pool',
      id: 'interactive-talent-pool',
    }),
    className: 'talent',
  },
  {
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'Events',
      id: 'components.Footer.FooterGlints.events',
    }),
    className: 'events',
  },
  {
    defaultURL: 'ebooks',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'eBooks',
      id: 'interactive-ebook',
    }),
    className: 'ebooks',
  },
  {
    defaultURL: 'faq',
    formattedMessageDescriptor: defineMessage({
      defaultMessage: 'FAQ',
      id: 'interactive-faq',
    }),
    className: 'faq',
  },
];

export const links = [
  {
    title: defineMessage({ id: 'text-company-v2', defaultMessage: 'Company' }),
    links: companySectionLinks,
  },
  {
    title: defineMessage({
      id: 'text-solutions-v2',
      defaultMessage: 'Solutions',
    }),
    links: solutionsLinks,
  },
  {
    title: defineMessage({
      id: 'text-resources-v2',
      defaultMessage: 'Resources',
    }),
    links: resourcesLinks,
  },
] as const;
