import { getThumborImageURL } from '@glints/fast-image';

import config from '../../config';

export function getImageAssetURL(image, assetType) {
  if (assetType) {
    return `https://${config.S3_BUCKET_NAME}.s3.amazonaws.com/${assetType}/${image}`;
  } else {
    return `https://${config.S3_BUCKET_NAME}.s3.amazonaws.com/${image}`;
  }
}

export function getOpenGraphURL(image, assetType) {
  return getThumborImageURL({
    thumborServerURL: config.THUMBOR_BASE,
    size: { width: 1200 },
    src: getImageAssetURL(image, assetType),
  });
}
