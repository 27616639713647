import React, { Suspense, useState } from 'react';
import { Flex } from 'glints-aries/es';
import { Icon, Popover, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { space8, space16 } from 'glints-aries/es/@next/utilities/spacing';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import * as portals from 'react-reverse-portal';
import { Link, useLocation } from 'react-router-dom';

import { truncateString, userName } from '../../../common/filters';
import useIsVIPMembershipEnabled from '../../../common/hooks/useIsVIPMembershipEnabled';
import ErrorBoundaryWithCrashReporting from '../../../components/Error/ErrorBoundaryWithCrashReporting';
import VIPProfilePicture from '../../../components/GlintsPicture/VIPProfilePicture';
import { useGlintsVIPMembershipInfo } from '../../../components/GlintsVIPMembershipInfo/hooks/useGlintsVIPMembershipInfo';
import RenderBasedOnCompanyCountry from '../../../components/RenderBasedOnCompanyCountry';
import { getUser } from '../../../selectors/user';
import CreditBalanceActivatorBadge from '../../CreditSystem/NavbarUserMenu/CreditBalanceActivatorBadge';
import CreditBalancePopoverMenuItem from '../../CreditSystem/NavbarUserMenu/CreditBalancePopoverMenuItem';
import VIPMembershipInfoMenuItem from '../../CreditSystem/NavbarUserMenu/VIPMembershipInfoMenuItem';
import type { MenuItem } from '../../Platform/Constants';
import { renderItemLabel } from '../navigationUtils';
import MenuProfileOverview from './MenuProfileOverview';
import {
  CustomPopoverStyles,
  StyledDivider,
  UserProfilePicture,
} from './UserMenu.sc';

type UserMenuProps = {
  menuItems: MenuItem[];
};

const UserProfilePictureDefault = () => {
  const me = useSelector(getUser);
  if (!me) return null;
  return (
    <UserProfilePicture
      size={30}
      userId={me.id}
      profilePic={me.profilePic}
      alt="Profile Picture"
    />
  );
};

const UserProfileBasedOnVIPMembership = () => {
  const {
    vipMembershipInfo: { isActive },
  } = useGlintsVIPMembershipInfo('cache-first');
  if (isActive) {
    return (
      <VIPProfilePicture
        profilePictureSize={30}
        vipLogoWidth={20}
        vipLogoOffset={12}
      />
    );
  }

  return <UserProfilePictureDefault />;
};
export default function UserMenu({ menuItems }: UserMenuProps) {
  const me = useSelector(getUser);
  const [popoverState, setPopoverState] = useState({
    isActive: false,
    renderKeepAliveComponents: false,
  });
  // some components in dropdown menu needs to render modals on some action
  // so we need to keep them alive to prevent unmounting them when the popover is closed
  // this is done so that we don't have to lift the state up to the parent component
  const keepAliveMenuItemPortalNode = React.useMemo(
    () => portals.createHtmlPortalNode(),
    []
  );

  const [openPopover, closePopover] = [
    () => {
      setPopoverState(prev => ({
        ...prev,
        renderKeepAliveComponents: true,
        isActive: true,
      }));
    },
    () =>
      setPopoverState(prev => ({
        ...prev,
        isActive: false,
      })),
  ];
  const isVipMembershipEnabled = useIsVIPMembershipEnabled();

  if (!me) return null;

  menuItems = menuItems.concat({
    to: '/logout',
    value: 'logout',
    label: {
      defaultMessage: 'Logout',
      id: 'interactive-log-out',
    },
    icon: 'ri-logout-circle-line',
  });

  const activator = (
    <Flex
      style={{
        gap: space8,
        margin: `0 ${space16}`,
      }}
      alignItems="center"
      onMouseEnter={openPopover}
    >
      <Choose>
        <When condition={isVipMembershipEnabled}>
          <ErrorBoundaryWithCrashReporting
            fallback={<UserProfilePictureDefault />}
          >
            <Suspense fallback={<UserProfilePictureDefault />}>
              <UserProfileBasedOnVIPMembership />
            </Suspense>
          </ErrorBoundaryWithCrashReporting>
        </When>
        <Otherwise>
          <UserProfilePictureDefault />
        </Otherwise>
      </Choose>
      <div>
        <Flex
          style={{
            gap: space8,
          }}
        >
          <Typography variant="caption" aria-label="user-name">
            {userName(me, true).length > 12
              ? truncateString(userName(me, true), 12)
              : userName(me, true)}
          </Typography>
          <Icon name="ri-arrow-m-down-line" width={20} />
        </Flex>

        <RenderBasedOnCompanyCountry.IDOnly>
          <CreditBalanceActivatorBadge />
        </RenderBasedOnCompanyCountry.IDOnly>
      </div>
    </Flex>
  );

  return (
    <div
      style={{
        cursor: 'pointer',
      }}
      onMouseLeave={closePopover}
      onClick={e => e.stopPropagation()}
    >
      <portals.InPortal node={keepAliveMenuItemPortalNode}>
        {popoverState.renderKeepAliveComponents && (
          <RenderBasedOnCompanyCountry.IDOnly>
            <VIPMembershipInfoMenuItem closePopover={closePopover} />
            <CreditBalancePopoverMenuItem closePopover={closePopover} />
          </RenderBasedOnCompanyCountry.IDOnly>
        )}
      </portals.InPortal>
      <Popover
        active={popoverState.isActive}
        activator={activator}
        onClose={closePopover}
        zIndexOverride={1200}
        preferredAlignment="right"
        preferredPosition="below"
      >
        <div
          style={{
            margin: `${space8}`,
          }}
        >
          <CustomPopoverStyles />
          <MenuProfileOverview />
          <StyledDivider />
          <portals.OutPortal node={keepAliveMenuItemPortalNode} />
          {menuItems.map(item => (
            <BasicMenuItem
              key={`menu-${item.value}`}
              item={item}
              onClick={closePopover}
            />
          ))}
        </div>
      </Popover>
    </div>
  );
}

function BasicMenuItem({
  item,
  onClick,
}: {
  item: MenuItem;
  onClick: () => void;
}) {
  const intl = useIntl();

  const content = (
    <Flex
      style={{
        padding: space8,
        gap: space8,
      }}
    >
      {item.icon && <Icon name={item.icon} fill={Neutral.B40} width={24} />}
      <Typography variant="body1" color={Neutral.B18}>
        {renderItemLabel(intl, item.label)}
      </Typography>
    </Flex>
  );
  const location = useLocation();
  const isActive = location.pathname === item.to;

  const handleClick = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    onClick();
  };

  const handleClickLink = (e: {
    stopPropagation: () => void;
    preventDefault: () => void;
  }) => {
    e.stopPropagation();

    onClick();
  };

  if (isActive) {
    return (
      <div
        style={{
          cursor: 'pointer',
        }}
        onClick={handleClick}
      >
        {content}
      </div>
    );
  }
  return (
    <Link to={item.to || '/'} onClick={handleClickLink}>
      {content}
    </Link>
  );
}
