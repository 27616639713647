import { isEqual } from 'lodash-es';
import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

export const initialState = {
  localFiles: {
    files: [],
  },
  fileUpload: {
    uploading: false,
    success: false,
    error: null,
    value: [],
  },
};

export default handleActions(
  {
    [Actions.FILE_UPLOAD_REQUEST]: state => ({
      ...state,
      fileUpload: {
        uploading: true,
        success: false,
        error: null,
      },
    }),
    [Actions.FILE_UPLOAD_SUCCESS]: (state, action) => ({
      ...state,
      fileUpload: {
        uploading: false,
        success: true,
        error: null,
        value: action.payload,
      },
    }),
    [Actions.FILE_UPLOAD_FAIL]: (state, action) => ({
      ...state,
      fileUpload: {
        uploading: true,
        success: false,
        error: action.payload,
      },
    }),
    [Actions.SAVE_FILE_TO_LOCAL]: (state, action) => ({
      ...state,
      localFiles: {
        files: state.localFiles.files.concat(action.payload),
      },
    }),
    [Actions.DELETE_FILE_IN_LOCAL]: (state, action) => ({
      ...state,
      localFiles: {
        files: state.localFiles.files.filter(
          file => !isEqual(file, action.payload)
        ),
      },
    }),
    [Actions.RESET_STATE]: () => ({
      ...initialState,
    }),
  },
  initialState
);
