export enum ForbiddenKeywordsCountries {
  ID = 'ID', // Default
  VN = 'VN',
}

interface ForbiddenKeywords {
  job: string[];
  company: string[];
}

type ForbiddenKeywordsRecord = Record<
  ForbiddenKeywordsCountries,
  ForbiddenKeywords
>;

const FORBIDDEN_KEYWORDS: ForbiddenKeywordsRecord = {
  ID: {
    job: [
      'hostess',
      'flowman',
      'togel',
      'pembesaran',
      'gacor',
      'judi',
      'terapis',
      'vitalitas',
      'alat vital',
      'slot',
      'gacor',
      'pills',
      'abortion',
    ],
    company: [
      'call girl',
      'call girls',
      'escort',
      'escorts',
      'sexy',
      'girl',
      'girls',
      'female',
      'call#',
      '#girls',
      'call # girls',
      'female escort',
      'female escorts',
      'sexy girl',
      'callgirl',
      'callgirls',
      'vitalitas',
      'alat vital',
      'slot',
      'gacor',
      'pills',
      'abortion',
    ],
  },
  VN: {
    job: [],
    company: [],
  },
};

export const DEFAULT_FORBIDDEN_KEYWORDS = FORBIDDEN_KEYWORDS.ID;

export default FORBIDDEN_KEYWORDS;
