import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

const initialState = {
  fetch: {
    loading: false,
    success: false,
    error: null,
    value: null,
  },
};

export default handleActions(
  {
    [Actions.RECOMMENDATION_REQUEST]: state => ({
      ...state,
      fetch: {
        ...initialState.fetch,
        loading: true,
      },
    }),
    [Actions.RECOMMENDATION_SUCCESS]: (state, action) => ({
      ...state,
      fetch: {
        ...initialState.fetch,
        success: true,
        value: action.payload,
      },
    }),
    [Actions.RECOMMENDATION_FAILURE]: (state, action) => ({
      ...state,
      fetch: {
        ...initialState.fetch,
        error: action.payload,
      },
    }),
  },
  initialState
);
