import { combineReducers } from '@reduxjs/toolkit';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';

import { LOGOUT } from './actions/session';
import formComponents from './components/Form/Reducer';
import jobShareModal from './components/JobShareModal/Reducer';
import application from './modules/Application/Reducer';
import companyDashboard from './modules/CompanyDashboard/Reducer';
import companyEdit from './modules/CompanyEdit/Reducer';
import emailVerification from './modules/EmailVerification/Reducer';
import findTalent from './modules/FindTalent/Reducer';
import glintsUploadFile from './modules/GlintsUploadFile/Reducer';
import landing from './modules/Landing/Reducer';
import login from './modules/Login/Reducer';
import modal from './modules/Modal/Reducers';
import navigation from './modules/Navigation/Reducer';
import passwordReset from './modules/PasswordReset/Reducer';
import platform from './modules/Platform/Reducer';
import recommendations from './modules/Recommendations/Reducer';
import resumePage from './modules/ResumePage/Reducer';
import signUp from './modules/SignUp/Reducer';
import talentModal from './modules/TalentModal/Reducer';
import talentProfile from './modules/TalentProfile/Reducer';
import talents from './modules/Talents/Reducer';
import applications from './reducers/applications';
import cities from './reducers/cities';
import company from './reducers/company';
import components from './reducers/components';
import countries from './reducers/countries';
import currencies from './reducers/currencies';
import entities from './reducers/entities';
import features from './reducers/features';
import groups from './reducers/groups';
import industries from './reducers/industries';
import job from './reducers/job';
import jobCategories from './reducers/job-categories';
import jobTitles from './reducers/job-titles';
import jobTypes from './reducers/job-types';
import jobs from './reducers/jobs';
import meta from './reducers/meta';
import schools from './reducers/schools';
import session from './reducers/session';
import skills from './reducers/skills';
import studies from './reducers/studies';
import userCompanies from './reducers/user/companies';
import me from './reducers/user/me';

const user = combineReducers({
  me,
  userCompanies,
});

/**
 * This is the reducer that transforms the previous state into the next state.
 * The transformation to be done is specified via 'action'.
 *
 * The reducer must be a pure function, i.e. given the same arguments, it
 * should always return a consistent result.
 *
 * More information available at http://redux.js.org/docs/basics/Reducers.html.
 */
const appReducer = (history, asyncReducer = {}) =>
  combineReducers({
    entities,
    ...{
      application,
      applications,
      cities,
      company,
      companyDashboard,
      companyEdit,
      components,
      countries,
      currencies,
      emailVerification,
      features,
      formComponents,
      findTalent,
      glintsUploadFile,
      groups,
      industries,
      job,
      jobCategories,
      jobs,
      jobShareModal,
      jobTitles,
      jobTypes,
      landing,
      login,
      meta,
      modal,
      navigation,
      passwordReset,
      platform,
      recommendations,
      resumePage,
      schools,
      session,
      signUp,
      skills,
      studies,
      talentModal,
      talentProfile,
      talents,
      user,
    },
    router: connectRouter(history),
    form: formReducer,
    ...asyncReducer,
  });

const rootReducer = (history, asyncReducer) => (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(history, asyncReducer)(state, action);
};

export default rootReducer;
