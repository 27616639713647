import { createAction } from 'redux-actions';

import { GROUPS } from '../../../common/filters';
import CALL_API from '../../../middleware/api_key';
import { Schemas } from '../../../middleware/schemas';

export const Actions = {
  FETCH_TALENT_REQUEST: 'glints/talentModal/profile/FETCH_TALENT_REQUEST',
  FETCH_TALENT_SUCCESS: 'glints/talentModal/profile/FETCH_TALENT_SUCCESS',
  FETCH_TALENT_FAILURE: 'glints/talentModal/profile/FETCH_TALENT_FAILURE',

  FETCH_TALENT_FROM_GROUP_REQUEST:
    'glints/talentModal/profile/FETCH_TALENT_FROM_GROUP_REQUEST',
  FETCH_TALENT_FROM_GROUP_SUCCESS:
    'glints/talentModal/profile/FETCH_TALENT_FROM_GROUP_SUCCESS',
  FETCH_TALENT_FROM_GROUP_FAILURE:
    'glints/talentModal/profile/FETCH_TALENT_FROM_GROUP_FAILURE',

  UPDATE_TALENT_GROUP_STATUS_REQUEST:
    'glints/talentModal/profile/UPDATE_TALENT_GROUP_STATUS_REQUEST',
  UPDATE_TALENT_GROUP_STATUS_SUCCESS:
    'glints/talentModal/profile/UPDATE_TALENT_GROUP_STATUS_SUCCESS',
  UPDATE_TALENT_GROUP_STATUS_FAILURE:
    'glints/talentModal/profile/UPDATE_TALENT_GROUP_STATUS_FAILURE',

  APPROVE_TO_TALENTHUNT_REQUEST:
    'glints/talentModal/profile/APPROVE_TO_TALENTHUNT_REQUEST',
  APPROVE_TO_TALENTHUNT_SUCCESS:
    'glints/talentModal/profile/APPROVE_TO_TALENTHUNT_SUCCESS',
  APPROVE_TO_TALENTHUNT_FAILURE:
    'glints/talentModal/profile/APPROVE_TO_TALENTHUNT_FAILURE',

  REMOVE_FROM_TALENTHUNT_REQUEST:
    'glints/talentModal/profile/REMOVE_FROM_TALENTHUNT_REQUEST',
  REMOVE_FROM_TALENTHUNT_SUCCESS:
    'glints/talentModal/profile/REMOVE_FROM_TALENTHUNT_SUCCESS',
  REMOVE_FROM_TALENTHUNT_FAILURE:
    'glints/talentModal/profile/REMOVE_FROM_TALENTHUNT_FAILURE',

  SET_TALENT_MODAL_HAS_RECOMMENDATIONS:
    'glints/talentModal/SET_TALENT_MODAL_HAS_RECOMMENDATIONS',
};

export const updateTalentGroupStatusRequest = createAction(
  Actions.UPDATE_TALENT_GROUP_STATUS_REQUEST
);
export const updateTalentGroupStatusSuccess = createAction(
  Actions.UPDATE_TALENT_GROUP_STATUS_SUCCESS
);
export const updateTalentGroupStatusFailure = createAction(
  Actions.UPDATE_TALENT_GROUP_STATUS_FAILURE
);

export const setTalentModalHasRecommendations = createAction(
  Actions.SET_TALENT_MODAL_HAS_RECOMMENDATIONS
);

export function fetchTalent(talentId, asCompanyId) {
  return {
    [CALL_API]: {
      types: [
        Actions.FETCH_TALENT_REQUEST,
        Actions.FETCH_TALENT_SUCCESS,
        Actions.FETCH_TALENT_FAILURE,
      ],
      endpoint: `talents/${talentId}`,
      schema: Schemas.TALENT,
      options: {
        params: {
          CompanyId: asCompanyId,
        },
      },
    },
  };
}

export function fetchTalentFromGroup(talentId) {
  return {
    [CALL_API]: {
      types: [
        Actions.FETCH_TALENT_FROM_GROUP_REQUEST,
        Actions.FETCH_TALENT_FROM_GROUP_SUCCESS,
        Actions.FETCH_TALENT_FROM_GROUP_FAILURE,
      ],
      endpoint: `groups/${GROUPS.talentSearchCandidate.id}/members`,
      schema: Schemas.GROUP_MEMBER_ARRAY,
      options: {
        params: {
          where: {
            CandidateId: talentId,
          },
        },
      },
    },
  };
}

export function updateTalentGroupStatus(groupMemberId, status) {
  const action = status === 'APPROVED' ? 'APPROVE_TO' : 'REMOVE_FROM';
  return {
    [CALL_API]: {
      types: [
        Actions[`${action}_TALENTHUNT_REQUEST`],
        Actions[`${action}_TALENTHUNT_SUCCESS`],
        Actions[`${action}_TALENTHUNT_FAILURE`],
      ],
      endpoint: `groups/${GROUPS.talentSearchCandidate.id}/members/${groupMemberId}`,
      schema: Schemas.GROUP_MEMBER,
      method: 'put',
      options: {
        data: {
          status: status,
          GroupId: GROUPS.talentSearchCandidate.id,
        },
      },
    },
  };
}
