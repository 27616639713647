import {
  gql,
  skipToken,
  SuspenseQueryHookFetchPolicy,
  useSuspenseQuery,
} from '@apollo/client';
import { useSelector } from 'react-redux';

import useIsVIPMembershipEnabled from '../../../common/hooks/useIsVIPMembershipEnabled';
import { EmployerPurchaseProductType } from '../../../common/interfaces/entities';
import { CompanyProductInventory } from '../../../modules/CreditSystem/Features/FeaturesAndPurchasesTabs/PurchasedProducts/types';
import { getSessionCompanyId } from '../../../selectors/session';

const query = gql`
  query getVIPMembershipInfo($input: GetCompanyProductInventoryInput!) {
    getVIPMembershipInfo: getCompanyProductInventory(input: $input) {
      amount
      expiryDateTime
      activeFreeCreditBenefit {
        amount
        nextAllocationDate
      }
      product {
        creditBenefit {
          amount
        }
      }
    }
  }
`;

type GetVIPMembershipInfoInput = {
  input: {
    companyId: string;
    productType: typeof EmployerPurchaseProductType.VIP_MEMBERSHIP;
  };
};

export type GetVIPMembershipInfoResponse = {
  getVIPMembershipInfo: Pick<
    CompanyProductInventory,
    'amount' | 'expiryDateTime' | 'product' | 'activeFreeCreditBenefit'
  > | null;
};

export const useGetVIPMembershipInfo = (
  fetchPolicy: SuspenseQueryHookFetchPolicy = 'cache-and-network',
  queryKey = 'getVIPMembershipInfo'
) => {
  const companyId: string | undefined = useSelector(getSessionCompanyId);
  const isVIPMembershipEnabled = useIsVIPMembershipEnabled();

  const { data } = useSuspenseQuery<
    GetVIPMembershipInfoResponse,
    GetVIPMembershipInfoInput
  >(
    query,
    isVIPMembershipEnabled
      ? {
          variables: {
            input: {
              companyId: companyId || '',
              productType: EmployerPurchaseProductType.VIP_MEMBERSHIP,
            },
          },
          fetchPolicy: fetchPolicy,
          queryKey,
        }
      : skipToken
  );

  return { data, skipped: !isVIPMembershipEnabled };
};
