import { handleActions } from 'redux-actions';

import { Actions } from './Actions';

const initialState = {
  hasRecommendations: false,

  groupMember: {
    value: [],
    loading: false,
    error: null,
    success: false,

    approve: {
      loading: false,
      error: null,
      success: false,
    },

    remove: {
      loading: false,
      error: null,
      success: false,
    },
  },
};

export default handleActions(
  {
    [Actions.FETCH_TALENT_FROM_GROUP_REQUEST]: state => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        value: [],
        loading: true,
        error: null,
        success: false,
      },
    }),
    [Actions.FETCH_TALENT_FROM_GROUP_SUCCESS]: (state, action) => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        value: action.response.result,
        loading: false,
        error: null,
        success: true,
      },
    }),
    [Actions.FETCH_TALENT_FROM_GROUP_FAILURE]: (state, action) => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        value: [],
        loading: false,
        error: action.error.payload,
        success: false,
      },
    }),
    [Actions.APPROVE_TO_TALENTHUNT_REQUEST]: state => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        approve: {
          ...initialState.groupMember.approve,
          loading: true,
        },
      },
    }),
    [Actions.APPROVE_TO_TALENTHUNT_SUCCESS]: state => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        approve: {
          ...initialState.groupMember.approve,
          success: true,
        },
      },
    }),
    [Actions.APPROVE_TO_TALENTHUNT_FAILURE]: (state, action) => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        approve: {
          ...initialState.groupMember.approve,
          error: action.error.payload,
        },
      },
    }),
    [Actions.REMOVE_FROM_TALENTHUNT_REQUEST]: state => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        remove: {
          ...initialState.groupMember.remove,
          loading: true,
        },
      },
    }),
    [Actions.REMOVE_FROM_TALENTHUNT_SUCCESS]: state => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        remove: {
          ...initialState.groupMember.remove,
          success: true,
        },
      },
    }),
    [Actions.REMOVE_FROM_TALENTHUNT_FAILURE]: (state, action) => ({
      ...state,
      groupMember: {
        ...state.groupMember,
        remove: {
          ...initialState.groupMember.remove,
          error: action.error.payload,
        },
      },
    }),
    [Actions.SET_TALENT_MODAL_HAS_RECOMMENDATIONS]: (state, action) => ({
      ...state,
      hasRecommendations: action.payload,
    }),
  },
  initialState
);
