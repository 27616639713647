import { createAction } from 'redux-actions';

import axios from '../../../common/axios-ts';
import { getSessionToken } from '../../../selectors/session';

export const Actions = {
  RECOMMENDATION_REQUEST:
    'glints/talentModal/recommendation/RECOMMENDATION_REQUEST',
  RECOMMENDATION_SUCCESS:
    'glints/talentModal/recommendation/RECOMMENDATION_SUCCESS',
  RECOMMENDATION_FAILURE:
    'glints/talentModal/recommendation/RECOMMENDATION_FAILURE',
};

export const recommendationRequest = createAction(
  Actions.RECOMMENDATION_REQUEST
);
export const recommendationSuccess = createAction(
  Actions.RECOMMENDATION_SUCCESS
);
export const recommendationFailure = createAction(
  Actions.RECOMMENDATION_FAILURE
);

export function fetchRecommendation({ talentId, jobId }) {
  return async (dispatch, getState) => {
    try {
      dispatch(recommendationRequest());
      const response = await axios(getSessionToken(getState())).get(
        '/recommendations',
        {
          params: {
            where: {
              CandidateId: talentId,
              JobId: jobId,
            },
          },
        }
      );
      dispatch(
        recommendationSuccess(
          response.data.data.length ? response.data.data[0] : null
        )
      );
    } catch (err) {
      dispatch(recommendationFailure(err));
    }
  };
}
