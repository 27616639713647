import { combineReducers } from '@reduxjs/toolkit';

import bookmark from './Bookmark/Reducer';
import modal from './ModalReducer';
import profile from './Profile/Reducer';
import recommend from './Recommend/Reducer';
import recommendation from './Recommendation/Reducer';

export const Reducer = combineReducers({
  bookmark,
  modal,
  profile,
  recommend,
  recommendation,
});

export default Reducer;
