import { defineMessages } from 'react-intl';

import { ApplicationStatus } from '../../../common/constants';
import { userName } from '../../../common/filters';
import { ChangeApplicationStatusPayloadWithName } from '../../../common/hooks/requests/useBulkChangeApplicationStatus';
import { ApplicationDetails } from '../../../common/interfaces/entities';
import { ApplicationRejectionMessageType } from '../RejectionReasonPreferenceModal/types';
import { RejectionReason } from './types';

export const messages = defineMessages({
  noOfApplicants: {
    id: 'components.Modal.ApplicationStatusTrackingModal.candidates',
    defaultMessage: 'Applicants',
  },
  dateOfApplication: {
    id: 'components.Modal.ApplicationStatusTrackingModal.dateOfApplication',
    defaultMessage: 'Date of Application',
  },
  defaultApplicantNameInEmail: {
    id: 'components.Modal.ApplicationStatusTrackingModal.default.candidate.name.in.email',
    defaultMessage: 'Applicant Name',
  },
  thisApplicant: {
    id: 'components.Modal.ApplicationStatusTrackingModal.candidate.email.desc.this.candidate',
    defaultMessage: 'this candidate',
  },
  specifyRejectionReason: {
    id: 'components.Modal.ApplicationStatusTrackingModal.specify.rejection.reason',
    defaultMessage: 'Please specify your rejection reason.',
  },
  specifyOtherReason: {
    id: 'components.Modal.ApplicationStatusTrackingModal.specify.other.reason',
    defaultMessage: 'Other reason',
  },
  proceedBtn: {
    id: 'interactive-proceed',
    defaultMessage: 'Proceed',
  },
  cancelBtn: {
    id: 'interactive-cancel',
    defaultMessage: 'Cancel',
  },
  rejectBtn: {
    id: 'interactive-yes-reject-application',
    defaultMessage: 'Yes, Reject Application',
  },
  hideMessage: {
    id: 'interactive-understood-hide-message-future',
    defaultMessage: 'Understood. Hide this message for me in the future.',
  },
});

export const getOptionalLength = (arr: any[]) => (arr ? arr.length : 0);

export const buildBulkChangeApplicationStatusPayload = (
  applications: ApplicationDetails[],
  incomingStatus: ApplicationStatus,
  // TODO: Remove RejectionReason type after RejectApplicant component is removed
  selectedReasons?: (RejectionReason | ApplicationRejectionMessageType)[],
  other?: string
) =>
  applications.map(application => ({
    applicationId: application.id,
    status: incomingStatus,
    name: userName(application.Applicant),
    ...(selectedReasons?.length && { rejectionReasons: selectedReasons }),
    ...(other && { otherReason: other }),
  })) as ChangeApplicationStatusPayloadWithName[];
