import React from 'react';
import { FormattedMessage } from 'react-intl';

export const TalentProfileContext = React.createContext(null);
export const ExternalProfileContext = React.createContext(null);

export const DATA_SOURCES = {
  GLINTS: 'GLINTS',
  LINKEDIN: 'LINKEDIN',
  CAKE_RESUME: 'CAKE_RESUME',
};

export const EXTERNAL_DATA_SOURCES = [DATA_SOURCES.LINKEDIN];

export const DATA_SOURCE_LABELS = {
  [DATA_SOURCES.GLINTS]: (
    <FormattedMessage
      id="modules.TalentProfile.Constants.glints"
      defaultMessage="Glints"
    />
  ),
  [DATA_SOURCES.LINKEDIN]: (
    <FormattedMessage
      id="modules.TalentProfile.Constants.linkedin"
      defaultMessage="LinkedIn"
    />
  ),
  [DATA_SOURCES.CAKE_RESUME]: (
    <FormattedMessage
      id="modules.TalentProfile.Constants.cakeresume"
      defaultMessage="Cake Resume"
    />
  ),
};

export const DATA_SOURCE_PROFILE_PIC_ASSET_TYPE = {
  [DATA_SOURCES.GLINTS]: 'profile-picture',
  [DATA_SOURCES.LINKEDIN]: 'talent-radar/linkedin/profile-picture',
  [DATA_SOURCES.CAKE_RESUME]: 'talent-radar/cakeresume/avatar',
};

export const TABS = {
  LOG: {
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.activity.timeline"
        defaultMessage="Activity Timeline"
      />
    ),
    key: 'LOG',
    className: 'talent-profile_activity-timeline',
  },
  RESUME: {
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.resume"
        defaultMessage="Resume"
      />
    ),
    key: 'RESUME',
    className: 'talent-profile_resume',
  },
  PROFILE_OVERVIEW: {
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.profile.overview"
        defaultMessage="Profile Overview"
      />
    ),
    key: 'PROFILE_OVERVIEW',
    className: 'talent-profile_profile-overview',
  },
  PROFILE_SUMMARY: {
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.profile.summary"
        defaultMessage="Profile Summary"
      />
    ),
    key: 'PROFILE_SUMMARY',
    className: 'talent-profile_profile-summary',
  },
  OTHER_SOURCES: {
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.other.sources"
        defaultMessage="Other Sources"
      />
    ),
    key: 'OTHER_SOURCES',
    className: 'talent-profile_other-sources',
  },
};

export const TAB_ORDERS = {
  RECRUITER: [TABS.PROFILE_SUMMARY, TABS.RESUME, TABS.LOG, TABS.OTHER_SOURCES],
  EMPLOYER: [TABS.PROFILE_OVERVIEW, TABS.RESUME, TABS.LOG],
};

export const ACTIVITY_LOG_TYPES = {
  // Candidate Status
  CANDIDATE_I_AM_LOOKING_FOR_JOB: {
    template:
      '[creator] updated the **candidate status** to **I am looking for a job**',
  },
  CANDIDATE_OPEN_FOR_OPPORTUNITIES: {
    template:
      '[creator] updated the **candidate status** to **Open for opportunities**',
  },
  CANDIDATE_I_AM_NOT_LOOKING_FOR_A_JOB: {
    templates: {
      withExpiry: `[creator] updated the **candidate status** to **I am not looking for a job**.
      Candidate will be open to opportunities from **[expiryMonth] [expiryYear]** onwards.`,
      withoutExpiry:
        '[creator] updated the **candidate status** to **I am not looking for a job**',
    },
  },
  // Others
  RESERVE_TALENT: {
    template: '[creator] **reserved** [talent] on [createdAt]',
  },
  COMMENTS: {
    templates: {
      companyOnly:
        '[creator] **added a note** about [talent] for **[company]**',
      companyAndJob:
        '[creator] **added a note** about [talent] for **[job]** at **[company]**',
      noCompanyNoJob: '[creator] **added a note** about [talent]',
    },
  },
  BOOKMARK: {
    template:
      '[creator] **bookmarked** [talent] for **[job]** at **[company]**',
  },
  // TalentHunt Status
  TALENTHUNT_APPROVED: {
    template: '[creator] has **approved** [talent] to **TalentHunt**',
  },
  TALENTHUNT_PENDING: {
    template: '[creator] has **removed** [talent] from **TalentHunt**',
  },
  // Recommendations
  RECOMMENDATIONS_APPROVED: {
    template:
      '[creator] **approved the recommendation** of [talent] for **[job]** at **[company]**',
  },
  RECOMMENDATIONS_PENDING: {
    template:
      '[creator] **recommended** [talent] to **[company]** for **[job]**',
  },
  RECOMMENDATIONS_REJECTED: {
    template:
      '[creator] **rejected the recommendation** of [talent] for **[job]** at **[company]**',
  },
  // Applications
  APPLICATIONS_NEW: {
    template:
      '[creator] **submitted an application** for **[job]** at **[company]**',
  },
  APPLICATIONS_IN_REVIEW: {
    template:
      '[creator] marked [talent] as **good fit** for **[job]** at **[company]**',
  },
  APPLICATIONS_INTERVIEWING: {
    template:
      "[creator] updated [talent]'s application to **interview** for **[job]** at **[company]**",
  },
  APPLICATIONS_ASSESSMENT: {
    template:
      "[creator] updated [talent]'s application to **assessment** for **[job]** at **[company]**",
  },
  APPLICATIONS_OFFERED: {
    template:
      "[creator] updated [talent]'s application to **offered** for **[job]** at **[company]**",
  },
  APPLICATIONS_HIRED: {
    template:
      "[creator] updated [talent]'s application to **hired** for **[job]** at **[company]**",
  },
  APPLICATIONS_REJECTED: {
    template:
      "[creator] marked [talent]'s application as **unsuitable** for **[job]** at **[company]**",
  },
  // RATS
  THJOB_APPLICATIONS_CONTACTED: {
    template: '[creator] **contacted** [talent] for **[job]** at **[company]**',
  },
  THJOB_APPLICATIONS_REFERRAL: {
    template:
      '[creator] **referred** [talent] to [referredRecruiter] for **[job]** at **[company]**',
  },
  THJOB_APPLICATIONS_PENDING: {
    template:
      '[creator] started a **[rats_with_type]** for [talent] for **[job]** at **[company]**',
  },
  THJOB_APPLICATIONS_APPROVED: {
    template:
      '[creator] **approved** the [rats_with_type] of [talent] for **[job]** at **[company]**',
  },
  THJOB_APPLICATIONS_ASSESSMENT: {
    template: `[creator] updated [talent]'s [rats_with_type] to
    **assessment** for **[job]** at **[company]**`,
  },
  THJOB_APPLICATIONS_INTERVIEW: {
    template: `[creator] updated [talent]'s [rats_with_type] to
    **interview** for **[job]** at **[company]**`,
  },
  THJOB_APPLICATIONS_OFFERED: {
    template:
      "[creator] updated [talent]'s [rats_with_type] to **offered** for **[job]** at **[company]**",
  },
  THJOB_APPLICATIONS_PLACED: {
    template:
      "[creator] updated [talent]'s [rats_with_type] to **placed** for **[job]** at **[company]**",
  },
  THJOB_APPLICATIONS_REJECTED: {
    template:
      '[creator] **rejected** the [rats_with_type] of [talent] for **[job]** at **[company]**',
  },
};

export const THJOB_TYPES = {
  DEFAULT: 'TalentHunt job application',
  RECOMMENDATION: 'TalentHunt job application (Recommendation)',
  APPLICATION: 'TalentHunt job application (Application)',
};

export const ACTIVITY_LOG_SORT_OPTIONS = {
  'createdAt DESC': {
    value: 'Date added (latest first)',
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.date.added.latest"
        defaultMessage="Date added (latest first)"
      />
    ),
  },
  'createdAt ASC': {
    value: 'Date added (earliest first)',
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.date.added.earliest"
        defaultMessage="Date added (earliest first)"
      />
    ),
  },
  'updatedAt DESC': {
    value: 'Date modified (latest first)',
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.date.modified.latest"
        defaultMessage="Date modified (latest first)"
      />
    ),
  },
  'updatedAt ASC': {
    value: 'Date modified (earliest first)',
    label: (
      <FormattedMessage
        id="modules.TalentProfile.Constants.date.modified.earliest"
        defaultMessage="Date modified (earliest first)"
      />
    ),
  },
};

export const ERRORS = {
  NoEmptyNote: 'Comment.note cannot be null',
};

export const CREATOR_NAMES = {
  SYSTEM: 'Glints automatically',
  TALENTHUNT_PLUS: 'TalentHunt+',
};

export const MAX_NOTE_LENGTH = 66000;

export const Actions = {
  ACTIVITY_LOG_REQUEST: 'glints/talentProfile/ACTIVITY_LOG_REQUEST',
  ACTIVITY_LOG_SUCCESS: 'glints/talentProfile/ACTIVITY_LOG_SUCCESS',
  ACTIVITY_LOG_FAILURE: 'glints/talentProfile/ACTIVITY_LOG_FAILURE',

  RESET_ACTIVITY_LOG_STATE: 'glints/talentProfile/RESET_ACTIVITY_LOG_STATE',

  SELECT_ACTIVITY_LOG_SORT_OPTION:
    'glints/talentProfile/SELECT_ACTIVITY_LOG_SORT_OPTION',

  UPDATE_CANDIDATE_STATUS_REQUEST:
    'glints/talentProfile/UPDATE_CANDIDATE_STATUS_REQUEST',
  UPDATE_CANDIDATE_STATUS_SUCCESS:
    'glints/talentProfile/UPDATE_CANDIDATE_STATUS_SUCCESS',
  UPDATE_CANDIDATE_STATUS_FAILURE:
    'glints/talentProfile/UPDATE_CANDIDATE_STATUS_FAILURE',

  SELECT_CANDIDATE_STATUS: 'glints/talentProfile/SELECT_CANDIDATE_STATUS',

  TOGGLE_ACTIVITY_LOG_NOTE: 'glints/talentProfile/TOGGLE_ACTIVITY_LOG_NOTE',
  SET_NOTE_EDIT_MODE: 'glints/talentProfile/SET_NOTE_EDIT_MODE',

  SAVE_NOTE_REQUEST: 'glints/talentProfile/SAVE_NOTE_REQUEST',
  SAVE_NOTE_SUCCESS: 'glints/talentProfile/SAVE_NOTE_SUCCESS',
  SAVE_NOTE_FAILURE: 'glints/talentProfile/SAVE_NOTE_FAILURE',

  DELETE_NOTE_REQUEST: 'glints/talentProfile/DELETE_NOTE_REQUEST',
  DELETE_NOTE_SUCCESS: 'glints/talentProfile/DELETE_NOTE_SUCCESS',
  DELETE_NOTE_FAILURE: 'glints/talentProfile/DELETE_NOTE_FAILURE',

  RESET_STATE: 'glints/talentProfile/RESET_STATE',

  TALENTHUNT_COMPANIES_REQUEST:
    'glints/talentProfile/TALENTHUNT_COMPANIES_REQUEST',
  TALENTHUNT_COMPANIES_SUCCESS:
    'glints/talentProfile/TALENTHUNT_COMPANIES_SUCCESS',
  TALENTHUNT_COMPANIES_FAILURE:
    'glints/talentProfile/TALENTHUNT_COMPANIES_FAILURE',
  UPDATE_COMPANY_SEARCH_TERM: 'glints/talentProfile/UPDATE_COMPANY_SEARCH_TERM',

  TALENTHUNT_JOBS_REQUEST: 'glints/talentProfile/TALENTHUNT_JOBS_REQUEST',
  TALENTHUNT_JOBS_SUCCESS: 'glints/talentProfile/TALENTHUNT_JOBS_SUCCESS',
  TALENTHUNT_JOBS_FAILURE: 'glints/talentProfile/TALENTHUNT_JOBS_FAILURE',

  UPDATE_ATS_COMPANY_ID: 'glints/talentProfile/UPDATE_ATS_COMPANY_ID',
  UPDATE_ATS_JOB_ID: 'glints/talentProfile/UPDATE_ATS_JOB_ID',
  UPDATE_ATS_RECRUITER_ID: 'glints/talentProfile/UPDATE_ATS_RECRUITER_ID',
  UPDATE_ATS_NOTE: 'glints/talentProfile/UPDATE_ATS_NOTE',
  UPDATE_ATS_REJECTION_REASONS:
    'glints/talentProfile/UPDATE_ATS_REJECTION_REASONS',
  UPDATE_ATS_OTHER_REASON: 'glints/talentProfile/UPDATE_ATS_OTHER_REASON',

  PERSIST_ATS_ACTION_REQUEST: 'glints/talentProfile/PERSIST_ATS_ACTION_REQUEST',
  PERSIST_ATS_ACTION_SUCCESS: 'glints/talentProfile/PERSIST_ATS_ACTION_SUCCESS',
  PERSIST_ATS_ACTION_FAILURE: 'glints/talentProfile/PERSIST_ATS_ACTION_FAILURE',

  REMOVE_ACTIVITY_LOG_ID: 'glints/talentProfile/REMOVE_ACTIVITY_LOG_ID',

  RESET_ATS_STATE: 'glints/talentProfile/RESET_ATS_STATE',

  TALENTHUNT_APPLICATION_REQUEST:
    'glints/talentProfile/TALENTHUNT_APPLICATION_REQUEST',
  TALENTHUNT_APPLICATION_SUCCESS:
    'glints/talentProfile/TALENTHUNT_APPLICATION_SUCCESS',
  TALENTHUNT_APPLICATION_FAILURE:
    'glints/talentProfile/TALENTHUNT_APPLICATION_FAILURE',

  GTM_TRACK_SAVE_NOTE: 'glints/talentProfile/GTM_TRACK_SAVE_NOTE',
};
