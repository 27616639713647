import { handleActions } from 'redux-actions';

import { Actions } from './Actions';
import { TALENT_MODAL_VIEWS } from './Constants';

const initialState = {
  isVisible: false,
  profileTab: null,
  talentId: null,
  view: TALENT_MODAL_VIEWS.PROFILE,
  hideGoBackProfile: false,
};

export default handleActions(
  {
    [Actions.OPEN_TALENT_MODAL]: (state, action) => ({
      ...state,
      isVisible: true,
      talentId: action.payload,
    }),
    [Actions.SET_TALENT_MODAL_VIEW]: (state, action) => ({
      ...state,
      view: action.payload,
    }),
    [Actions.SET_TALENT_PROFILE_TAB]: (state, action) => ({
      ...state,
      profileTab: action.payload,
    }),
    [Actions.UPDATE_HIDE_GO_BACK_PROFILE]: (state, action) => ({
      ...state,
      hideGoBackProfile: action.payload,
    }),
    [Actions.RESET_TALENT_MODAL]: () => initialState,
  },
  initialState
);
