import config from '../config';
import { hash } from './filters';

/**
 * Assets used on Glints are stored on Amazon S3. Due to the differing storage
 * locations depending on environment (i.e. production vs development) and
 * asset type, this helper function allows callers to quickly determine the
 * full URL.
 *
 * The currently defined asset types are:
 *
 *   - application-attachment
 *   - career-banner-pic
 *   - career-banner-pic-small
 *   - company-banner-pic
 *   - company-logo
 *   - company-photos
 *   - company-resources
 *   - content
 *   - default-banner-pic-large
 *   - default-banner-pic-small
 *   - group-logo
 *   - group-banner-pic
 *   - job-attachment
 *   - job-banner-pic
 *   - job-default-banner-pic
 *   - perk-pic
 *   - profession-banner-pic
 *   - profession-banner-pic-small
 *   - profile-picture
 *   - profile-picture-default
 *   - resource-banner-pic
 *   - resource-cards
 *   - resume
 *
 *  Some defaults for images:
 *
 *  - default-company-logo.jpg
 *  - default-group-logo.jpg
 *  - default-job-edit-banner-pic.jpg
 *  - profile-picture-default-(id % 15 + 1).jpg
 *  - career-banner-pic-(career.id).jpg
 *  - job-default-banner-pic-(id % 16 + 1).jpg
 *  - profession-banner-pic-(profession.id).jpg
 *  - glints_logo_360.jpg
 *
 */

export function getImageAssetUrl(image, assetType) {
  const fileName = image.split('.')[0];
  const optimumWidth = 360;
  return {
    initial: `//${config.IMAGES_URL}/all/${fileName}.jpg`,
    optimum: `//${config.IMAGES_URL}/all/${fileName}_${optimumWidth}.jpg`,
    fallback: `//s3-ap-southeast-1.amazonaws.com/${config.S3_BUCKET_NAME}/${assetType}/${image}`,
  };
}

export function getAssetUrl(assetType, name) {
  if (assetType === 'all') {
    return `//${config.IMAGES_URL}/all/${name}`;
  } else {
    return `//s3-ap-southeast-1.amazonaws.com/${config.S3_BUCKET_NAME}/${assetType}/${name}`;
  }
}

export function getRandomFallbackProfilePicture(id) {
  return `${(Math.abs(hash(id)) % 15) + 1}.jpg`;
}

export function getRandomFallbackJobBannerPic(id) {
  return `${(Math.abs(hash(id)) % 16) + 1}.jpg`;
}

export function getRandomFallbackCompanyBannerPic(id) {
  return `${(Math.abs(hash(id)) % 16) + 1}.jpg`;
}
